<script setup>
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';

import useDateFormat from '@/Composables/useDateFormat.js';
import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    paymentMethod: Object,
    sales: Object,
});

const { simplifyDate } = useDateFormat();
const { convertToCurrencyFormat } = usePriceFormatter();
</script>

<template>
    <Dashboard :page_title="paymentMethod.name">
        <div class="row mx-0" id="show-payment-method">
            <Breadcrumb
                :currentPageName="paymentMethod.name"
                :linkList="[
                    { previous_page_url: 'payment-methods', previous_page_name: 'Métodos' },
                ]"
            />
            <div class="col-lg-12 p-0">
                <div class="card p-4">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">{{ paymentMethod.name }}</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon
                                :href="`/dashboard/payment-methods/edit/${paymentMethod.id}`"
                                background="primary"
                                color="white"
                                icon="pen-to-square"
                                message="Editar dados"
                            />
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="purchase-list" v-if="Object.values(sales).length > 0">
                    <div class="card-header font-bold p-3 bg-transparent">
                        Ultimas compras que utilizaram esse método de pagamento
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive mt-3 col-11 mx-auto">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th class="text-center" scope="col">Cliente</th>
                                        <th class="text-center" scope="col">Valor</th>
                                        <th class="text-center" scope="col">Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(sale, index) in sales" :key="index">
                                        <th scope="row">#{{ sale.id }}</th>
                                        <td class="text-center">{{ sale.client_name }}</td>
                                        <td class="text-center">
                                            {{ convertToCurrencyFormat(sale.total_sale_price) }}
                                        </td>
                                        <td class="text-center">
                                            {{ simplifyDate(sale.created_at) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
