import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faEye,
    faScrewdriverWrench,
    faChartColumn,
    faAt,
    faCirclePlus,
    faBarcode,
    faCircleExclamation,
    faPenToSquare,
    faBoxArchive,
    faFile,
    faBarsStaggered,
    faPlus,
    faMoneyBillWave,
    faBook,
    faAngleDown,
    faUsers,
    faCircleCheck,
    faEllipsisVertical,
    faTrashCan,
    faRightFromBracket,
    faEnvelope,
    faBasketShopping,
    faMagnifyingGlass,
    faCopyright,
    faLayerGroup,
    faTruckMoving,
    faTags,
    faGripVertical,
    faCopy,
    faListCheck,
    faEyeSlash,
    faAddressBook,
    faSliders,
    faCreditCard,
    faKey,
    faUserShield,
    faShop,
    faArrowsRotate,
    faArrowUpRightFromSquare,
    faHouseCircleCheck,
    faUserGroup,
    faBars,
    faPhone,
    faBan,
    faPercent,
    faAngleRight,
    faCircleInfo,
    faCartShopping,
    faFilter,
    faBookmark,
    faFilePdf,
    faSquarePlus,
    faFileExcel,
    faBagShopping,
    faMapLocationDot,
    faCloudArrowUp,
    faUpLong,
    faDownLong,
    faLeftLong,
    faRightLong,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faCircleXmark,
    faChevronDown,
    faUserTag,
    faTimes,
    faGear,
    faUserPlus,
    faPanorama,
    faClock,
    faArrowUpFromBracket,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebook,
    faYoutube,
    faSpotify,
    faInstagram,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

import { faComments } from '@fortawesome/free-regular-svg-icons';

library.add(
    faClock,
    faGear,
    faPanorama,
    faTimes,
    faComments,
    faCartShopping,
    faCopy,
    faChevronDown,
    faSquarePlus,
    faUserPlus,
    faCloudArrowUp,
    faSpotify,
    faCirclePlus,
    faAt,
    faAngleRight,
    faFilePdf,
    faFileExcel,
    faBookmark,
    faBarcode,
    faArrowsRotate,
    faWhatsapp,
    faFilter,
    faFacebook,
    faYoutube,
    faPhone,
    faBagShopping,
    faEnvelope,
    faInstagram,
    faCopyright,
    faMapLocationDot,
    faGripVertical,
    faScrewdriverWrench,
    faChartColumn,
    faTruckMoving,
    faListCheck,
    faCircleExclamation,
    faHouseCircleCheck,
    faShop,
    faKey,
    faCircleCheck,
    faCircleInfo,
    faEyeSlash,
    faBars,
    faEye,
    faBan,
    faUserTag,
    faUserShield,
    faPenToSquare,
    faBoxArchive,
    faFile,
    faAngleDown,
    faBarsStaggered,
    faPlus,
    faTrashCan,
    faMoneyBillWave,
    faBook,
    faUsers,
    faUserGroup,
    faEllipsisVertical,
    faRightFromBracket,
    faBasketShopping,
    faMagnifyingGlass,
    faSliders,
    faTags,
    faAddressBook,
    faLayerGroup,
    faCreditCard,
    faPercent,
    faArrowUpRightFromSquare,
    faUpLong,
    faDownLong,
    faLeftLong,
    faRightLong,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faArrowUpFromBracket,
    faCircleXmark
);
