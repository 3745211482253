<template>
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="favorites"
        aria-labelledby="favoritesLabel"
    >
        <div class="offcanvas-header border-bottom">
            <h5 class="offcanvas-title d-flex align-items-center" id="favoritesLabel">
                <i class="fas fa-heart text-danger me-2"></i>
                <span class="fw-bold">Meus Favoritos</span>
                <span class="badge bg-danger ms-2" v-if="bookLength > 0">
                    {{ bookLength }}
                </span>
            </h5>
            <button
                type="button"
                class="btn-close"
                ref="offcanvas"
                data-bs-dismiss="offcanvas"
            ></button>
        </div>

        <div class="offcanvas-body p-0">
            <!-- Empty State -->
            <div v-if="bookLength === 0" class="text-center empty-state">
                <div class="empty-icon mb-3">
                    <i class="fas fa-heart text-muted fa-3x"></i>
                </div>
                <h6 class="fw-bold text-muted mb-2">Nenhum favorito ainda</h6>
                <p class="text-muted small px-4">
                    Adicione livros aos favoritos para encontrá-los facilmente depois
                </p>
            </div>

            <!-- Books Grid -->
            <div v-else class="favorites-grid p-3">
                <div v-for="book in books" :key="book.clean_name" class="favorite-item">
                    <div class="card h-100 border-0 shadow-sm">
                        <div class="position-relative">
                            <button
                                class="btn btn-remove"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                data-bs-title="Remover dos favoritos"
                                @click="removeFromFavoritesByCleanName(book.clean_name)"
                            >
                                <font-awesome-icon :icon="['fa', 'ban']" />
                            </button>

                            <img
                                :src="book.cover_image_url_link"
                                :alt="book.name"
                                class="card-img-top p-3"
                            />
                        </div>

                        <div class="card-body text-center">
                            <h6 class="card-title mb-2 text-truncate fs-12">{{ book.name }}</h6>
                            <p class="price mb-3 font-bold">
                                {{ formatPrice(book.original_price) }}
                            </p>

                            <Link
                                class="btn btn-primary w-100 font-semibold fs-12"
                                :href="`/livros/${book.clean_name}`"
                                @click="offcanvas.click()"
                            >
                                Ver livro
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import { ref, onMounted } from 'vue';
import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const books = ref([]);
const bookLength = ref(0);
const offcanvas = ref(null);
const { convertToCurrencyFormat } = usePriceFormatter();

const favoriteBooks = () => {
    const alreadyFavoriteBooks = localStorage.getItem('verboshop_favorites');
    books.value = alreadyFavoriteBooks ? JSON.parse(alreadyFavoriteBooks) : [];
    bookLength.value = books.value.length;
};

const removeFromFavoritesByCleanName = (clean_name) => {
    const alreadyFavoriteBooks = localStorage.getItem('verboshop_favorites');
    let convertToArray = alreadyFavoriteBooks ? JSON.parse(alreadyFavoriteBooks) : [];

    const index = convertToArray.findIndex(
        (favoriteBook) => favoriteBook.clean_name === clean_name
    );

    if (index !== -1) {
        convertToArray.splice(index, 1);
        localStorage.setItem('verboshop_favorites', JSON.stringify(convertToArray));
        favoriteBooks();
    }
};

const formatPrice = (value) => convertToCurrencyFormat(value);

onMounted(() => {
    const offcanvasElement = document.getElementById('favorites');

    offcanvasElement.addEventListener('shown.bs.offcanvas', favoriteBooks);
    offcanvasElement.addEventListener('hidden.bs.offcanvas', favoriteBooks);

    favoriteBooks();
});
</script>

<style scoped>
.offcanvas {
    max-width: 420px;
}

.favorites-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 1rem;
}

.favorite-item {
    animation: fadeIn 0.3s ease-out;
}

.card {
    transition: transform 0.2s ease;
}

.card:hover {
    transform: translateY(-3px);
}

.btn-remove {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
    background: rgba(var(--bs-danger-rgb), 0.1);
    color: var(--bs-danger);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    opacity: 0;
}

.card:hover .btn-remove {
    opacity: 1;
}

.btn-remove:hover {
    background: var(--bs-danger);
    color: white;
}

.price {
    color: var(--bs-primary);
    font-weight: 600;
    font-size: 1.1rem;
}

.empty-state {
    opacity: 0.7;
}

.empty-icon {
    animation: pulse 2s infinite;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

/* Responsive adjustments */
@media (max-width: 400px) {
    .favorites-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
