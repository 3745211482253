<script setup>
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

const props = defineProps({
    roles: Object,
});

const searchForm = reactive({ display_name: '' });

const search = () => router.get(route('roles.index'), searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de Funções" main-data="roles">
        <Breadcrumb currentPageName="Listagem de Funções" />

        <PageHeader title="Funções" :button-link="route('roles.create')" button-text="Nova" />

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-lg-12 mb-3">
                        <label for="display_name" class="form-label">Nome da Função</label>
                        <input
                            type="text"
                            id="display_name"
                            autocomplete="on"
                            class="form-control"
                            placeholder="Administrador"
                            v-model="searchForm.display_name"
                            @input="search"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-roles"
                :headers="[
                    {
                        key: 'id',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', tdClass: 'text-start' },
                    { key: 'display_name', label: 'Nome Legível', tdClass: 'text-start' },
                ]"
                :data="roles.data"
                :pagination="{ data: roles.meta, reference: 'roles' }"
                :rowLink="(item) => route('roles.show', item.id)"
            >
                <template #cell-id="{ item }">
                    {{ item.id }}
                </template>

                <template #cell-name="{ item }">
                    {{ item.name }}
                </template>

                <template #cell-display_name="{ item }">
                    {{ item.display_name }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
