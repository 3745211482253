<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

import { vMaska } from 'maska';
import { VMoney } from 'v-money';

import usePreviewImage from '@/Composables/usePreviewImage.js';
import useMoney from '@/Composables/useMoney.js';
import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    authors: Object,
    categories: Object,
});

const form = useForm({
    name: '',
    isbn: '',
    total_pages: '',
    original_price: '',
    quantity: '',
    visible: 1,
    cover_image_url: '',
    description: '',
    presentation_youtube_url: '',
    spotify_link_url: '',
    categories: [],
    authors: [],
    release_date: '',
    supplier_price: '',
});

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();
const { convertDecimalFormat } = usePriceFormatter();

const { money: supplierMoney } = useMoney(3);
const { money: originalMoney } = useMoney();

const store = () => {
    form.transform((data) => ({
        ...data,
        quantity: form.quantity == '' ? 0 : form.quantity,
        original_price: convertDecimalFormat(data.original_price),
        supplier_price: convertDecimalFormat(data.supplier_price),
    })).post(route('books.store'));
};
</script>

<template>
    <Dashboard page_title="Criar livro">
        <Breadcrumb
            current-page-name="Novo livro"
            :link-list="[
                {
                    previous_page_url: 'books',
                    previous_page_name: 'Livros',
                },
            ]"
        />

        <div id="create-client">
            <h4 class="my-3">Novo livro</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="row g-3">
                    <div class="col-md-8">
                        <label for="name" class="form-label">Nome do livro</label>
                        <input
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            id="name"
                            name="name"
                            v-model="form.name"
                            placeholder="5 ELEMENTOS DA FÉ"
                            required
                        />

                        <small class="text-danger mt-3" v-if="form.errors.name">{{
                            form.errors.name
                        }}</small>
                    </div>

                    <div class="col-md-4">
                        <label for="isbn" class="form-label">ISBN</label>
                        <input
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            id="isbn"
                            name="isbn"
                            v-model="form.isbn"
                            placeholder="978-65-8539-813-8"
                            v-maska
                            data-maska="###-##-####-###-#"
                            required
                        />

                        <small class="text-danger mt-3" v-if="form.errors.isbn">{{
                            form.errors.isbn
                        }}</small>
                    </div>

                    <div class="col-md-3 col-6">
                        <label for="total_pages" class="form-label">Total de páginas</label>
                        <input
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            id="total_pages"
                            name="total_pages"
                            v-model="form.total_pages"
                            v-maska
                            data-maska="#####"
                            placeholder="80"
                            required
                        />

                        <small class="text-danger mt-3" v-if="form.errors.total_pages">{{
                            form.errors.total_pages
                        }}</small>
                    </div>

                    <div class="col-md-4 col-6">
                        <label for="original_price" class="form-label">Preço do livro</label>
                        <input
                            type="text"
                            class="form-control"
                            id="original_price"
                            name="original_price"
                            v-model.lazy="form.original_price"
                            autocomplete="off"
                            v-money="originalMoney"
                            maxlength="7"
                            required
                        />
                        <small class="text-danger mt-3" v-if="form.errors.original_price">{{
                            form.errors.original_price
                        }}</small>
                    </div>

                    <div class="col-md-5 col-6">
                        <label for="visible" class="form-label">Vísivel</label>
                        <select
                            class="form-select"
                            id="visible"
                            name="visible"
                            v-model="form.visible"
                        >
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                        </select>
                    </div>

                    <div class="col-md-5 col-6">
                        <label for="release_date" class="form-label">Data de lançamento</label>
                        <input
                            type="date"
                            class="form-control"
                            autocomplete="off"
                            id="release_date"
                            name="release_date"
                            v-model="form.release_date"
                        />
                        <small class="text-danger mt-3" v-if="form.errors.release_date">{{
                            form.errors.release_date
                        }}</small>
                    </div>

                    <div class="col-md-4 col-6">
                        <label for="supplier_price" class="form-label">Preço de compra</label>
                        <input
                            type="text"
                            class="form-control"
                            id="supplier_price"
                            name="supplier_price"
                            v-model.lazy="form.supplier_price"
                            autocomplete="off"
                            v-money="supplierMoney"
                            maxlength="8"
                        />
                        <small class="text-danger mt-3" v-if="form.errors.supplier_price">{{
                            form.errors.supplier_price
                        }}</small>
                    </div>

                    <div class="col-md-3 col-6">
                        <label for="quantity" class="form-label">Quantidade</label>
                        <input
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            id="quantity"
                            name="quantity"
                            v-model="form.quantity"
                            v-maska
                            data-maska="####"
                            placeholder="1"
                        />

                        <small class="text-danger mt-3" v-if="form.errors.quantity">{{
                            form.errors.quantity
                        }}</small>
                    </div>

                    <div class="col-md-6">
                        <label for="presentation_youtube_url" class="form-label"
                            >Link apresentação Youtube</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            id="presentation_youtube_url"
                            name="presentation_youtube_url"
                            v-model="form.presentation_youtube_url"
                            placeholder="https://www.youtube.com/watch?verbodavida"
                        />

                        <small
                            class="text-danger mt-3"
                            v-if="form.errors.presentation_youtube_url"
                            >{{ form.errors.presentation_youtube_url }}</small
                        >
                    </div>

                    <div class="col-md-6">
                        <label for="spotify_link_url" class="form-label">Link Sportify</label>
                        <input
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            id="spotify_link_url"
                            name="spotify_link_url"
                            v-model="form.spotify_link_url"
                            placeholder="https://open.spotify.com/intl-pt/album"
                        />

                        <small class="text-danger mt-3" v-if="form.errors.spotify_link_url">{{
                            form.errors.spotify_link_url
                        }}</small>
                    </div>

                    <div class="col-md-12">
                        <label for="description" class="form-label">Descrição</label>
                        <small>(opcional)</small>
                        <textarea
                            type="text"
                            class="form-control"
                            id="description"
                            placeholder="Forneça uma descrição minuciosa e abrangente do conteúdo do livro"
                            name="description"
                            v-model="form.description"
                            rows="5"
                        ></textarea>

                        <small class="text-danger mt-3" v-if="form.errors.description">{{
                            form.errors.description
                        }}</small>
                    </div>
                </div>

                <hr class="mb-0" />

                <div class="row g-3 mt-0">
                    <div class="col-lg-6">
                        <h5 class="font-bold mb-3">Categorias</h5>
                        <ul class="list-group overflow-auto category-listing">
                            <li
                                class="list-group-item d-flex align-items-center"
                                v-for="(category, index) in categories"
                                :key="index"
                            >
                                <input
                                    class="form-check-input me-1"
                                    type="checkbox"
                                    :value="category.id"
                                    :id="'checkBoxCategory' + index"
                                    v-model="form.categories"
                                />
                                <label
                                    class="form-check-label stretched-link ms-3"
                                    :for="'checkBoxCategory' + index"
                                    style="cursor: pointer"
                                    >{{ category.name }}</label
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <h5 class="font-bold mb-3">Autores</h5>
                        <ul class="list-group overflow-auto category-listing">
                            <li
                                class="list-group-item d-flex align-items-center p-2"
                                v-for="(author, index) in authors"
                                :key="index"
                            >
                                <input
                                    class="form-check-input me-1"
                                    type="checkbox"
                                    :value="author.id"
                                    :id="'checkBoxAuthor' + index"
                                    v-model="form.authors"
                                />
                                <label
                                    class="form-check-label stretched-link ms-3"
                                    :for="'checkBoxAuthor' + index"
                                    style="cursor: pointer"
                                    >{{ author.name }}</label
                                >
                            </li>
                        </ul>
                    </div>
                </div>

                <hr class="mb-0" />

                <div class="row g-3">
                    <div class="col-md-12">
                        <label for="formFile" class="form-label"
                            >Selecione uma foto para a capa do livro - Tamanho máximo permitido:
                            2mb</label
                        >
                        <input
                            class="form-control"
                            @input="form.cover_image_url = $event.target.files[0]"
                            type="file"
                            id="formFile"
                            accept="image/png,image/jpeg"
                            @change="previewImage"
                            required
                        />
                        <small class="text-danger mt-3" v-if="form.errors.cover_image_url">{{
                            form.errors.cover_image_url
                        }}</small>
                    </div>
                    <div class="col-md-11 mx-auto">
                        <img
                            class="d-block mx-auto preview-image"
                            v-if="imageUrl"
                            :src="imageUrl"
                        />
                    </div>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
