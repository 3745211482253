<script setup>
import { useForm, Deferred } from '@inertiajs/vue3';
import { watchEffect, ref } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import Loader from '@/Pages/Dashboard/Components/ui/Loader.vue';

import { vMaska } from 'maska';

const props = defineProps({
    categories: Object,
    books: Object,
});

const form = useForm({
    name: null,
    discount_percentage: null,
    active: 0,
    rhema: 0,
    categories: [],
    books: [],
});

const store = () => form.post(route('promotions.store'));

const limitPercentage = () => {
    if (form.discount_percentage > 100) {
        form.discount_percentage = 100;
    }
};

const selectAllBooks = ref(false);

watchEffect(() => {
    if (selectAllBooks.value) {
        form.books = props.books.map((book) => book.id);
    } else {
        form.books = [];
    }
});
</script>

<template>
    <Dashboard page_title="Criar promoção">
        <Breadcrumb
            current-page-name="Nova categoria"
            :link-list="[
                {
                    previous_page_url: 'promotions',
                    previous_page_name: 'Promoções',
                },
            ]"
        />

        <div id="create-promotion">
            <h4 class="my-3">Nova promoção</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-8">
                    <label for="name" class="form-label">Nome da promoção</label>
                    <input
                        type="text"
                        autocomplete="on"
                        class="form-control"
                        id="name"
                        name="name"
                        placeholder="Dia das mães"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-4">
                    <label for="discount_percentage" class="form-label">Desconto em %</label>
                    <div class="input-group">
                        <input
                            type="text"
                            placeholder="10"
                            class="form-control"
                            id="discount_percentage"
                            name="discount_percentage"
                            v-model="form.discount_percentage"
                            v-maska
                            data-maska="##"
                            autocomplete="off"
                            required
                            @keyup="limitPercentage()"
                        />
                    </div>
                    <small class="text-danger mt-2" v-if="form.errors.discount_percentage">{{
                        form.errors.discount_percentage
                    }}</small>
                </div>

                <hr class="mb-0" />

                <div class="col-md-12">
                    <div class="form-check d-flex align-items-center form-switch">
                        <input
                            id="flexCheckDefault"
                            v-model="form.rhema"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            Promoção Oficial do Rhema?
                        </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-check d-flex align-items-center form-switch">
                        <input
                            id="flexCheckDefault"
                            v-model="form.active"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            Promoção ativa?
                        </label>
                    </div>
                </div>

                <hr class="mb-0" />

                <h4 class="mb-0">Livros vinculados</h4>

                <Deferred data="books">
                    <template #fallback>
                        <Loader />
                    </template>

                    <ul class="list-group overflow-auto normal-listing p-2 mt-2">
                        <li class="list-group-item p-3">
                            <input
                                class="form-check-input me-1"
                                type="checkbox"
                                id="selectAllBooks"
                                v-model="selectAllBooks"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13"
                                for="selectAllBooks"
                                style="cursor: pointer"
                                >Selecionar todos os livros</label
                            >
                        </li>
                        <li class="list-group-item p-3" v-for="(book, index) in books" :key="index">
                            <input
                                class="form-check-input me-1"
                                type="checkbox"
                                :value="book.id"
                                :id="'checkBoxBook' + index"
                                v-model="form.books"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13"
                                :for="'checkBoxBook' + index"
                                style="cursor: pointer"
                                >{{ book.name }}</label
                            >
                        </li>
                    </ul>
                </Deferred>

                <hr class="mb-0" />

                <h4 class="mb-0">Categorias vinculadas</h4>

                <Deferred data="categories">
                    <template #fallback>
                        <Loader />
                    </template>
                    <ul class="list-group overflow-auto normal-listing p-2 mt-2">
                        <li
                            class="list-group-item p-3"
                            v-for="(category, index) in categories"
                            :key="index"
                        >
                            <input
                                class="form-check-input me-1"
                                type="checkbox"
                                :value="category.id"
                                :id="'checkBoxCategory' + index"
                                v-model="form.categories"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13"
                                :for="'checkBoxCategory' + index"
                                style="cursor: pointer"
                                >{{ category.name }}</label
                            >
                        </li>
                    </ul>
                </Deferred>
            </Form>
        </div>
    </Dashboard>
</template>
