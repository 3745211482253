<script setup>
import { Link } from '@inertiajs/vue3';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import BarChart from '@/Pages/Dashboard/Graphics/BarChart.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

const props = defineProps({
    sales: Object,
    book_stock: Number,
    total_clients: Number,
    total_sales_today: Number,
    pending_books: String,
    users: Object,
    allBookSales: Object,
    differenceSalesOrders: Object,
    bestSellingBooks: Object,
    booksSoldByPaymentMethod: Object,
    slugLastSevenDays: Object,
    totalSalesLastSevenDays: Object,
    totalSalesLastSixMonths: Object,
});

const { convertToCurrencyFormat } = usePriceFormatter();

const isCurrentDate = (dateSale) => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return dateSale.split(' ')[0] === formattedDate;
};

const formatDate = (date) => {
    const parts = date.split(' ');
    const datePart = parts[0];
    const formattedDate = datePart.substr(0, 5);

    return formattedDate;
};
</script>

<template>
    <Dashboard page_title="Visão geral">
        <div class="mt-2 mx-0" id="home">
            <h3 class="font-bold mb-3">Visão geral</h3>
            <div class="row" id="main-cards">
                <div class="col-sm-3 col-6">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Vendas de hoje</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ convertToCurrencyFormat(total_sales_today) }}
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link class="font-semibold fs-12" href="/dashboard/sales"
                                        >Ver vendas</Link
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0">
                                    <span
                                        class="px-3 py-2 bg-success bg-opacity-50 shadow-sm rounded-2"
                                    >
                                        <font-awesome-icon :icon="['fas', 'money-bill-wave']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Estoque atual</h5>
                            <h4 class="font-bold mb-lg-3">{{ book_stock }} livros</h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link class="font-semibold fs-12" href="/dashboard/books"
                                        >Ver livros</Link
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0">
                                    <span
                                        class="px-3 py-2 bg-primary bg-opacity-50 shadow-sm rounded-2"
                                    >
                                        <font-awesome-icon :icon="['fas', 'book']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6 mt-3 mt-sm-0">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Pedidos</h5>
                            <h4 class="font-bold mb-lg-3">{{ pending_books }} livros</h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link class="font-semibold fs-12" href="/dashboard/orders"
                                        >Ver pedidos</Link
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0">
                                    <span
                                        class="px-3 py-2 bg-warning bg-opacity-50 shadow-sm rounded-2"
                                    >
                                        <font-awesome-icon :icon="['fas', 'box-archive']" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6 mt-3 mt-sm-0">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Clientes</h5>
                            <h4 class="font-bold mb-lg-3">{{ total_clients }} clientes</h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link class="font-semibold fs-12" href="/dashboard/clients"
                                        >Ver clientes</Link
                                    >
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-sm-0">
                                    <span
                                        class="px-3 py-2 bg-danger bg-opacity-50 shadow-sm rounded-2"
                                    >
                                        <font-awesome-icon :icon="['fas', 'users']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-none d-sm-block">
                <div class="row mt-3">
                    <div class="col-md-7">
                        <div class="card p-3">
                            <BarChart
                                :labels="totalSalesLastSixMonths['labels']"
                                :series="totalSalesLastSixMonths['totals']"
                                title="Vendas em R$ nos últimos meses"
                                barColor="#8cc3a9"
                            />
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="card p-3">
                            <BarChart
                                :labels="booksSoldByPaymentMethod['labels']"
                                :series="booksSoldByPaymentMethod['totals']"
                                title="Vendas por métodos"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <h4 class="font-bold font-bold mb-0 px-3">Vendas</h4>
                <span class="text-secondary font-semibold fs-14 px-3"
                    >Visualize as últimas vendas da loja</span
                >
                <div class="col-lg-12">
                    <Table
                        table-id="table-sales"
                        :headers="[
                            { key: 'id', label: 'ID' },
                            { key: 'client_name', label: 'Cliente' },
                            {
                                key: 'sale_payment_methods',
                                label: 'Pagamento',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'user_avatar',
                                label: 'Vendedor',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'total_sale_price',
                                label: 'Valor',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'created_at',
                                label: 'Data',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                        ]"
                        :data="sales.data"
                        :pagination="{ reference: 'sales' }"
                        :rowLink="(item) => `/dashboard/sales/${item.id}`"
                    >
                        <template #cell-total_sale_price="{ item }">
                            {{ convertToCurrencyFormat(item.total_sale_price) }}
                        </template>

                        <template #cell-sale_payment_methods="{ item }">
                            <div class="d-flex justify-content-center">
                                <button
                                    class="d-flex align-items-center btn btn-outline-success ms-3"
                                    :key="item.sale_payment_methods[0].payment_method.name"
                                    disabled
                                >
                                    <img
                                        :src="`${item.sale_payment_methods[0].payment_method.photo_identification}`"
                                        alt=""
                                        width="20"
                                    />
                                    <span class="ms-2 fs-12">{{
                                        item.sale_payment_methods[0].payment_method.name
                                    }}</span>
                                </button>
                            </div>
                        </template>

                        <template #cell-user_avatar="{ item }">
                            <div class="d-flex justify-content-center">
                                <LazyImage
                                    :src="item.user_avatar"
                                    :alt="`Venda ${item.id}`"
                                    width="30px"
                                />
                            </div>
                        </template>

                        <template #cell-created_at="{ item }">
                            <span
                                v-if="isCurrentDate(item.created_at)"
                                class="badge text-bg-primary p-2"
                                >Hoje</span
                            >
                            <span v-else>{{ formatDate(item.created_at) }}</span>
                        </template>
                    </Table>
                </div>
            </div>

            <div class="row mt-3 d-flex align-items-center">
                <div class="col-lg-6 col-12 mb-2">
                    <div class="search-form-card overflow-auto" id="last-sales">
                        <div
                            class="card-header d-flex align-items-center bg-white p-3 border-bottom"
                        >
                            <span class="font-bold text-uppercase">Livros mais vendidos</span>
                        </div>
                        <div class="card-body px-3">
                            <Table
                                table-id="table-best-selling-books"
                                :headers="[
                                    { key: 'name', label: 'Livro' },
                                    {
                                        key: 'total_books_sold',
                                        label: 'Total',
                                        thClass: 'text-center',
                                        tdClass: 'text-center',
                                    },
                                ]"
                                :data="bestSellingBooks"
                                :rowLink="(item) => `/dashboard/books/${item.id}`"
                                :pagination="{ reference: 'bestSellingBooks' }"
                            >
                                <template #cell-name="{ item }">
                                    <div class="d-flex align-items-center">
                                        <img
                                            style="width: 30px"
                                            :src="`/storage/${item.cover_image_url}`"
                                            alt=""
                                        />
                                        <span class="ms-3">{{ item.name }}</span>
                                    </div>
                                </template>
                            </Table>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-12 px-4 px-sm-2">
                    <div class="row">
                        <div class="card mb-3">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Promoções</h5>
                                <p class="card-text font-regular">
                                    Elabore promoções com o objetivo de reduzir o valor dos livros
                                </p>
                                <Link
                                    href="/dashboard/promotions"
                                    class="btn btn-success btn-sm text-white font-bold"
                                    >Ver promoções
                                </Link>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Seções da loja</h5>
                                <p class="card-text font-regular">
                                    Organize as seções, atualize seus dados e ordene de maneira
                                    eficiente!
                                </p>
                                <Link
                                    href="/dashboard/store-session"
                                    class="btn btn-primary btn-sm text-white font-bold"
                                >
                                    Ver seções</Link
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
