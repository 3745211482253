<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({ name: String });

const statusStyle = computed(() => {
    const style = {
        pendente: {
            color: 'dark',
            background: 'warning',
        },
        aprovado: {
            color: 'white',
            background: 'primary',
        },
        cancelado: {
            color: 'white',
            background: 'danger',
        },
        cancelada: {
            color: 'white',
            background: 'danger',
        },
        finalizado: {
            color: 'white',
            background: 'success',
        },
        concretizada: {
            color: 'white',
            background: 'success',
        },
        vinculo_realizado: {
            color: 'white',
            background: 'primary',
        },
        livro_disponivel: {
            color: 'white',
            background: 'success',
        },
        livro_entregue: {
            color: 'white',
            background: 'success',
        },
        pastor: {
            color: 'white',
            background: 'primary',
        },
        aluno_rhema: {
            color: 'white',
            background: 'danger',
        },
        comum: {
            color: 'white',
            background: 'secondary',
        },
        YES: {
            color: 'white',
            background: 'success',
            name: 'Sim',
        },
        NOT: {
            color: 'white',
            background: 'danger',
            name: 'Não',
        },
        Comum: {
            color: 'white',
            background: 'primary',
            name: 'COMUM',
        },
        Pastor: {
            color: 'white',
            background: 'danger',
            name: 'Pastor',
        },
        CANCELLED: {
            color: 'white',
            background: 'danger',
            name: 'Cancelada',
        },
    };

    return (
        style[props.name] || {
            color: 'dark',
            background: 'warning',
            name: props.name,
        }
    );
});
</script>

<template>
    <span :class="`badge bg-${statusStyle.background} text-${statusStyle.color} p-2`">
        {{ statusStyle.name }}
    </span>
</template>
