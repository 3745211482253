<script setup>
import { onMounted } from 'vue';
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({ permissions: Object });

const form = useForm({
    name: null,
    display_name: null,
    description: null,
    permissions: [],
});

const store = () => form.post(route('roles.store'));

onMounted(() => {
    form.permissions = props.permissions.map((permission) => permission.id);
});
</script>

<template>
    <Dashboard page_title="Criar função">
        <Breadcrumb
            current-page-name="Nova função"
            :link-list="[
                {
                    previous_page_url: 'roles',
                    previous_page_name: 'Funções',
                },
            ]"
        />

        <div id="create-promotion">
            <h4 class="my-3">Nova função</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-4">
                    <label for="name" class="form-label">Nome da função</label>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="name"
                        name="name"
                        placeholder="admin"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-8">
                    <label for="display_name" class="form-label">Nome legível da função</label>
                    <input
                        type="text"
                        class="form-control"
                        id="display_name"
                        display_name="display_name"
                        placeholder="Administrador"
                        v-model="form.display_name"
                        autocomplete="off"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.display_name">{{
                        form.errors.display_name
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label">Descrição da função</label>
                    <input
                        type="text"
                        class="form-control"
                        id="description"
                        description="description"
                        placeholder="Essa função irá permite que um usuário acesse boa parte das funcionalidades do sistema"
                        v-model="form.description"
                        autocomplete="off"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.description">{{
                        form.errors.description
                    }}</small>
                </div>

                <ul class="list-group overflow-auto normal-listing p-2">
                    <li
                        class="list-group-item p-3"
                        v-for="(permission, index) in permissions"
                        :key="index"
                    >
                        <input
                            class="form-check-input me-1"
                            type="checkbox"
                            :value="permission.id"
                            :id="'checkBoxPermission' + index"
                            v-model="form.permissions"
                        />
                        <label
                            class="form-check-label stretched-link ms-3"
                            :for="'checkBoxPermission' + index"
                            style="cursor: pointer"
                            >{{ permission.display_name }} - {{ permission.description }}</label
                        >
                    </li>
                </ul>
            </Form>
        </div>
    </Dashboard>
</template>
