<script setup>
import { ref, onMounted } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import { vMaska } from 'maska';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';

import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    client: Object,
    types: Object,
    members: Object,
});

const { searchParams, compositionSeacher, toggleSelection } = useSearch({
    member_name: null,
});

const form = useForm({
    name: props.client.name,
    type_id: props.client.type_id,
    member_id: null,
    member_name: props.client.name,
    rhema_student_code: props.client.rhema_student_code,
});

const clientEditModalCloseButton = ref(null);

const update = () => form.put(route('clients.update', props.client.id));
const destroy = () => {
    clientEditModalCloseButton.value.click();
    router.delete(route('clients.destroy'), props.client.id);
};

const search = () => {
    router.visit(route('clients.edit', props.client.id), {
        method: 'get',
        data: searchParams.value,
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
        },
    });
};

onMounted(() => {
    const url = new URL(window.location.href);

    if (url.search) {
        router.get(
            route('clients.edit', props.client.id),
            {},
            { replace: true, preserveState: true }
        );
    }

    searchParams.value.member_name = props.client.name;

    if (props.members) {
        search();
    }

    if (props.client.sgi_member_id) {
        form.member_id = props.client.sgi_member_id;
    }
});
</script>

<template>
    <Dashboard :page_title="client.name">
        <Breadcrumb
            :currentPageName="client.name"
            :linkList="[
                {
                    previous_page_url: `clients/${client.id}`,
                    previous_page_name: 'Clientes',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link :href="route('clients.show', client.id)" class="btn btn-success btn-add me-2">
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#clientEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-5">
                <label for="name" class="form-label">Nome do cliente</label>
                <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    id="name"
                    name="name"
                    v-model="form.name"
                    required
                    @input="(event) => compositionSeacher('member_name', event, search)"
                />
                <small class="text-danger mt-3" v-if="form.errors.name">{{
                    form.errors.name
                }}</small>
            </div>

            <div class="col-md-4">
                <label for="type" class="form-label">Tipo de cliente</label>
                <select
                    class="form-select"
                    id="payment_method_id"
                    name="payment_method_id"
                    v-model="form.type_id"
                    required
                >
                    <option :value="type.id" v-for="(type, index) in types" :key="index">
                        {{ type.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-3">
                    <label for="name" class="form-label">COD Rhema</label>
                    <input
                        id="member_id"
                        v-model="form.rhema_student_code"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="000000"
                        v-maska
                        data-maska="######"
                    />

                    <small class="text-danger mt-3" v-if="form.errors.rhema_student_code">{{
                        form.errors.rhema_student_code
                    }}</small>
                </div>

            <template v-if="members?.data">
                <hr class="mb-0" />

                <div class="alert alert-primary">
                    Verifique se esse cliente já é um membro da nossa igreja
                </div>

                <ul class="list-group member-found mt-0">
                    <li
                        v-for="(item, index) in members.data"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-2"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxSpouse' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id, form)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxSpouse' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex align-items-center">
                                <LazyImage
                                    :src="`${item.identification_photo}`"
                                    :alt="`Client ${item.name}`"
                                    width="30px"
                                />
                                <span class="ms-3">{{ item.name }}</span>
                            </div>
                        </label>
                    </li>
                </ul>
            </template>
        </Form>

        <ConfirmationModal modal_id="clientEditModal" modal_title="Confirmação de remoção do autor">
            <template v-slot:modal-text>
                Você tem certeza que deseja excluir o autor {{ client.name }}?
            </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="clientEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar autor
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
