<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({ categories: Object });

const form = useForm({
    name: '',
    description: '',
    visible: true,
    rhema: 0,
    categories: [],
});

const store = () => form.post(route('store-session.store'));
</script>

<template>
    <Dashboard page_title="Criar seção">
        <Breadcrumb
            current-page-name="Nova seção"
            :link-list="[
                {
                    previous_page_url: 'store-session',
                    previous_page_name: 'Seções',
                },
            ]"
        />

        <div id="create-store-session">
            <h4 class="my-3">Nova seção</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="row g-3">
                    <div class="col-md-5 p-0">
                        <label for="name" class="form-label">Nome da seção da loja</label>
                        <input
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            id="name"
                            name="name"
                            v-model="form.name"
                            placeholder="Destaque"
                            required
                        />
                        <small class="text-danger mt-3" v-if="form.errors.name">{{
                            form.errors.name
                        }}</small>
                    </div>

                    <div class="col-md-4 col-6">
                        <label for="rhema" class="form-label">Rhema</label>
                        <select class="form-select" name="rhema" id="rhema" v-model="form.rhema">
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                        </select>
                    </div>

                    <div class="col-lg-3 col-md-4 d-flex align-items-end justify-content-center">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="visible"
                                v-model="form.visible"
                            />
                            <label class="form-check-label" for="visible"> Seção visível </label>
                        </div>
                    </div>

                    <div class="col-md-12 p-0">
                        <label for="description" class="form-label">Descrição da seção</label>
                        <small>(opcional)</small>
                        <textarea
                            type="text"
                            class="form-control"
                            id="description"
                            name="description"
                            v-model="form.description"
                            rows="5"
                        ></textarea>
                        <small class="text-danger mt-3" v-if="form.errors.description">{{
                            form.errors.description
                        }}</small>
                    </div>

                    <legend>Categorias</legend>

                    <ul class="list-group overflow-auto" id="categoryListing">
                        <li
                            class="list-group-item p-3"
                            v-for="(category, index) in categories"
                            :key="index"
                        >
                            <input
                                class="form-check-input me-1"
                                type="checkbox"
                                :value="category.id"
                                :id="'checkBoxCategory' + index"
                                v-model="form.categories"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13"
                                :for="'checkBoxCategory' + index"
                                style="cursor: pointer"
                                >{{ category.name }}</label
                            >
                        </li>
                    </ul>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
