<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const form = useForm({
    name: null,
    description: null,
    discount_available: 0,
});

const store = () => form.post(route('client-types.store'));
</script>

<template>
    <Dashboard page_title="Criar Tipo de Cliente">
        <Breadcrumb
            current-page-name="Novo Tipo de Cliente"
            :link-list="[
                {
                    previous_page_url: 'client-types',
                    previous_page_name: 'Tipos de Cliente',
                },
            ]"
        />

        <div id="create-client-type">
            <h4 class="my-3">Novo Tipo de Cliente</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-12">
                    <label for="name" class="form-label">Nome do Tipo de Cliente</label>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="name"
                        placeholder="Pastor"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label">Descrição</label>
                    <small class="ms-2">(opcional)</small>
                    <textarea
                        class="form-control"
                        id="description"
                        v-model="form.description"
                        placeholder="Descreva este tipo de cliente"
                        rows="5"
                    ></textarea>
                    <small class="text-danger mt-3" v-if="form.errors.description">{{
                        form.errors.description
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="discount_available" class="form-label"
                        >Desconto Disponível (%)</label
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="discount_available"
                        v-model="form.discount_available"
                        max="100"
                        maxlength="3"
                        min="0"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.discount_available">{{
                        form.errors.discount_available
                    }}</small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
