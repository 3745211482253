<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Components/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Composables/useImageResizer';

const props = defineProps({ roles: Object });

const form = useForm({
    name: '',
    email: '',
    profile_image_url: '/img/avatars/male.png',
    changed_photo: false,
    role_id: props.roles[0].id,
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.profile_image_url);

const store = () => {
    form.profile_image_url = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('users.store'));
};
</script>

<template>
    <Dashboard page_title="Criar membro">
        <Breadcrumb
            current-page-name="Novo membro"
            :link-list="[
                {
                    previous_page_url: 'users',
                    previous_page_name: 'Membros',
                },
            ]"
        />

        <div id="create-category">
            <h4 class="my-3">Novo membro</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.changed_photo">
                    {{ form.errors.changed_photo }}
                </div>

                <div class="col-md-6">
                    <label for="name" class="form-label">Nome</label>
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="name"
                        name="name"
                        v-model="form.name"
                        placeholder="Matheus"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-6">
                    <label for="role" class="form-label">Função</label>
                    <select class="form-select" name="role" v-model="form.role_id">
                        <option v-for="(role, index) in roles" :key="index" :value="role.id">
                            {{ role.display_name }}
                        </option>
                    </select>
                    <small class="text-danger mt-3" v-if="form.errors.role">{{
                        form.errors.role
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="email" class="form-label">Email</label>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="email"
                        name="email"
                        v-model="form.email"
                        placeholder="matheus@gmail.com"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.email">{{
                        form.errors.email
                    }}</small>
                </div>

                <PhotoEditModal
                    v-if="form.profile_image_url"
                    title="Adicionar foto"
                    id="photoUpdateModal"
                    @image-resized="handleImageResized"
                />
            </Form>
        </div>
    </Dashboard>
</template>
