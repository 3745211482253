<script setup>
import { ref, reactive, nextTick, computed, watch, defineProps, onMounted } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import BookImage from '@/Pages/Dashboard/Pages/Books/Components/BookImage.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

import { vMaska } from 'maska';

const inputValue = ref('');
const isbnInput = ref(null);

const props = defineProps({
    book: Object,
    clients: Object,
    paymentMethods: Object,
    events: Object,
});

const { convertToCurrencyFormat } = usePriceFormatter();

const form = useForm({
    client: null,
    event: null,
    books: [],
    payments: [],
    created_at: null,
});

const seacher = reactive({
    client_name: null,
    book_isbn: null,
});

const store = () => {
    console.log(form.books)
    form.transform((data) => ({
        ...data,
        client: form.client?.id,
        event: form.event?.id,
        books: data.books.map((book) => ({
            book_id: book.id,
            quantity: book.quantity,
            discount_applied: book.clientDiscountApplied,
            unit_price: book.price,
            total_books_price: book.price * book.quantity,
        })),
        payments: selectedPayments.value.map((payment) => ({
            payment_method_id: payment.id,
            amount: payment.amount,
        })),
    })).post(route('sales.store'));
};

let typingTimer = null;

const search = () => {
    router.visit(`/dashboard/sales/create`, {
        method: 'get',
        data: { seacher },
        only: ['clients', 'book'],
        preserveState: true,
        preserveScroll: true,
    });
};

const compositionSeacher = (field, event) => {
    if (field == 'book_isbn') {
        inputValue.value = event.target.value;

        if (inputValue.value.length >= 34) {
            seacher.book_isbn = inputValue.value.slice(-17);
        } else {
            seacher.book_isbn = inputValue.value;
        }
    } else {
        seacher[field] = event.target.value;
    }

    clearTimeout(typingTimer);

    typingTimer = setTimeout(async () => {
        await nextTick();
        search();
    }, 1000);
};

const addBook = () => {
    if (!props.book || form.books.some((b) => b.id === props.book.id)) return;

    let bookPrice = props.book.original_price;
    let bookPromotion = null;
    let clientDiscount = form.client ? form.client.discount_available : 0;
    let bestDiscount = 0;

    if (props.book.current_promotion && props.book.current_promotion.active) {
        if (props.book.current_promotion.rhema && form.client?.type !== 'Aluno Rhema') {
            bookPromotion = null;
            bestDiscount = 0;
        } else {
            bestDiscount =
                ((props.book.original_price - props.book.current_promotion.discounted_price) /
                    props.book.original_price) *
                100;
            bookPromotion = props.book.current_promotion;
        }
    }

    if (clientDiscount > bestDiscount) {
        bestDiscount = clientDiscount;
        bookPromotion = null;
    }
    
    if (form.client?.type == 'Aluno Rhema' && !(props.book.current_promotion && props.book.current_promotion.active)) {
        bestDiscount = 0;
        clientDiscount = 0;
        bookPromotion = null;
    }

    bookPrice = props.book.original_price * (1 - bestDiscount / 100);

    form.books.push({
        id: props.book.id,
        name: props.book.name,
        isbn: props.book.isbn,
        quantity: 1,
        stock: props.book.quantity,
        price: bookPrice,
        originalPrice: props.book.original_price,
        photo: props.book.cover_image_url,
        promotion: bookPromotion,
        clientDiscountApplied: bestDiscount,
    });

    seacher.book_isbn = null;
    if (isbnInput.value) {
        isbnInput.value.value = '';
    }

    adjustPayments();
};

const removeBook = (index) => {
    form.books.splice(index, 1);
};

const totalBooksPrice = computed(() => {
    return form.books.reduce((sum, book) => sum + book.quantity * book.price, 0);
});

const toggleSelection = (field, client) => {
    if (form[field] && form[field].id === client.id) {
        form[field] = null;
    } else {
        form[field] = client;
    }
};

const selectedPayments = ref([]);

const addPaymentMethod = (paymentMethod) => {
    if (!selectedPayments.value.some((p) => p.id === paymentMethod.id)) {
        let remainingAmount = totalBooksPrice.value;

        if (selectedPayments.value.length > 0) {
            remainingAmount = 0;
        }

        selectedPayments.value.push({
            id: paymentMethod.id,
            name: paymentMethod.name,
            amount: parseFloat(remainingAmount.toFixed(2)),
        });
    }
};

const adjustedTotalPrice = computed(() => {
    return form.books.reduce((sum, book) => sum + book.quantity * book.price, 0);
});

const adjustPayments = () => {
    let totalAssigned = selectedPayments.value.reduce((sum, p) => sum + p.amount, 0);
    let remaining = adjustedTotalPrice.value - totalAssigned;

    if (remaining !== 0 && selectedPayments.value.length > 0) {
        selectedPayments.value[selectedPayments.value.length - 1].amount = parseFloat(
            (selectedPayments.value[selectedPayments.value.length - 1].amount + remaining).toFixed(
                2
            )
        );
    }
};

const removePaymentMethod = (index) => {
    selectedPayments.value.splice(index, 1);
    adjustPayments();
};

watch(selectedPayments, adjustPayments, { deep: true });

onMounted(() => {
    const url = new URL(window.location.href);

    if (url.search) {
        router.get(route('sales.create'), {}, { replace: true, preserveState: true });
    }
});

const totalDiscountApplied = computed(() => {
    return form.books.reduce((sum, book) => {
        let originalPrice = book.promotion ? book.promotion.original_price : book.price;

        if (form.client && form.client.discount_available) {
            originalPrice -= (originalPrice * form.client.discount_available) / 100;
        }

        let discount = originalPrice - book.price;
        return sum + discount * book.quantity;
    }, 0);
});

const totalWithoutDiscount = computed(() => {
    return form.books.reduce((sum, book) => sum + book.quantity * book.originalPrice, 0);
});

const adjustBookQuantity = (book) => {
    if (book.quantity > book.stock) {
        book.quantity = book.stock;
    }
};

const recalculateBookPrices = () => {
    form.books.forEach((book) => {
        let bookPrice = book.originalPrice;
        let clientDiscount = form.client ? form.client.discount_available : 0;
        let bestDiscount = 0;
        let bookPromotion = book.promotion;

        if (bookPromotion) {
            bestDiscount =
                ((book.originalPrice - bookPromotion.discounted_price) / book.originalPrice) * 100;
        }

        if (clientDiscount > bestDiscount) {
            bestDiscount = clientDiscount;
            bookPromotion = null;
        }

        book.price = book.originalPrice * (1 - bestDiscount / 100);
        book.clientDiscountApplied = clientDiscount >= bestDiscount ? clientDiscount : null;
    });

    adjustPayments();
};

watch(
    () => form.client,
    () => {
        recalculateBookPrices();
    },
    { deep: true }
);
</script>

<template>
    <Dashboard page_title="Vender livro">
        <Breadcrumb currentPageName="Vender livro" />

        <div class="px-5 py-4 mt-3 form-card mt-3">
            <form>
                <div class="col-lg-12">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-7">
                            <div class="row g-4">
                                <div class="col-md-12">
                                    <label for="client_id" class="form-label">Cliente</label>
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            @input="compositionSeacher('client_name', $event)"
                                            placeholder="Informe o nome do cliente"
                                            autocomplete="off"
                                            required
                                        />
                                        <a
                                            target="_blank"
                                            :href="route('clients.create')"
                                            class="btn btn-success font-semibold text-white float-end"
                                            ><font-awesome-icon :icon="['fas', 'plus']"
                                        /></a>
                                    </div>
                                </div>

                                <div class="col-lg-12" v-if="clients.length">
                                    <ul class="list-group member-found">
                                        <li
                                            v-for="(item, index) in clients"
                                            :key="index"
                                            class="list-group-item p-2 d-flex align-items-center mb-3"
                                            :class="{ checked: form.client?.id === item.id }"
                                        >
                                            <input
                                                :id="'checkBoxSpouse' + item.id"
                                                class="form-check-input me-1 mb-0"
                                                type="checkbox"
                                                name="listGroupRadio"
                                                :value="item"
                                                :checked="form.client && form.client.id === item.id"
                                                @change="toggleSelection('client', item)"
                                            />

                                            <label
                                                class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                                                :for="'checkBoxSpouse' + item.id"
                                                style="cursor: pointer"
                                            >
                                                <div
                                                    class="d-flex justify-content-between align-items-center"
                                                >
                                                    <span>{{ item.name }}</span>
                                                    <a
                                                        :href="route('clients.show', item.id)"
                                                        class="btn btn-primary btn-add z-2 me-3"
                                                        target="_blank"
                                                        >Acessar perfil</a
                                                    >
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-md-12" v-if="form.client?.id">
                                    <label for="book_id" class="form-label">ISBN</label>

                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            ref="isbnInput"
                                            v-maska
                                            data-maska="###-##-####-###-# ###-##-####-###-#"
                                            @input="compositionSeacher('book_isbn', $event)"
                                            placeholder="Informe o ISBN do livro"
                                            autocomplete="off"
                                            required
                                            @keydown.enter.prevent
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            @click="addBook"
                                            :disabled="!book?.id"
                                        >
                                            Adicionar livro
                                        </button>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <label for="created_at" class="form-label">Data de venda</label>
                                    <input
                                        type="datetime-local"
                                        class="form-control"
                                        v-model="form.created_at"
                                        id="created_at"
                                        name="created_at"
                                    />
                                    <small class="text-danger mt-3" v-if="form.errors.created_at">{{
                                        form.errors.created_at
                                    }}</small>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5 mt-4" v-if="props.book">
                            <BookImage :url="`/storage/${props.book.cover_image_url}`" />
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="px-5 py-4 mt-3 form-card mt-3" v-if="form.books.length">
            <form>
                <div class="row">
                    <div>
                        <legend class="mb-4">
                            {{ form.books.length }} Livro(s) Selecionado(s)
                        </legend>
                        <ul class="list-group">
                            <li
                                v-for="(book, index) in form.books"
                                :key="book.id"
                                class="list-group-item d-flex justify-content-between align-items-center font-semibold fs-12"
                            >
                                <div class="d-flex align-items-center p-2">
                                    <img
                                        :src="`/storage/${book.photo}`"
                                        alt=""
                                        width="30"
                                        class="me-2"
                                    />
                                    <div class="flex-grow-1">
                                        <div>
                                            {{ book.name }} ({{ book.quantity }}x) -
                                            {{ convertToCurrencyFormat(book.price) }}
                                        </div>
                                        <div v-if="book.promotion">
                                            <span class="text-success">
                                                <font-awesome-icon
                                                    :icon="['fas', 'user-tag']"
                                                    class="me-1"
                                                />
                                                Promoção: {{ book.promotion.discount_percentage }}%
                                                OFF!
                                            </span>
                                        </div>
                                        <div
                                            v-else-if="
                                                form.client && form.client.discount_available && book.clientDiscountApplied > 0
                                            "
                                        >
                                            <span class="text-primary">
                                                <font-awesome-icon
                                                    :icon="['fas', 'user-tag']"
                                                    class="me-1"
                                                />
                                                Desconto do cliente:
                                                {{ form.client.discount_available }}% OFF
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-group input-group-sm w-auto">
                                    <input
                                        type="number"
                                        v-model.number="book.quantity"
                                        :max="book.stock"
                                        class="form-control text-center"
                                        style="max-width: 80px"
                                        @input="adjustBookQuantity(book)"
                                    />
                                    <button
                                        type="button"
                                        class="btn btn-danger btn-sm"
                                        @click="removeBook(index)"
                                    >
                                        <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>

        <div class="px-5 py-4 mt-3 form-card mt-3" v-if="form.books.length">
            <form>
                <div>
                    <legend class="mb-4">Formas de Pagamento Disponíveis</legend>
                    <div class="d-flex justify-content-center">
                        <button
                            type="button"
                            class="btn me-3"
                            :class="{
                                'btn-outline-success': !selectedPayments.some(
                                    (p) => p.id === method.id
                                ),
                                'btn-primary': selectedPayments.some((p) => p.id === method.id),
                            }"
                            v-for="method in props.paymentMethods"
                            :key="method.id"
                            @click="addPaymentMethod(method)"
                        >
                            <img :src="`${method.photo_identification}`" alt="" width="30" />
                            <span class="ms-2">{{ method.name }}</span>
                        </button>
                    </div>

                    <ul class="my-4 list-group" v-if="selectedPayments.length">
                        <li
                            class="list-group-item d-flex justify-content-between align-items-center font-semibold fs-12"
                            v-for="(payment, index) in selectedPayments"
                            :key="payment.id"
                        >
                            {{ payment.name }}
                            <div class="input-group input-group-sm w-auto">
                                <span class="input-group-text" id="basic-addon1"> R$ </span>
                                <input
                                    class="form-control text-center"
                                    type="text"
                                    v-model.number="payment.amount"
                                    @input="adjustPayments"
                                    style="max-width: 80px"
                                />
                                <button
                                    class="btn btn-danger btn-sm"
                                    type="button"
                                    @click="removePaymentMethod(index)"
                                >
                                    <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </form>
        </div>

        <div class="px-5 py-4 mt-3 form-card mt-3" v-if="selectedPayments.length && events">
            <div class="col-lg-12">
                <legend class="mb-4">Evento relacionado</legend>
                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in events"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mt-2"
                        :class="{ checked: form.event?.id === item.id }"
                    >
                        <input
                            :id="'checkBoxSpouse' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item"
                            :checked="form.event && form.event.id === item.id"
                            @change="toggleSelection('event', item)"
                        />

                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxSpouse' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.schedule.name }} - {{ item.start_time }}</span>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>
        </div> 

        <div class="px-5 py-4 mt-3 form-card mt-3" v-if="selectedPayments.length">
            <div v-if="totalDiscountApplied > 0">
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between">
                        <div>
                            Desconto total de
                            <span class="font-bold">{{
                                convertToCurrencyFormat(totalDiscountApplied)
                            }}</span>
                            aplicado sobre o subtotal de
                            <span class="font-bold">{{
                                convertToCurrencyFormat(totalWithoutDiscount)
                            }}</span>
                        </div>
                    </li>
                </ul>
                <hr />
            </div>

            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <span class="font-bold">Valor total da compra: </span>
                    <span class="font-bold text-secondary">{{ convertToCurrencyFormat(adjustedTotalPrice) }}</span>
                </div>
                <button class="btn btn-primary" type="button" @click="store()">
                    Finalizar compra
                </button>
            </div>
        </div>
    </Dashboard>
</template>
