<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';

import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    clients: Object,
});

const { searchParams, isLoading, compositionSeacher, executeSearch, updateLoadingState } =
    useSearch(
        {
            name: '',
            type: '',
        },
        'clients.index'
    );

watch(
    () => props.clients,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page_title="Listagem de clientes" main-data="clients">
        <Breadcrumb currentPageName="Listagem de clientes" />

        <PageHeader title="Clientes" :button-link="route('clients.create')" button-text="Novo" />

        <div class="p-3 search-form-card">
            <div class="d-flex justify-content-between align-items-center mt-2 row px-3">
                <div class="font-bold col-12 col-sm-6">Baixar clientes</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <ButtonWithIcon
                        :href="`/dashboard/clients/pdf/export`"
                        background="danger"
                        color="white"
                        icon="file-pdf"
                        :linkInertia="false"
                        message="PDF"
                        additionalStyles="me-2"
                    />

                    <ButtonWithIcon
                        :href="`/dashboard/clients/xlxs/export`"
                        background="success"
                        color="white"
                        icon="file-excel"
                        :linkInertia="false"
                        message="Excel"
                    />
                </div>
            </div>

            <hr class="mx-3" />
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-8">
                        <label class="form-label" for="name">Nome do cliente</label>
                        <input
                            id="name"
                            type="text"
                            class="form-control"
                            placeholder="Gabriela Barbosa"
                            v-model="searchParams.name"
                            @input="(event) => compositionSeacher('name', event, executeSearch)"
                        />
                    </div>
                    <div class="col-md-4">
                        <label class="form-label" for="type">Tipo do cliente</label>
                        <select
                            class="form-select"
                            id="type"
                            v-model="searchParams.type"
                            @change="executeSearch"
                        >
                            <option selected value="">Todos os tipos</option>
                            <option value="COMUM">Comum</option>
                            <option value="PASTOR">Pastor</option>
                            <option value="ALUNO_RHEMA">Aluno Rhema</option>
                        </select>
                    </div>
                </div>
            </div>

            <Table
                table-id="table-clients"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', tdClass: 'text-start' },
                    { key: 'type', label: 'Tipo', thClass: 'text-center', tdClass: 'text-center' },
                    {
                        key: 'created_at',
                        label: 'Criação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="clients?.data"
                :pagination="{ data: clients?.meta, reference: 'clients' }"
                :rowLink="(item) => route('clients.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    {{ item.name }}
                </template>

                <template #cell-type="{ item }">
                    <Badge :name="item.type" />
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
