<script setup>
import { Link } from '@inertiajs/vue3';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';

import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    order: Object,
    approvals: Array,
});

const { convertToCurrencyFormat } = usePriceFormatter();

const getColorClass = (event) => {
    const iconClasses = {
        created: 'bg-warning',
        finished: 'bg-success',
        approval: 'bg-primary',
        canceled: 'bg-danger',
    };

    return `timeline-icon ${iconClasses[event]}`;
};
</script>

<template>
    <Dashboard :page_title="`Pedido nº ${order.id}`">
        <div class="row mx-0">
            <Breadcrumb
                :currentPageName="`Pedido nº ${order.id}`"
                :linkList="[{ previous_page_url: 'orders', previous_page_name: 'Pedidos' }]"
            />
            <div class="card-simple p-4" id="show-order">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-lg-4"></div>
                    <div class="col-lg-8 col-12 mt-4 mt-sm-0 text-end">
                        <ButtonWithIcon
                            :href="`/dashboard/orders/edit/${order.id}`"
                            background="primary"
                            color="white"
                            icon="pen-to-square"
                            message="Editar"
                            additionalStyles="me-2"
                        />

                        <ButtonWithIcon
                            v-if="order.status != 'FINALIZADO' && order.status != 'CANCELADO'"
                            :href="`/dashboard/orders/${order.id}/book-order/create`"
                            background="success"
                            color="white"
                            icon="plus"
                            message="Novo livro"
                        />
                    </div>
                </div>
                <hr />
                <div class="box-order d-flex justify-content-center">
                    <h4>PEDIDO #{{ order.id }}</h4>
                </div>
                <ul
                    class="nav nav-tabs d-flex justify-content-center mt-4"
                    id="myTab"
                    role="tablist"
                >
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link active"
                            id="data-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#data-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="data-tab-pane"
                            aria-selected="true"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'book']" /> Dados
                        </button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="timeline-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#timeline-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="timeline-tab-pane"
                            aria-selected="false"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'list-check']" />Ações
                        </button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#requested-books-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="requested-books-tab-pane"
                            aria-selected="false"
                            :disabled="order.book_order.length < 1"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'book']" /> Livros
                            solicitados
                        </button>
                    </li>

                    <li class="nav-item" role="presentation" v-if="order.client_order.length">
                        <button
                            class="nav-link"
                            id="contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#requested-books-clients-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="requested-books-clients-tab-pane"
                            aria-selected="false"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'user-group']" /> Pedidos
                            clientes
                        </button>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div
                        class="tab-pane fade show active col-11 mx-auto py-3"
                        id="data-tab-pane"
                        role="tabpanel"
                        aria-labelledby="data-tab"
                        tabindex="0"
                    >
                        <div class="row" v-if="order.book_order.length > 0">
                            <p class="font-semibold border-start border-5 border-primary p-2 fs-14">
                                Pedido possui {{ order.total_books }} livros, e irá custar um total
                                de {{ convertToCurrencyFormat(order.total_orders_price, 3) }}
                            </p>
                            <hr />
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <span class="font-bold">Solicitante:</span>
                            </div>
                            <div class="col-lg-8 col-12 text-end font-semibold">
                                <img
                                    class="rounded-circle me-2"
                                    :src="order.creator_user.avatar"
                                    alt=""
                                    style="width: 30px"
                                />
                                {{ order.creator_user.name }}
                            </div>
                        </div>

                        <hr v-if="order.observation" />
                        <p class="mb-0" v-if="order.observation">
                            <font-awesome-icon class="me-2" :icon="['fas', 'circle-info']" />
                            {{ order.observation }}
                        </p>

                        <div v-if="order.book_order.length <= 0 && order.status != 'FINALIZADO'">
                            <hr />
                            <div class="mt-4">
                                <span
                                    class="font-semibold border-start border-5 border-danger p-2 fs-14"
                                >
                                    Nenhum livro foi adicionado ao pedido ainda
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="tab-pane fade col-11 mx-auto py-3"
                        id="requested-books-tab-pane"
                        role="tabpanel"
                        aria-labelledby="data-tab"
                        tabindex="0"
                    >
                        <div class="card mb-3 border-0 overflow-y">
                            <div class="d-flex justify-content-between align-items-center mt-2 row">
                                <div class="font-bold col-12 col-md-6">Baixar dados do pedido</div>
                                <div
                                    class="col-12 col-md-6 text-md-end mt-3 mt-sm-0 text-end text-sm-start"
                                >
                                    <ButtonWithIcon
                                        :href="`/dashboard/orders/${order.id}/xlxs/export`"
                                        background="success"
                                        color="white"
                                        icon="file-excel"
                                        :linkInertia="false"
                                        message="Excel"
                                        additionalStyles="me-2"
                                    />
                                    <ButtonWithIcon
                                        :href="`/dashboard/orders/${order.id}/pdf/export`"
                                        background="danger"
                                        color="white"
                                        icon="file"
                                        :linkInertia="false"
                                        message="PDF"
                                    />
                                </div>
                            </div>

                            <hr />

                            <Table
                                table-id="table-book-orders"
                                :headers="[
                                    {
                                        key: 'index',
                                        label: '#',
                                        thClass: 'text-center',
                                        tdClass: 'text-center fw-bold',
                                    },
                                    {
                                        key: 'book_name',
                                        label: 'Livro',
                                        thClass: 'text-center',
                                        tdClass: 'text-start',
                                    },
                                    {
                                        key: 'quantity',
                                        label: 'Quantidade',
                                        thClass: 'text-center',
                                        tdClass: 'text-center',
                                    },
                                    {
                                        key: 'unit_price',
                                        label: 'P. Unitário',
                                        thClass: 'text-center',
                                        tdClass: 'text-center',
                                    },
                                    {
                                        key: 'total_books_price',
                                        label: 'P. Total',
                                        thClass: 'text-center',
                                        tdClass: 'text-center',
                                    },
                                ]"
                                :data="
                                    order.book_order.map((bookOrder, index) => ({
                                        index: parseInt(index) + 1,
                                        book_name: bookOrder.book_name,
                                        quantity: bookOrder.quantity,
                                        unit_price: convertToCurrencyFormat(
                                            bookOrder.unit_price,
                                            3
                                        ),
                                        total_books_price: convertToCurrencyFormat(
                                            bookOrder.total_books_price,
                                            3
                                        ),
                                        book_id: bookOrder.book_id,
                                    }))
                                "
                                :pagination="{
                                    reference: 'order',
                                }"
                                :rowLink="(item) => route('books.show', item.book_id)"
                            >
                                <template #cell-index="{ item }"> {{ item.index }} </template>
                            </Table>
                        </div>
                    </div>
                    <div
                        class="tab-pane fade"
                        id="timeline-tab-pane"
                        role="tabpanel"
                        aria-labelledby="timeline-tab"
                        tabindex="0"
                    >
                        <div class="overflow-auto my-4">
                            <div class="card border-0 timeline mt-3">
                                <div class="card-body px-5 border-0">
                                    <ul class="timeline-with-icons">
                                        <li
                                            class="timeline-item mb-4"
                                            v-for="(activity, index) in approvals"
                                            :key="index"
                                        >
                                            <span :class="getColorClass(activity.event)">
                                                <font-awesome-icon
                                                    class="text-white"
                                                    :icon="['fas', 'box-archive']"
                                                />
                                            </span>
                                            <h5 class="timeline-title">{{ activity.title }}</h5>
                                            <p class="text-muted mb-2 font-semibold fs-13">
                                                {{ activity.date }}
                                            </p>
                                            <p class="text-muted d-flex alig-item-center">
                                                <span class="me-3">
                                                    <img
                                                        class="rounded-circle"
                                                        width="25"
                                                        :src="activity.user_avatar"
                                                        alt=""
                                                    />
                                                </span>
                                                <span>{{ activity.description }}</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tab-pane fade"
                        id="requested-books-clients-tab-pane"
                        role="tabpanel"
                        aria-labelledby="requested-books-clients-tab-pane"
                        tabindex="0"
                    >
                        <div class="overflow-auto my-4 p-3">
                            <div
                                class="card mt-3 p-3"
                                style="border-style: dashed"
                                v-for="(client_order, index) in order.client_order"
                                :key="index"
                            >
                                <div class="row d-flex justify-content-between">
                                    <div class="col-9 col-12">
                                        <h5 class="mt-0">{{ client_order.book.name }}</h5>
                                        <p>
                                            {{ client_order.client.name }} solicitou o pedido de
                                            {{ client_order.quantity }} unidade(s) do livro em
                                            {{ client_order.created_at }}.
                                        </p>
                                        <Link
                                            :href="`/dashboard/client-orders/${client_order.id}`"
                                            class="stretched-link"
                                        >
                                            Ir para pedido</Link
                                        >
                                    </div>
                                    <div
                                        class="col-3 col-12 d-flex align-items-center justify-content-end"
                                    >
                                        <img
                                            width="100"
                                            :src="`/storage/${client_order.book.cover_image_url}`"
                                            class="me-3"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
