<script setup>
import { ref } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import EventCard from '@/Pages/Dashboard/Components/Shared/EventCard.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const saleCancellationModalCloseButton = ref(null);

const props = defineProps({
    bookSales: Object,
    sale: Object,
    member: Object,
    event: Object,
});

const { convertToCurrencyFormat } = usePriceFormatter();

const cancellationSale = () => {
    router.post(`/dashboard/sales/cancel-sale/${props.sale.id}`);
    saleCancellationModalCloseButton.value.click();
};
</script>

<template>
    <Dashboard :page_title="`Venda nº ${sale.id}`">
        <div class="row mx-0">
            <Breadcrumb
                :currentPageName="`Venda nº ${sale.id}`"
                :linkList="[{ previous_page_url: 'sales', previous_page_name: 'Vendas' }]"
            />
            <div class="col-lg-12 bg-white p-4 rounded-3 border mb-4">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-sm-5">
                        <h4 class="font-bold m-0">Venda nº {{ sale.id }}</h4>
                    </div>
                    <div class="col-sm-7 col-12 mt-3 mt-sm-0 text-end">
                        <ButtonWithIcon
                            v-if="sale.status != 'CANCELADA'"
                            href="#"
                            background="danger"
                            color="white"
                            icon="ban"
                            :linkInertia="false"
                            message="Cancelar venda"
                            dataBsToggle="modal"
                            dataBsTarget="#saleCancellationModal"
                            additionalStyles="me-2"
                        />
                        <div v-else>
                            <Badge name="CANCELLED" />
                        </div>
                    </div>
                </div>

                <hr />

                <div class="alert alert-light border m-0 mb-3" role="alert">
                    <div class="d-flex justify-content-between align-items-center">
                        <span>
                            Valor da compra: {{ convertToCurrencyFormat(sale.total_sale_price) }}
                        </span>
                        <div class="d-flex">
                            <button
                                class="d-flex align-items-center btn btn-outline-success ms-3"
                                v-for="(item, index) in sale.sale_payment_methods"
                                :key="index"
                            >
                                <img
                                    :src="`${item.payment_method.photo_identification}`"
                                    alt=""
                                    width="30"
                                />
                                <span class="ms-2 fs-12">{{ item.payment_method.name }}</span>
                                <span class="ms-2" v-if="sale.sale_payment_methods.length > 1">
                                    {{ convertToCurrencyFormat(item.total_sale_price) }}</span
                                >
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    class="d-flex align-items-center font-semibold fs-12 alert alert-primary"
                    role="alert"
                >
                    <LazyImage :src="`${sale.user_avatar}`" :alt="`Vendedor`" width="30px" />
                    <span class="ms-2">{{ sale.user_name }}</span>
                    - realizou essa venda em
                    {{ sale.created_at }}
                </div>

                <ConfirmationModal
                    modal_id="saleCancellationModal"
                    modal_title="Confirmação de cancelamento de venda"
                >
                    <template v-slot:modal-text>
                        <p>Você tem certeza que deseja cancelar a venda #{{ sale.id }}?</p>
                        <p class="border-start border-5 border-primary p-3 bg-info bg-opacity-10">
                            Quando cancelada, você não podera mudar o status da venda novamente.
                        </p>
                    </template>
                    <template v-slot:modal-footer>
                        <button
                            type="button"
                            ref="saleCancellationModalCloseButton"
                            class="btn btn-secondary text-white"
                            data-bs-dismiss="modal"
                        >
                            Voltar
                        </button>
                        <button
                            @click="cancellationSale()"
                            type="button"
                            class="btn btn-success text-white"
                        >
                            Cancelar venda
                        </button>
                    </template>
                </ConfirmationModal>

                <hr />

             
                    <h5 class="font-bold mb-2">{{ bookSales.length }} Livro(s) vendido(s)</h5>
                    <Table
                        table-id="table-books"
                        :headers="[
                            {
                                key: 'index',
                                label: '#',
                                thClass: 'text-center',
                                tdClass: 'text-center fw-bold',
                            },
                            { key: 'name', label: 'Livro', tdClass: 'text-start' },
                            {
                                key: 'quantity',
                                label: 'Quantidade',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'unit_price',
                                label: 'Preço Unitário',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'total_price',
                                label: 'Preço Total',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                        ]"
                        :data="bookSales"
                        :pagination="{ reference: 'bookSales' }"
                        :rowLink="(item) => route('books.show', item.book_id)"
                    >
                        <template #cell-index="{ index }">
                            {{ index + 1 }}
                        </template>

                        <template #cell-name="{ item }">
                            <div class="d-flex align-items-center">
                                <LazyImage
                                    :src="`/storage/${item.book_image}`"
                                    :alt="`Livro ${item.book_name}`"
                                    width="30px"
                                />
                                <span class="ms-3">{{ item.book_name }}</span>
                            </div>
                        </template>

                        <template #cell-quantity="{ item }">
                            {{ item.quantity }}
                        </template>

                        <template #cell-unit_price="{ item }">
                            {{ convertToCurrencyFormat(item.unit_price) }}
                        </template>

                        <template #cell-total_price="{ item }">
                            <div class="d-flex align-items-center justify-content-center">
                                {{ convertToCurrencyFormat(item.total_books_price) }}
                                <span
                                    class="badge bg-warning text-dark ms-3"
                                    v-if="item.aplicaded_discount"
                                >
                                    {{ item.aplicaded_discount }} %
                                </span>
                            </div>
                        </template>
                    </Table>
                </div>
  

            <div class="px-5 py-4 mb-3 card-simple position-relative mt-4">
                <div class="position-absolute top-0 start-50 translate-middle">
                    <LazyImage
                        v-if="member?.identification_photo"
                        :src="`${member?.identification_photo}`"
                        :alt="`Vendedor`"
                        width="50px"
                    />
                </div>

                <br v-if="member?.identification_photo" />

                <div class="font-semibold text-center fs-14">
                    Livro(s) comprado(s) por
                    <Link :href="route('clients.show', sale.client_id)">{{
                        sale.client_name
                    }}</Link>
                </div>
            </div>

            <EventCard v-if="event" :event="event" />
        </div>
    </Dashboard>
</template>
