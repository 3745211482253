<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    authors: Object,
});

const { searchParams, isLoading, compositionSeacher, updateLoadingState, executeSearch } =
    useSearch({ name: null }, 'authors.index');

watch(
    () => props.authors,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page_title="Listagem de autores" main-data="authors">
        <Breadcrumb currentPageName="Listagem de autores" />

        <PageHeader title="Autores" :button-link="route('authors.create')" button-text="Novo" />

        <div class="p-4 search-form-card">
            <div>
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="authorName">Nome do autor</label>
                        <input
                            id="authorName"
                            type="text"
                            class="form-control"
                            v-model="searchParams.name"
                            placeholder="Bud Wright"
                            @input="(event) => compositionSeacher('name', event, executeSearch)"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-authors"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', tdClass: 'text-start' },
                    {
                        key: 'book_total',
                        label: 'Livros',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="authors?.data"
                :pagination="{
                    data: authors?.meta,
                    reference: 'authors',
                }"
                :rowLink="(item) => route('authors.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage
                            :src="`${item.profile_picture_url}`"
                            :alt="`Autor ${item.name}`"
                            width="30px"
                        />
                        <span class="ms-3">
                            {{ item.name }}
                        </span>
                    </div>
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
