<script setup>
import { onMounted, watchEffect, ref } from 'vue';
import { useForm, router, Deferred, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import Loader from '@/Pages/Dashboard/Components/ui/Loader.vue';

import { vMaska } from 'maska';

const props = defineProps({
    promotion: Object,
    booksPromotion: Object,
    allBooks: Object,
    categories: Object,
});

const form = useForm({
    name: props.promotion.name,
    discount_percentage: props.promotion.discount_percentage,
    active: props.promotion.active ? true : false,
    rhema: props.promotion.rhema ? true : false,
    books: [],
    categories: [],
});

const update = () => form.put(route('promotions.update', props.promotion.id));

const limitPercentage = () => {
    if (form.discount_percentage > 100) {
        form.discount_percentage = 100;
    }
};

const promotionEditModalCloseButton = ref(null);
const destroy = () => {
    promotionEditModalCloseButton.value.click();
    router.delete(route('promotions.destroy', props.promotion.id));
};

onMounted(() => {
    form.books = props.booksPromotion.map((book) => book.id);
});

const selectAllBooks = ref(false);

watchEffect(() => {
    if (selectAllBooks.value) {
        form.books = props.allBooks.map((book) => book.id);
    } else {
        form.books = [];
    }
});
</script>

<template>
    <Dashboard :page_title="promotion.name">
        <div class="row mx-0" id="show-client">
            <Breadcrumb
                :currentPageName="promotion.name"
                :linkList="[{ previous_page_url: 'promotions', previous_page_name: 'Promoções' }]"
            />

            <div id="edit-promotion" class="p-0">
                <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                    <div class="col-md-6">
                        <h4 class="font-bold m-0">Editar dados</h4>
                    </div>
                    <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                        <Link
                            :href="route('promotions.show', promotion.id)"
                            class="btn btn-success btn-add me-2"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                            Visualizar registro
                        </Link>
                        <a
                            data-bs-toggle="modal"
                            data-bs-target="#promotionEditModal"
                            href="#"
                            class="btn btn-danger btn-add me-2"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                            Deletar
                        </a>
                    </div>
                </div>

                <hr />

                <Form
                    button-name="Editar dados"
                    :method="update"
                    :processing="form.processing"
                    :button-disabled="form.processing"
                >
                    <div class="col-md-8">
                        <label for="name" class="form-label">Nome da promoção</label>
                        <input
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            id="name"
                            name="name"
                            placeholder="Dia das mães"
                            v-model="form.name"
                            required
                        />
                        <small class="text-danger mt-3" v-if="form.errors.name">{{
                            form.errors.name
                        }}</small>
                    </div>

                    <div class="col-md-4 col-6">
                        <label for="discount_percentage" class="form-label">Desconto em %</label>
                        <input
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            id="discount_percentage"
                            name="discount_percentage"
                            v-model="form.discount_percentage"
                            v-maska
                            data-maska="##"
                            required
                            @keyup="limitPercentage()"
                        />
                        <small class="text-danger mt-2" v-if="form.errors.discount_percentage">{{
                            form.errors.discount_percentage
                        }}</small>
                    </div>

                    <hr class="mb-0" />

                    <div class="col-md-12">
                        <div class="form-check d-flex align-items-center form-switch">
                            <input
                                id="flexCheckDefault"
                                v-model="form.rhema"
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked
                            />
                            <label class="form-check-label ms-3" for="flexCheckDefault">
                                Promoção Oficial do Rhema?
                            </label>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-check d-flex align-items-center form-switch">
                            <input
                                id="flexCheckDefault"
                                v-model="form.active"
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked
                            />
                            <label class="form-check-label ms-3" for="flexCheckDefault">
                                Promoção ativa?
                            </label>
                        </div>
                    </div>

                    <hr class="mb-0" />

                    <h4 class="mb-0">Livros vinculados</h4>

                    <Deferred data="allBooks">
                        <template #fallback>
                            <Loader />
                        </template>

                        <ul class="list-group overflow-auto normal-listing p-2 mt-2">
                            <li class="list-group-item p-3">
                                <input
                                    class="form-check-input me-1"
                                    type="checkbox"
                                    :id="'selectAllBooks'"
                                    v-model="selectAllBooks"
                                />
                                <label
                                    class="form-check-label stretched-link ms-3 fs-13"
                                    :for="'selectAllBooks'"
                                    style="cursor: pointer"
                                    >Selecionar todos os livros</label
                                >
                            </li>
                            <li
                                class="list-group-item p-3"
                                v-for="(book, index) in allBooks"
                                :key="index"
                            >
                                <input
                                    class="form-check-input me-1"
                                    type="checkbox"
                                    :value="book.id"
                                    :id="'checkBoxBook' + book.id"
                                    :checked="form.books.includes(book.id) ? true : false"
                                    v-model="form.books"
                                />
                                <label
                                    class="form-check-label stretched-link ms-3 fs-13"
                                    :for="'checkBoxBook' + book.id"
                                    style="cursor: pointer"
                                    >{{ book.name }}</label
                                >
                            </li>
                        </ul>
                    </Deferred>

                    <hr class="mb-0" />

                    <h4 class="mb-0">Categorias vinculadas</h4>

                    <Deferred data="categories">
                        <template #fallback>
                            <Loader />
                        </template>

                        <ul class="list-group overflow-auto normal-listing p-2 mt-2">
                            <li
                                class="list-group-item p-3"
                                v-for="(category, index) in categories"
                                :key="index"
                            >
                                <input
                                    class="form-check-input me-1"
                                    type="checkbox"
                                    :value="category.id"
                                    :id="'checkBoxCategory' + index"
                                    v-model="form.categories"
                                />
                                <label
                                    class="form-check-label stretched-link ms-3 fs-13"
                                    :for="'checkBoxCategory' + index"
                                    style="cursor: pointer"
                                    >{{ category.name }}</label
                                >
                            </li>
                        </ul>
                    </Deferred>
                </Form>

                <ConfirmationModal
                    modal_id="promotionEditModal"
                    modal_title="Confirmação de remoção da promoção"
                >
                    <template v-slot:modal-text>
                        Você tem certeza que deseja excluir a promoção {{ promotion.name }}?
                    </template>
                    <template v-slot:modal-footer>
                        <button
                            type="button"
                            ref="promotionEditModalCloseButton"
                            class="btn btn-secondary text-white"
                            data-bs-dismiss="modal"
                        >
                            Voltar
                        </button>
                        <button @click="destroy" type="button" class="btn btn-danger text-white">
                            Deletar promoção
                        </button>
                    </template>
                </ConfirmationModal>
            </div>
        </div>
    </Dashboard>
</template>
