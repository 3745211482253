<script setup>
import { computed, ref } from 'vue';
import { Link, useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    errors: Object,
    order: Object,
});

const orderApprovalModalCloseButton = ref(null);
const orderCompletionModalCloseButton = ref(null);
const orderCancellationModalCloseButton = ref(null);

const { convertToCurrencyFormat } = usePriceFormatter();

const form = useForm({
    observation: props.order.observation,
});

const totalOrderAmount = computed(() => convertToCurrencyFormat(props.order.total_orders_price));

const changeOrderStatus = (status, ref) => {
    router.put(`/dashboard/orders/update-order-status/${props.order.id}`, { status });
    ref.click();
};
</script>

<template>
    <Dashboard :page_title="`Pedido nº ${order.id}`">
        <div class="row mx-0">
            <Breadcrumb
                :currentPageName="`Editar Pedido nº ${order.id}`"
                :linkList="[
                    { previous_page_url: `orders/${order.id}`, previous_page_name: 'Pedido' },
                ]"
            />

            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex mb-3 p-0 align-items-center">
                        <div class="col-12 col-sm-4 mb-3 mb-sm-0">
                            <ButtonWithIcon
                                :href="`/dashboard/orders/${order.id}`"
                                background="primary"
                                color="white"
                                icon="eye"
                                message="Visualizar"
                                additionalStyles="me-2"
                            />
                        </div>
                        <div class="col text-start text-sm-end">
                            <ButtonWithIcon
                                v-if="order.status == 'PENDENTE'"
                                href="#"
                                background="primary"
                                color="white"
                                icon="circle-check"
                                :linkInertia="false"
                                message="Aprovar"
                                dataBsToggle="modal"
                                dataBsTarget="#orderApprovalModal"
                                additionalStyles="me-2"
                            />

                            <ButtonWithIcon
                                v-else-if="order.status == 'APROVADO'"
                                href="#"
                                background="success"
                                color="white"
                                icon="circle-check"
                                :linkInertia="false"
                                message="Finalizar pedido"
                                dataBsToggle="modal"
                                dataBsTarget="#orderCompletionModal"
                                additionalStyles="me-2"
                            />

                            <template
                                v-if="order.status != 'FINALIZADO' && order.status != 'CANCELADO'"
                            >
                                <ButtonWithIcon
                                    href="#"
                                    background="danger"
                                    color="white"
                                    icon="ban"
                                    :linkInertia="false"
                                    message="Cancelar"
                                    dataBsToggle="modal"
                                    dataBsTarget="#orderCancellationModal"
                                />
                            </template>
                        </div>
                    </div>

                    <hr />

                    <div class="alert alert-info mt-4" role="alert">
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-info']" /> Pedido
                        composto por {{ order.total_books }} livros que custam um total de
                        <span ref="total_books">{{ totalOrderAmount }}</span>
                    </div>

                    <ConfirmationModal
                        modal_id="orderApprovalModal"
                        modal_title="Confirmação de aprovação de pedido"
                    >
                        <template v-slot:modal-text>
                            Você tem certeza que deseja aprovar o pedido {{ order.id }}?
                        </template>
                        <template v-slot:modal-footer>
                            <button
                                type="button"
                                ref="orderApprovalModalCloseButton"
                                class="btn btn-secondary text-white"
                                data-bs-dismiss="modal"
                            >
                                Voltar
                            </button>
                            <button
                                @click="
                                    changeOrderStatus('APROVADO', orderApprovalModalCloseButton)
                                "
                                type="button"
                                class="btn btn-success text-white"
                            >
                                Aprovar pedido
                            </button>
                        </template>
                    </ConfirmationModal>

                    <ConfirmationModal
                        modal_id="orderCompletionModal"
                        modal_title="Confirmação de finalização de pedido"
                    >
                        <template v-slot:modal-text>
                            <p>Você tem certeza que deseja finalizar o pedido {{ order.id }}?</p>
                            <p
                                class="border-start border-5 border-primary p-3 bg-info bg-opacity-10"
                            >
                                Quando finalizado, os livros do pedido serão automaticamente
                                adicionados ao estoque da loja.
                            </p>
                        </template>
                        <template v-slot:modal-footer>
                            <button
                                type="button"
                                ref="orderCompletionModalCloseButton"
                                class="btn btn-secondary text-white"
                                data-bs-dismiss="modal"
                            >
                                Voltar
                            </button>
                            <button
                                @click="
                                    changeOrderStatus('FINALIZADO', orderCompletionModalCloseButton)
                                "
                                type="button"
                                class="btn btn-success text-white"
                            >
                                Finalizar pedido
                            </button>
                        </template>
                    </ConfirmationModal>

                    <ConfirmationModal
                        modal_id="orderCancellationModal"
                        modal_title="Confirmação de cancelamento de pedido"
                    >
                        <template v-slot:modal-text>
                            <p>Você tem certeza que deseja cancelar o pedido {{ order.id }}?</p>
                            <p
                                class="border-start border-5 border-primary p-3 bg-info bg-opacity-10"
                            >
                                Quando cancelado, você não podera mudar o status do pedido
                                novamente.
                            </p>
                        </template>
                        <template v-slot:modal-footer>
                            <button
                                type="button"
                                ref="orderCancellationModalCloseButton"
                                class="btn btn-secondary text-white"
                                data-bs-dismiss="modal"
                            >
                                Voltar
                            </button>
                            <button
                                @click="
                                    changeOrderStatus(
                                        'CANCELADO',
                                        orderCancellationModalCloseButton
                                    )
                                "
                                type="button"
                                class="btn btn-success text-white"
                            >
                                Cancelar pedido
                            </button>
                        </template>
                    </ConfirmationModal>
                </div>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>
