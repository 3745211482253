<template>
    <div class="card placeholder-glow card-hover card-author p-1">
        <div
            v-if="imageHasBeenUploaded"
            class="card-header text-center text-sm-start font-semibold px-3 py-2 text-uppercase bg-white border-0"
        >
            <span class="fs-14">{{ author.name }}</span>
        </div>
        <div
            v-else
            class="card-header col-12 col-sm-4 px-3 py-2 border-0 placeholder bg-primary"
        ></div>
        <div class="card-body">
            <div class="placeholder-image d-block mx-auto" v-if="!imageHasBeenUploaded"></div>
            <img
                class="d-block mx-auto preview-image border-warning"
                :src="`${author.profile_picture_url}`"
                alt=""
                @load="uploadedImage"
                :class="{ loaded: imageHasBeenUploaded }"
            />
            <template v-if="!imageHasBeenUploaded">
                <p>
                    <span class="placeholder col-6 bg-primary"></span>
                </p>
                <a
                    href="#"
                    tabindex="-1"
                    class="btn btn-primary disabled placeholder float-end col-4"
                    :aria-hidden="imageHasBeenUploaded"
                ></a>
            </template>
            <template v-else>
                <p class="font-semibold mt-3 books-available fs-13">
                    {{ author.book_total }} livro(s) disponíveis
                </p>
                <Link
                    :href="`/autores/${author.clean_name}`"
                    class="btn btn-primary stretched-link float-sm-end text-white font-semibold fs-13"
                    :aria-hidden="!imageHasBeenUploaded"
                    >Ver livros</Link
                >
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { Link } from '@inertiajs/vue3';

const imageHasBeenUploaded = ref(false);
const props = defineProps({ author: Object });

const uploadedImage = () => {
    imageHasBeenUploaded.value = true;
};
</script>

<style scoped>
.card-author {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    transition: transform 0.2s ease;
}

.card-author:hover {
    transform: translateY(-2px);
}

.author-image-container {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    position: relative;
}

.author-image,
.placeholder-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #ffc107;
}

.placeholder-image {
    background-color: #e9ecef;
}

.books-available {
    font-size: 13px;
    font-weight: 600;
    color: #666;
}

.btn-primary {
    font-size: 13px;
    font-weight: 600;
    padding: 0.375rem 1rem;
}

.fs-14 {
    font-size: 14px;
}
</style>
