<script setup>
import { ref, defineProps } from 'vue';
import { Link } from '@inertiajs/vue3';
import Card from '@/Pages/Store/Shared/Card.vue';
import Layout from '@/Pages/Store/Layouts/Layout.vue';

const props = defineProps({
    books: Object,
    author: Object,
});

const imageHasBeenUploaded = ref(false);
const uploadedImage = () => (imageHasBeenUploaded.value = true);
</script>

<template>
    <Layout :page_title="author.name">
        <div class="col-11 mx-auto mb-4 mt-sm-5">
            <div class="row p-0" id="store-show-author">
                <div class="p-sm-2 mx-auto">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb font-semibold">
                            <li class="breadcrumb-item">
                                <Link href="/">Início</Link>
                            </li>
                            <li class="breadcrumb-item">
                                <Link href="/autores">Autores</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                {{ author.name }}
                            </li>
                        </ol>
                    </nav>
                    <div class="col-lg-12 mt-4">
                        <div class="row">
                            <div
                                class="col-12 col-sm-8 d-flex align-items-center order-2 order-sm-1"
                            >
                                <p
                                    class="text-justify border-start border-5 border-primary px-3 mt-3 mt-sm-0 m-0"
                                >
                                    {{ author.personal_description }}
                                </p>
                            </div>

                            <div
                                class="col-12 col-sm-4 d-flex align-items-center order-1 order-sm-2 text-center"
                            >
                                <img
                                    class="mx-auto preview-image"
                                    :src="`${author.profile_picture_url}`"
                                    alt=""
                                    @load="uploadedImage"
                                    :class="{ loaded: imageHasBeenUploaded }"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="books.data.length">
                    <h4
                        class="d-block fw-bold mb-sm-3 text-center my-4 text-uppercase title-with-arrows"
                    >
                        Obras do autor
                    </h4>
                    <div
                        class="row row-cols-2 row-cols-md-3 row-cols-xl-4 g-4 px-4 d-flex justify-content-center mt-sm-3 mb-4"
                    >
                        <div class="col" v-for="(book, indice) in books.data" :key="indice">
                            <Card :book="book" :displayFavorite="true" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h4 class="d-block text-center fw-bold mb-sm-3 my-4 p-0 m-0 text-uppercase">
                        Não há nenhum livro associado ao autor
                    </h4>
                </div>
            </div>
        </div>
    </Layout>
</template>
