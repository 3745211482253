<script setup>
import { Line } from 'vue-chartjs';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend
);

const props = defineProps({
    labels: Array,
    data: Array,
    title: String,
    unitMeasure: String,
});

const chartData = {
    labels: props.labels,
    datasets: [
        {
            borderColor: ['#c2dbfe'],
            fill: true,
            backgroundColor: '#3164f4',
            tension: 0.3,
            data: props.data,
        },
    ],
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            grid: {
                display: false,
            },
        },
        x: {
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: function (context) {
                    return `${context.parsed.y} ${props.unitMeasure}`;
                },
            },
        },
        legend: {
            display: false,
        },
        title: {
            display: true,
            padding: {
                top: 10,
                bottom: 30,
            },
            font: {
                size: 14,
            },
            text: props.title,
        },
    },
};
</script>

<template>
    <Line :data="chartData" :options="options" />
</template>
