<script setup>
import { ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Components/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Composables/useImageResizer';

const props = defineProps({
    paymentMethod: Object,
    errors: Object,
});

const form = useForm({
    name: props.paymentMethod.name,
    photo_identification: props.paymentMethod.photo_identification,
    _method: 'put',
    changed_photo: false,
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.photo_identification);

const paymentMethodEditModalCloseButton = ref(null);

const update = () => {
    form.photo = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('payment-methods.update', props.paymentMethod.id));
};

const destroy = () => {
    paymentMethodEditModalCloseButton.value.click();
    router.delete(route('payment-methods.destroy'), props.paymentMethod.id);
};
</script>

<template>
    <Dashboard :page_title="paymentMethod.name">
        <Breadcrumb
            :currentPageName="paymentMethod.name"
            :linkList="[
                {
                    previous_page_url: `payment-methods/${paymentMethod.id}`,
                    previous_page_name: 'Métodos',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="route('payment-methods.show', paymentMethod.id)"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#censuEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="d-flex justify-content-center">
                <div class="position-relative">
                    <img
                        :src="form.photo_identification"
                        alt="Identification Photo"
                        class="upload-box img-fluid shadow-sm"
                    />
                    <div class="position-absolute bottom-0 end-0 m-3">
                        <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#photoUpdateModal"
                            class="btn btn-edit shadow"
                        >
                            <font-awesome-icon
                                class="text-white"
                                :icon="['fas', 'pen-to-square']"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <label for="name" class="form-label">Nome do método</label>
                <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    id="name"
                    name="name"
                    v-model="form.name"
                    required
                />
                <small class="text-danger mt-3" v-if="form.errors.name">{{
                    form.errors.name
                }}</small>
            </div>

            <PhotoEditModal
                v-if="form.photo_identification"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </Form>

        <ConfirmationModal modal_id="authorEditModal" modal_title="Confirmação de remoção do autor">
            <template v-slot:modal-text>
                Você tem certeza que deseja excluir o autor {{ paymentMethod.name }}?
            </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="paymentMethodEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar autor
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
