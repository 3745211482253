<script setup>
import { computed, defineEmits } from 'vue';
import { router, usePage, Link } from '@inertiajs/vue3';

const page = usePage();
const user = computed(() => page.props.auth.user);

const emit = defineEmits('changeStateResponsiveSidebar');

const environment = import.meta.env.VITE_ENVIRONMENT;

const logout = () => router.post('/logout');
const changeStateResponsiveSidebar = () => emit('changeStateResponsiveSidebar');
</script>

<template>
    <nav class="navbar" :class="{ 'mt-3': environment === 'staging' }">
        <a href="#" class="sidebar-toggler" @click="changeStateResponsiveSidebar">
            <font-awesome-icon class="text-dark" :icon="['fas', 'bars']" />
        </a>
        <div class="navbar-content">
            <form class="m-0 search-form">
                <div class="input-group mt-2 mt-md-0">
                    <div class="input-group-text">
                        <font-awesome-icon class="mt-1" :icon="['fas', 'magnifying-glass']" />
                    </div>
                    <input
                        id="navbarForm"
                        type="text"
                        class="form-control"
                        placeholder="Buscar livro"
                        data-bs-toggle="modal"
                        data-bs-target="#bookSearchModal"
                    />
                </div>
            </form>
            <ul class="navbar-nav d-flex align-items-center">
                <li class="nav-item dropdown">
                    <a
                        id="profileDropdown"
                        class="nav-link dropdown-toggle d-flex align-items-center"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <img
                            class="rounded-circle border border-2 border-warning"
                            :src="`${user.avatar}`"
                            alt="Profile Photo"
                        />

                        <div class="d-none d-sm-flex flex-column ms-3 me-3">
                            <span class="fs-12 font-bold">{{ user.name }}</span>
                            <span class="fs-10 text-muted">{{ user.role }}</span>
                        </div>

                        <font-awesome-icon class="ms-2 ms-sm-0" :icon="['fas', 'angle-down']" />
                    </a>

                    <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
                        <div class="d-flex flex-column align-items-center border-bottom px-5 py-2">
                            <div class="mb-3">
                                <img
                                    class="rounded-circle"
                                    :src="`${user.avatar}`"
                                    alt="Profile Photo"
                                />
                            </div>
                            <div class="text-center">
                                <p class="fw-bolder m-0 fs-13">
                                    {{ user.name }}
                                </p>
                                <p class="fs-13">
                                    {{ user.email }}
                                </p>
                            </div>
                        </div>
                        <ul class="list-unstyled p-1">
                            <li class="dropdown-item">
                                <Link
                                    href="/dashboard/profile"
                                    class="text-decoration-none ms-0 d-block p-2"
                                >
                                    <font-awesome-icon class="me-3" :icon="['fas', 'sliders']" />
                                    <span class="fs-12 font-bold">Perfil</span>
                                </Link>
                            </li>
                            <li class="dropdown-item">
                                <a
                                    href="#"
                                    class="text-decoration-none ms-0 d-block p-2"
                                    @click="logout"
                                >
                                    <font-awesome-icon
                                        class="me-3"
                                        :icon="['fas', 'right-from-bracket']"
                                    />
                                    <span class="fs-12 font-bold">Sair da conta</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>
