<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

const props = defineProps({ user: Object });

const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const redefinePassword = () =>
    form.put('/user/password', {
        onSuccess: () => form.reset(),
    });
</script>

<template>
    <Dashboard page_title="Redefinir senha">
        <Breadcrumb
            currentPageName="Redefinição de senha"
            :linkList="[{ previous_page_url: 'profile', previous_page_name: 'Perfil' }]"
        />
        <h4>Redefinir senha</h4>
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3 p-4">
                <div class="row">
                    <div class="overflow-auto" id="showuser">
                        <div class="row">
                            <form
                                class="border-0 shadow-none p-3"
                                @submit.prevent="redefinePassword"
                            >
                                <div class="row g-4">
                                    <div class="col-12">
                                        <p
                                            class="border-start border-5 border-primary font-semibold rounded-1 p-3 bg-info bg-opacity-10 fs-14 mb-3"
                                        >
                                            Sua nova senha deve atender às seguintes diretrizes de
                                            segurança
                                        </p>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0">
                                                - No mínimo 8 caracteres.
                                            </li>
                                            <li class="list-group-item border-0">
                                                - Pelo menos uma letra maiúscula.
                                            </li>
                                            <li class="list-group-item border-0">
                                                - Pelo menos um número.
                                            </li>
                                        </ul>
                                        <hr />
                                    </div>

                                    <div class="col-md-4">
                                        <label for="current_password" class="form-label"
                                            >Senha atual</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            id="current_password"
                                            name="current_password"
                                            autocomplete="off"
                                            v-model="form.current_password"
                                            required
                                        />
                                        <small
                                            class="text-danger mt-3"
                                            v-if="form.errors.updatePassword?.current_password"
                                        >
                                            {{ form.errors.updatePassword.current_password }}
                                        </small>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="new_password" class="form-label"
                                            >Nova senha</label
                                        >
                                        <input
                                            type="password"
                                            autocomplete="off"
                                            class="form-control"
                                            id="new_password"
                                            name="password"
                                            v-model="form.password"
                                            required
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="password_confirmation" class="form-label"
                                            >Confirmar nova senha</label
                                        >
                                        <input
                                            type="password"
                                            autocomplete="off"
                                            class="form-control"
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            v-model="form.password_confirmation"
                                            required
                                        />
                                        <small
                                            class="text-danger mt-3"
                                            v-if="form.errors.updatePassword?.password"
                                        >
                                            {{ form.errors.updatePassword?.password }}
                                        </small>
                                    </div>
                                </div>
                                <button
                                    class="btn btn-success mt-3 float-end"
                                    type="submit"
                                    :disabled="form.processing"
                                >
                                    <span
                                        v-if="form.processing"
                                        class="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Atualizar senha
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
