<script setup>
import { ref, onMounted, watch } from 'vue';
import { Head, Link, WhenVisible } from '@inertiajs/vue3';

import useAxios from '@/Services/Axios.vue';
import usePriceFormatter from '@/Composables/usePriceFormatter.js';

import Sidebar from './Sidebar.vue';
import Navbar from './Navbar.vue';

import Toast from '@/Pages/Dashboard/Components/Shared/Toast.vue';
import { Tooltip } from 'bootstrap';

const props = defineProps({ page_title: String, mainData: String });

const sidebarOpen = ref(true);
const responsiveSidebarIsOpen = ref(false);

const changeSidebarState = () => (sidebarOpen.value = !sidebarOpen.value);
const changeStateResponsiveSidebar = () =>
    (responsiveSidebarIsOpen.value = !responsiveSidebarIsOpen.value);

const { instance: axios } = useAxios();
const { convertToCurrencyFormat } = usePriceFormatter();

const modal = ref(null);
const modalName = ref(null);

const name = ref(null);
const books = ref([]);

const searchStarted = ref(false);
const processing = ref(false);

const compositionUpdate = (event) => {
    name.value = event.target.value;
};

watch(name, (value) => {
    seacher();
});

const seacher = () => {
    searchStarted.value = true;
    processing.value = true;
    axios
        .get(`/seache?name=${name.value}`)
        .then((response) => {
            books.value = response.data.books;
        })
        .finally(() => {
            processing.value = false;
        });
};

onMounted(() => {
    const modalElement = document.getElementById('bookSearchModal');

    modalElement.addEventListener('shown.bs.modal', () => {
        modalName.value.focus();
        modalName.value.click();
    });

    new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
        trigger: 'hover',
    });
});
</script>

<template>
    <div :class="{ 'sidebar-folded': !sidebarOpen, 'sidebar-open': responsiveSidebarIsOpen }">
        <Head :title="page_title" />
        <div class="main-wrapper">
            <Sidebar
                v-on:changeSidebarState="changeSidebarState"
                v-on:changeStateResponsiveSidebar="changeStateResponsiveSidebar"
                :open="sidebarOpen"
            />
            <div class="page-wrapper">
                <Navbar v-on:changeStateResponsiveSidebar="changeStateResponsiveSidebar" />
                <div class="page-content">
                    <div class="row">
                        <div class="col-xxl-9 col-xl-12 mx-auto">
                            <slot></slot>
                        </div>
                    </div>
                    <Toast />
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="bookSearchModal"
            tabindex="-1"
            aria-labelledby="bookSearchModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="bookSearchModalLabel">Buscar livros</h1>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref="modal"
                        ></button>
                    </div>
                    <div class="modal-body bg-light p-3">
                        <form action="">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label"
                                    >Nome</label
                                >
                                <input
                                    type="text"
                                    ref="modalName"
                                    class="form-control"
                                    id="exampleFormControlInput1"
                                    v-model="name"
                                    @input="compositionUpdate"
                                    placeholder="Digite o nome do livro"
                                />
                            </div>
                        </form>

                        <ul class="list-group">
                            <Link
                                :href="`/dashboard/books/${book.id}`"
                                v-for="(book, index) in books"
                                :key="index"
                                @click="modal.click()"
                                class="list-group-item mt-2 d-flex align-items-center justify-content-between bg-white rounded-3 border"
                            >
                                <div class="d-flex">
                                    <img
                                        :src="`/storage/${book.cover_image_url}`"
                                        alt=""
                                        style="width: 50px"
                                    />
                                    <div class="fs-12 font-semibold">
                                        <span>{{ book.name }}</span
                                        ><br />
                                        <span>{{
                                            convertToCurrencyFormat(book.original_price)
                                        }}</span>
                                    </div>
                                </div>
                                <font-awesome-icon :icon="['fas', 'angle-right']" />
                            </Link>
                        </ul>

                        <div class="d-flex justify-content-center" v-if="processing">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div v-else>
                            <div
                                class="d-block text-center my-3"
                                v-if="Object.values(books).length > 0"
                            >
                                <Link
                                    :href="`/dashboard/books?name=${name}`"
                                    class="btn btn-warning fs-12 text-uppercase rounded-2 text-center font-semibold text-dark"
                                    @click="modal.click()"
                                    >Ver todos os resultados</Link
                                >
                            </div>
                            <div v-else-if="Object.values(books).length == 0 && searchStarted">
                                <div>Nenhum livro foi encontrado</div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn btn-secondary text-white fs-14 ms-3"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
