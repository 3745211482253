<script setup>
import { ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    clientType: Object,
    errors: Object,
});

const form = useForm({
    name: props.clientType.name,
    description: props.clientType.description,
    discount_available: props.clientType.discount_available,
    _method: 'put',
});

const clientTypeEditModalCloseButton = ref(null);

const update = () => form.post(route('client-types.update', props.clientType.id));
const destroy = () => {
    clientTypeEditModalCloseButton.value.click();
    router.delete(route('client-types.destroy', props.clientType.id));
};
</script>

<template>
    <Dashboard :page_title="clientType.name">
        <Breadcrumb
            :currentPageName="clientType.name"
            :linkList="[
                { previous_page_url: 'client-types', previous_page_name: 'Tipos de Cliente' },
            ]"
        />

        <div id="edit-client-type">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar Tipo de Cliente</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="route('client-types.show', clientType.id)"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#clientTypeEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <hr />

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-12">
                    <label for="name" class="form-label">Nome do Tipo de Cliente</label>
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="name"
                        name="name"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label">Descrição</label>
                    <small class="ms-2">(opcional)</small>
                    <textarea
                        class="form-control"
                        id="description"
                        name="description"
                        v-model="form.description"
                        rows="5"
                    ></textarea>
                    <small class="text-danger mt-3" v-if="form.errors.description">{{
                        form.errors.description
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="discount_available" class="form-label"
                        >Desconto Disponível (%)</label
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="discount_available"
                        v-model="form.discount_available"
                        min="0"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.discount_available">{{
                        form.errors.discount_available
                    }}</small>
                </div>
            </Form>

            <ConfirmationModal
                modal_id="clientTypeEditModal"
                modal_title="Confirmação de remoção do Tipo de Cliente"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o tipo de cliente {{ clientType.name }}?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="clientTypeEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar Tipo de Cliente
                    </button>
                </template>
            </ConfirmationModal>
        </div>
    </Dashboard>
</template>
