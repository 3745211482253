<template>
    <div
        class="book-card"
        :class="{ 'out-of-stock': !book.quantity }"
        data-aos="fade-up"
        data-aos-duration="1000"
    >
        <!-- Badges -->
        <div class="badge-container" v-if="imageHasBeenUploaded">
            <div v-if="book.current_promotion && book.quantity" class="discount-badge">
                {{ book.current_promotion.discount_percentage }}% OFF
            </div>
            <div v-if="!book.quantity" class="sold-out-badge">Esgotado</div>
        </div>

        <!-- Card Content -->
        <div class="book-content">
            <!-- Image Container -->
            <div class="image-container">
                <div class="image-wrapper">
                    <img
                        :src="`/storage/${book.cover_image_url}`"
                        :alt="book.name"
                        @load="uploadedImage"
                        :class="{
                            loaded: imageHasBeenUploaded,
                            loading: !imageHasBeenUploaded,
                        }"
                    />
                </div>
            </div>

            <!-- Book Info -->
            <div class="book-info">
                <h3 v-if="imageHasBeenUploaded" class="book-title">
                    {{ book.name }}
                </h3>
                <div
                    v-else
                    class="placeholder-title"
                    :class="{ loading: !imageHasBeenUploaded }"
                ></div>

                <div class="price-container" v-if="imageHasBeenUploaded">
                    <div class="price-wrapper">
                        <span v-if="book.current_promotion" class="original-price">
                            {{ formatPrice(book.original_price) }}
                        </span>
                        <span
                            class="current-price"
                            :class="{ promotional: book.current_promotion }"
                        >
                            {{
                                formatPrice(
                                    book.current_promotion
                                        ? book.current_promotion.discounted_price
                                        : book.original_price
                                )
                            }}
                        </span>
                    </div>
                </div>
                <div
                    v-else
                    class="placeholder-price"
                    :class="{ loading: !imageHasBeenUploaded }"
                ></div>
            </div>

            <Link :href="`/livros/${book.clean_name}`" class="card-link" :aria-label="book.name" />
        </div>
    </div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import { ref, onMounted } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const { convertToCurrencyFormat } = usePriceFormatter();
const formatPrice = (value) => convertToCurrencyFormat(value);

const props = defineProps({ book: Object });

const imageHasBeenUploaded = ref(false);

const uploadedImage = () => (imageHasBeenUploaded.value = true);

onMounted(() => {
    AOS.init({
        once: true,
    });
});
</script>

<style scoped>
.book-card {
    position: relative;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.book-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.book-content {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Image Styles */
.image-container {
    position: relative;
    margin-bottom: 1.5rem;
    padding-top: 130%;
}

.image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-wrapper img {
    max-width: 80%;
    max-height: 100%;
    object-fit: contain;
    transition: all 0.5s ease;
    transform: perspective(800px) rotateY(0deg);
}

.book-card:hover .image-wrapper img {
    transform: perspective(800px) rotateY(10deg);
}

/* Badge Styles */
.badge-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.discount-badge {
    background: #cc0000;
    color: white;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(204, 0, 0, 0.2);
}

.sold-out-badge {
    background: #666;
    color: white;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 600;
}

/* Book Info Styles */
.book-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.book-title {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.price-container {
    margin-top: auto;
    text-align: center;
}

.price-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
}

.original-price {
    color: #666;
    text-decoration: line-through;
    font-size: 0.875rem;
}

.current-price {
    font-size: 1.25rem;
    font-weight: 700;
    color: #333;
}

.current-price.promotional {
    color: #cc0000;
}

/* Loading States */
.loading {
    animation: pulse 1.5s ease-in-out infinite;
    background: #f0f0f0;
}

.placeholder-title {
    height: 1rem;
    width: 80%;
    margin: 0 auto 1rem;
    border-radius: 4px;
}

.placeholder-price {
    height: 1.5rem;
    width: 40%;
    margin: auto auto 0;
    border-radius: 4px;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Link Overlay */
.card-link {
    position: absolute;
    inset: 0;
    z-index: 1;
}

/* Out of Stock State */
.out-of-stock {
    opacity: 0.8;
}

.out-of-stock img {
    filter: grayscale(1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .book-content {
        padding: 1rem;
    }

    .book-title {
        font-size: 0.9rem;
    }

    .current-price {
        font-size: 1.1rem;
    }

    .badge-container {
        top: 0.5rem;
        right: 0.5rem;
    }

    .discount-badge,
    .sold-out-badge {
        padding: 0.35rem 0.5rem;
        font-size: 0.75rem;
    }
}
</style>
