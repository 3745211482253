<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import usePriceFormatter from '@/Composables/usePriceFormatter.js';

import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    sales: Object,
    users: Object,
});

const { searchParams, isLoading, compositionSeacher, executeSearch, updateLoadingState } =
    useSearch(
        {
            client_name: '',
            status: '',
            start_date: '',
            end_date: '',
            user_id: '',
        },
        'sales.index'
    );

watch(
    () => props.sales,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);

const { convertToCurrencyFormat } = usePriceFormatter();
</script>

<template>
    <Dashboard page_title="Listagem de vendas" main-data="sales">
        <Breadcrumb currentPageName="Listagem de vendas" />

        <PageHeader title="Vendas" :button-link="route('sales.create')" button-text="Nova" />

        <div class="p-3 search-form-card">
            <div class="d-flex justify-content-between align-items-center mt-2 row px-3">
                <div class="font-bold col-12 col-sm-6">Baixar vendas</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <ButtonWithIcon
                        :href="`/dashboard/sales/pdf/export?client_id=${searchParams.client_id}&status=${searchParams.status}&start_date=${searchParams.start_date}&end_date=${searchParams.end_date}&user_id=${searchParams.user_id}`"
                        background="danger"
                        color="white"
                        icon="file-pdf"
                        :linkInertia="false"
                        message="PDF"
                        additionalStyles="me-2"
                    />
                    <ButtonWithIcon
                        :href="`/dashboard/sales/xlxs/export?client_id=${searchParams.client_id}&status=${searchParams.status}&start_date=${searchParams.start_date}&end_date=${searchParams.end_date}&user_id=${searchParams.user_id}`"
                        background="success"
                        color="white"
                        icon="file-excel"
                        :linkInertia="false"
                        message="Excel"
                    />
                </div>
            </div>

            <hr />

            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-8">
                        <label class="form-label" for="floatingInput">Nome do cliente</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="searchParams.client_name"
                            placeholder="Matheus Souza"
                            @input="
                                (event) => compositionSeacher('client_name', event, executeSearch)
                            "
                        />
                    </div>

                    <div class="col-sm-4">
                        <label class="form-label">Status da venda</label>
                        <select
                            class="form-select"
                            v-model="searchParams.status"
                            @change="executeSearch()"
                        >
                            <option value="">Todos os status</option>
                            <option value="CONCRETIZADA">Concretizada</option>
                            <option value="CANCELADA">Cancelada</option>
                        </select>
                    </div>

                    <div class="col-6 col-sm-4">
                        <label class="form-label">Data de início</label>
                        <input
                            type="date"
                            class="form-control"
                            v-model="searchParams.start_date"
                            @change="executeSearch()"
                        />
                    </div>

                    <div class="col-6 col-sm-4">
                        <label class="form-label">Data de fim</label>
                        <input
                            type="date"
                            class="form-control"
                            v-model="searchParams.end_date"
                            @change="executeSearch()"
                        />
                    </div>

                    <div class="col-sm-4">
                        <label class="form-label">Vendedor</label>
                        <select
                            class="form-select"
                            v-model="searchParams.user_id"
                            @change="executeSearch()"
                        >
                            <option value="">Qualquer vendedor</option>
                            <option v-for="user in users" :key="user.id" :value="user.id">
                                {{ user.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <Table
                table-id="table-sales"
                :headers="[
                    { key: 'id', label: 'ID', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'client_name', label: 'Cliente', tdClass: 'text-start' },
                    {
                        key: 'total_sale_price',
                        label: 'Valor',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_books',
                        label: 'Livros',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'sale_payment_methods',
                        label: 'Método',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'user_avatar',
                        label: 'Vendedor',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="sales?.data"
                :pagination="{
                    data: sales?.meta,
                    reference: 'sales',
                }"
                :rowClass="(item, index) => [item.status == 'CANCELADA' ? 'table-danger' : '']"
                :rowLink="(item) => route('sales.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-id="{ item }"> #{{ item.id }} </template>

                <template #cell-total_sale_price="{ item }">
                    {{ convertToCurrencyFormat(item.total_sale_price) }}
                </template>

                <template #cell-user_avatar="{ item }">
                    <div class="d-flex justify-content-center">
                        <LazyImage :src="item.user_avatar" :alt="`Venda ${item.id}`" width="30px" />
                    </div>
                </template>

                <template #cell-sale_payment_methods="{ item }">
                    <div class="d-flex justify-content-center">
                        <button
                            class="d-flex align-items-center btn btn-outline-success ms-3"
                            :key="item.sale_payment_methods[0].payment_method.name"
                            disabled
                        >
                            <img
                                :src="`${item.sale_payment_methods[0].payment_method.photo_identification}`"
                                alt=""
                                width="20"
                            />
                            <span class="ms-2 fs-12">{{
                                item.sale_payment_methods[0].payment_method.name
                            }}</span>
                        </button>
                    </div>
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
