<script setup>
import { onMounted, computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const emit = defineEmits(['changeSidebarState', 'changeStateResponsiveSidebar']);
const props = defineProps({
    open: Boolean,
});

const changeSidebarState = () => emit('changeSidebarState');
const changeStateResponsiveSidebar = () => emit('changeStateResponsiveSidebar');
const checkActiveLinks = () => {
    const links = document.querySelectorAll('.nav-main-link');
    const currentUrl = window.location.pathname;

    links.forEach((link) => {
        link.classList.remove('active');
        const hrefs = link.dataset?.destinationUrls?.split(',') || [];

        if (hrefs.some((url) => currentUrl.startsWith(url))) {
            link.classList.add('active');
        }
    });
};

const closeAllCollapse = () => {
    const links = document.querySelectorAll('.sidebar .nav-link');
    links.forEach((link) => {
        link.setAttribute('aria-expanded', 'false');
    });
};

onMounted(() => {
    checkActiveLinks();
    closeAllCollapse();
});
</script>

<template>
    <div class="sidebar">
        <div class="sidebar-header">
            <Link href="/dashboard" class="sidebar-brand"> VERBOSHOP <span>ADMIN</span> </Link>
            <div
                class="sidebar-toggler d-none d-lg-block"
                :class="open ? 'not-active' : 'active'"
                @click="changeSidebarState"
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
            <span class="d-lg-none" @click="changeStateResponsiveSidebar"
                ><font-awesome-icon class="text-white" :icon="['fas', 'bars']"
            /></span>
        </div>
        <div class="sidebar-body">
            <ul class="nav">
                <li class="nav-item nav-category">Principal</li>

                <li
                    class="nav-item"
                    :class="{ active: $page.component === 'Dashboard/Pages/Home' }"
                >
                    <Link href="/dashboard" class="nav-link">
                        <font-awesome-icon class="link-icon" :icon="['fas', 'layer-group']" />
                        <span class="link-title">Visão geral</span>
                    </Link>
                </li>

                <li class="nav-item nav-category">Livros</li>

                <li
                    class="nav-item nav-main-link active"
                    data-destination-urls="/dashboard/books,/dashboard/books/create"
                >
                    <a
                        class="nav-link active"
                        data-bs-toggle="collapse"
                        href="#books"
                        role="button"
                        aria-controls="books"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'book']" />
                        <span class="link-title">Livros</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="books">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/books" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/books/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                            <!-- <li class="nav-item">
                                <Link href="/dashboard/books/quick-consultation" class="nav-link"
                                    >Preço Livro</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/books/turnover" class="nav-link"
                                    >Rotatividade</Link
                                >
                            </li> -->
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Componentes</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/categories">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#category"
                        role="button"
                        aria-controls="category"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'tags']" />
                        <span class="link-title">Categorias</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="category">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/categories" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/categories/create" class="nav-link"
                                    >Criar nova</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/orders">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#pedidos"
                        role="button"
                        aria-controls="pedidos"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'box-archive']" />
                        <span class="link-title">Pedidos</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="pedidos">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/orders" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/orders/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/authors">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#author"
                        role="button"
                        aria-controls="author"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'address-book']" />
                        <span class="link-title">Autores</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="author">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/authors" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/authors/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Vendas</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/sales">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#sale"
                        role="button"
                        aria-controls="sale"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'basket-shopping']" />
                        <span class="link-title">Vendas</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="sale">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/sales" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/sales/create" class="nav-link"
                                    >Nova venda</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/promotions">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#promotion"
                        role="button"
                        aria-controls="promotion"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'percent']" />
                        <span class="link-title">Promoções</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="promotion">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/promotions" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/promotions/create" class="nav-link"
                                    >Criar promoção</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/payment-methods"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#payment-method"
                        role="button"
                        aria-controls="payment-method"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'credit-card']" />
                        <span class="link-title">Gateways</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="payment-method">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/payment-methods" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/payment-methods/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Clientes</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/clients">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#clients"
                        role="button"
                        aria-controls="clients"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'users']" />
                        <span class="link-title">Clientes</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="clients">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/clients" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/clients/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/client-types">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#client-types"
                        role="button"
                        aria-controls="client-types"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'user-tag']" />
                        <span class="link-title">Tipos</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="client-types">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/client-types" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/client-types/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <!-- <li class="nav-item nav-main-link" data-destination-urls="/dashboard/client-orders">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#client-orders"
                        role="button"
                        aria-controls="client-orders"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'box-archive']" />
                        <span class="link-title">Pedidos</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="client-orders">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/client-orders" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item" v-if="permissions.create_order">
                                <Link href="/dashboard/client-orders/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li> -->

                <li class="nav-item nav-category">Loja</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/store-session">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#loja-verboshop"
                        role="button"
                        aria-controls="loja-verboshop"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'shop']" />
                        <span class="link-title">Seções</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="loja-verboshop">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/store-session" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/store-session/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/store-session/ordination" class="nav-link"
                                    >Ordernar</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/advertising-banners"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#advertising-banners"
                        role="button"
                        aria-controls="advertising-banners"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'panorama']" />
                        <span class="link-title">Banners</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="advertising-banners">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/advertising-banners" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/advertising-banners/create" class="nav-link"
                                    >Criar novo</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link
                                    href="/dashboard/advertising-banners/ordination"
                                    class="nav-link"
                                    >Ordernar</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item"
                    :class="{ active: $page.component === 'Dashboard/Pages/Store/Index' }"
                >
                    <Link href="/dashboard/edit/store" class="nav-link">
                        <font-awesome-icon
                            class="link-icon"
                            :icon="['fas', 'house-circle-check']"
                        />
                        <span class="link-title">Dados</span>
                    </Link>
                </li>

                <li class="nav-item">
                    <a href="/" class="nav-link" target="_blank">
                        <font-awesome-icon
                            class="link-icon"
                            :icon="['fas', 'arrow-up-right-from-square']"
                        />
                        <span class="link-title">Ir para loja</span>
                    </a>
                </li>

                <li class="nav-item nav-category">Equipe</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/users">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#users"
                        role="button"
                        aria-controls="users"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'user-group']" />
                        <span class="link-title">Membros</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="users">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/users" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/users/create" class="nav-link"
                                    >Adicionar membro</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/users/organization-chart" class="nav-link"
                                    >Organograma</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/roles">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#role"
                        role="button"
                        aria-controls="role"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'user-tag']" />
                        <span class="link-title">Funções</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="role">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/roles" class="nav-link"
                                    >Listagem completa</Link
                                >
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/roles/create" class="nav-link"
                                    >Criar nova</Link
                                >
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
