<script setup>
import { useForm } from '@inertiajs/vue3';
import { onMounted } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import usePreviewImage from '@/Composables/usePreviewImage.js';

const props = defineProps({
    banner: Object,
});

const form = useForm({
    name: props.banner.name,
    banner: null,
    order: props.banner.order,
    redirect_link: props.banner.redirect_link,
    visible: props.banner.visible ? true : false,
    changed_banner: false,
});

const { previewImage, imageUrl } = usePreviewImage();

onMounted(() => {
    imageUrl.value = `/storage/${props.banner.banner}`;
});

const update = () => form.put(route('advertising-banners.update', props.banner.id));
</script>

<template>
    <Dashboard page_title="Editar Banner">
        <Breadcrumb
            current-page-name="Editar Banner"
            :link-list="[
                {
                    previous_page_url: `advertising-banners/${banner.id}`,
                    previous_page_name: 'Banner',
                },
            ]"
        />

        <div id="edit-banner">
            <h4 class="my-3">Editar Banner</h4>

            <Form
                button-name="Atualizar"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.changed_banner">
                    {{ form.errors.changed_banner }}
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label">Nome do Banner</label>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="name"
                        placeholder="Nome do Banner"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-4">
                    <label for="visible" class="form-label">Visível</label>
                    <select class="form-select" id="visible" v-model="form.visible">
                        <option :value="true">Sim</option>
                        <option :value="false">Não</option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="redirect_link" class="form-label">Link de Redirecionamento</label>
                    <input
                        type="url"
                        class="form-control"
                        id="redirect_link"
                        v-model="form.redirect_link"
                        placeholder="https://exemplo.com"
                    />
                    <small class="text-danger mt-3" v-if="form.errors.redirect_link">{{
                        form.errors.redirect_link
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="formFile" class="form-label"
                        >Banner da turma - Dimensões recomendadas: 1920 x 600</label
                    >
                    <input
                        id="formFile"
                        class="form-control"
                        type="file"
                        @input="form.banner = $event.target.files[0]"
                        @change="previewImage"
                    />
                    <small class="mt-3 informative-text"
                        >O tamanho máximo que a imagem deve ter é de 2mb</small
                    >
                </div>

                <div class="col-md-11 mx-auto">
                    <img
                        v-if="imageUrl"
                        width="576"
                        height="180"
                        class="d-block mx-auto border border-5 border-light shadow"
                        :src="imageUrl"
                    />
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
