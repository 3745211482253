import { ref, watch, onMounted, computed } from 'vue';
import { router } from '@inertiajs/vue3';

export function useSearch(initialData = {}, searchRoute, dataRef = null) {
    const searchParams = ref({ ...initialData });
    const isLoading = ref(false);
    let timeoutId = null;

    const compositionSeacher = (field, event, callback) => {
        searchParams.value[field] = event.target.value;
        clearTimeout(timeoutId);

        isLoading.value = true; // Ativa o loader assim que começa a buscar

        timeoutId = setTimeout(() => {
            if (callback) callback();
        }, 1000);
    };

    const toggleSelection = (field, id, form) => {
        form[field] = form[field] === id ? null : id;
    };

    const executeSearch = () => {
        isLoading.value = true;
        router.get(route(searchRoute), searchParams.value, { preserveState: true });
    };

    // 🔍 Verifica a URL na montagem e executa a busca inicial
    onMounted(() => {
        const url = new URL(window.location.href);
        if (url.search) {
            isLoading.value = true;
            router.get(route(searchRoute), {}, { replace: true, preserveState: true });
        }
    });

    const updateLoadingState = (data) => {
        if (data && data.data?.length >= 0) {
            isLoading.value = false; // Desativa o loader quando os dados chegam ou se estiver vazio
        }
    };

    return {
        searchParams,
        isLoading,
        compositionSeacher,
        toggleSelection,
        executeSearch,
        updateLoadingState,
    };
}
