<template>
    <div class="card border-0">
        <div class="card-body">
            <ul class="list-group list-group">
                <template v-for="(activity, index) in activitys" :key="index">
                    <li
                        class="list-group-item p-3 font-semibold fs-14"
                        v-if="activity.event == 'finished-order'"
                    >
                        <div class="row d-flex justify-content-between align-items-center">
                            <div class="col-lg-3">
                                <span class="badge bg-primary p-2 mb-3 mb-sm-0 fw-bold text-white"
                                    >Pedido nº {{ activity.details.order_id }}
                                </span>
                            </div>
                            <div class="col-lg-7 mt-md-2 mt-lg-0">
                                <span>
                                    {{ activity.details.moved_quantity }} livros chegaram - Agora há
                                    {{ activity.details.new_quantity }} no estoque</span
                                >
                            </div>
                            <div class="col-lg-2 text-end">
                                <span>{{ simplifyDate(activity.created_at) }}</span>
                            </div>
                        </div>
                    </li>

                    <li
                        class="list-group-item p-3 font-semibold fs-14"
                        v-if="activity.event == 'completed-sale'"
                    >
                        <div class="row d-flex justify-content-between align-items-center">
                            <div class="col-lg-3">
                                <span class="badge bg-success p-2 mb-3 mb-sm-0 fw-bold text-white"
                                    >Compra nº {{ activity.details.sale_id }}</span
                                >
                            </div>
                            <div class="col-lg-7 mt-md-2 mt-lg-0">
                                <span
                                    >{{ activity.details.moved_quantity }} livros foram vendidos -
                                    {{
                                        activity.details.new_quantity > 0
                                            ? `Agora há ${activity.details.new_quantity} livros em estoque`
                                            : 'O estoque agora está vazio'
                                    }}</span
                                >
                            </div>
                            <div class="col-lg-2 text-end">
                                <span>{{ simplifyDate(activity.created_at) }}</span>
                            </div>
                        </div>
                    </li>
                    <li
                        class="list-group-item p-3 font-semibold fs-14"
                        v-if="activity.event == 'canceled-sale'"
                    >
                        <div class="row d-flex justify-content-between align-items-center">
                            <div class="col-lg-3">
                                <span class="badge bg-danger p-2 mb-3 mb-sm-0 fw-bold text-white"
                                    >Compra nº {{ activity.details.sale_id }}</span
                                >
                            </div>
                            <div class="col-lg-7 mt-md-2 mt-lg-0">
                                <span
                                    >{{ activity.details.moved_quantity }} livros foram devolvidos -
                                    Agora há {{ activity.details.new_quantity }} no estoque</span
                                >
                            </div>
                            <div class="col-lg-2 text-end">
                                <span>{{ simplifyDate(activity.created_at) }}</span>
                            </div>
                        </div>
                    </li>

                    <li
                        class="list-group-item p-3 font-semibold fs-14"
                        v-if="activity.event == 'manual-quantity-change'"
                    >
                        <div class="row d-flex justify-content-between align-items-center">
                            <div class="col-lg-3">
                                <span class="badge bg-warning p-2 mb-3 mb-sm-0 fw-bold text-dark"
                                    >Manual</span
                                >
                            </div>
                            <div class="col-lg-7 mt-md-2 mt-lg-0">
                                <span
                                    >A quantidade de livros em estoque foi atualizada para
                                    {{ activity.details.new_quantity }}</span
                                >
                            </div>
                            <div class="col-lg-2 text-end">
                                <span>{{ simplifyDate(activity.created_at) }}</span>
                            </div>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup>
import useDateFormat from '@/Composables/useDateFormat.js';

const props = defineProps({ activitys: Object });

const { simplifyDate } = useDateFormat();
</script>
