<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-section">
                <h3 class="footer-title">Acesso Rápido</h3>
                <nav class="quick-links">
                    <Link href="/livros" class="footer-link">
                        <font-awesome-icon :icon="['fas', 'book']" class="icon" />
                        <span>Todos os livros</span>
                    </Link>
                    <Link href="/autores" class="footer-link">
                        <font-awesome-icon :icon="['fas', 'users']" class="icon" />
                        <span>Autores</span>
                    </Link>
                    <Link href="/temas" class="footer-link">
                        <font-awesome-icon :icon="['fas', 'bookmark']" class="icon" />
                        <span>Temas</span>
                    </Link>
                    <Link :href="route('login')" class="footer-link">
                        <font-awesome-icon :icon="['fas', 'gear']" class="icon" />
                        <span>Painel</span>
                    </Link>
                </nav>
            </div>

            <div class="footer-section contact-section">
                <h3 class="footer-title">Entre em Contato</h3>
                <ul class="contact-list">
                    <li class="contact-item">
                        <font-awesome-icon :icon="['fab', 'whatsapp']" class="icon" />
                        <a :href="`https://wa.me/${storeData.mobile_number}`" class="contact-link">
                            {{ storeData.mobile_number }}
                        </a>
                    </li>
                    <li class="contact-item">
                        <font-awesome-icon :icon="['fas', 'phone']" class="icon" />
                        <a :href="`tel:${storeData.phone_number}`" class="contact-link">
                            {{ storeData.phone_number }}
                        </a>
                    </li>
                    <li class="contact-item">
                        <font-awesome-icon :icon="['fas', 'envelope']" class="icon" />
                        <a :href="`mailto:${storeData.email}`" class="contact-link">
                            {{ storeData.email }}
                        </a>
                    </li>
                    <li class="contact-item">
                        <font-awesome-icon :icon="['fas', 'map-location-dot']" class="icon" />
                        <address class="address">{{ storeData.address }}</address>
                    </li>
                </ul>
            </div>

            <div class="footer-section social-section">
                <h3 class="footer-title">Redes Sociais</h3>
                <div class="social-links">
                    <a
                        href="https://www.facebook.com/VerboPauloAfonso"
                        class="social-link facebook"
                        target="_blank"
                        aria-label="Facebook"
                    >
                        <font-awesome-icon :icon="['fab', 'facebook']" />
                    </a>
                    <a
                        href="https://www.youtube.com/@verbodavidapauloafonso9814"
                        class="social-link youtube"
                        target="_blank"
                        aria-label="Youtube"
                    >
                        <font-awesome-icon :icon="['fab', 'youtube']" />
                    </a>
                    <a
                        href="https://www.instagram.com/verbodavidapauloafonso/"
                        class="social-link instagram"
                        target="_blank"
                        aria-label="Instagram"
                    >
                        <font-awesome-icon :icon="['fab', 'instagram']" />
                    </a>
                </div>
            </div>
        </div>

        <div class="copyright">
            <div class="copyright-content">
                VerboShop Paulo Afonso - {{ new Date().getFullYear() }}
            </div>
        </div>
    </footer>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';

const page = usePage();
const storeData = page.props.store.data;
</script>
