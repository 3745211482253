<template>
    <div class="container-fluid p-0 m-0" id="book-store" :class="{ 'rhema-day': rhemaDay }">
        <div id="loading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <Head :title="page_title" />
        <Navbar />
        <div class="col-lg-12 mx-auto col-xxl-9 mx-auto" id="main-container">
            <div class="dividing-line">&nbsp;</div>
            <slot></slot>
        </div>
        <div
            class="modal fade"
            id="bookSearchModalStore"
            tabindex="-1"
            aria-labelledby="bookSearchModalStoreLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-0 shadow">
                    <div class="modal-header border-0 pb-0">
                        <h5 class="modal-title fw-bold" id="bookSearchModalStoreLabel">
                            <i class="fas fa-search me-2 text-primary"></i>
                            Buscar livros
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            ref="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div class="modal-body px-4">
                        <!-- Search Input -->
                        <div class="search-wrapper position-relative mb-4">
                            <input
                                type="text"
                                ref="modalName"
                                v-model="name"
                                @input="compositionUpdate"
                                class="form-control form-control-lg border-0 bg-light shadow-sm ps-4"
                                placeholder="Digite o nome do livro..."
                                autocomplete="off"
                            />
                            <div
                                class="spinner-wrapper position-absolute top-50 end-0 translate-middle-y me-3"
                                v-if="processing"
                            >
                                <div
                                    class="spinner-border spinner-border-sm text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden">Buscando...</span>
                                </div>
                            </div>
                        </div>

                        <!-- Results List -->
                        <div class="search-results">
                            <div v-if="books.length > 0" class="results-wrapper">
                                <div v-for="book in books" :key="book.id" class="result-item">
                                    <a
                                        @click="viewBook(`/livros/${book.clean_name}`)"
                                        class="d-flex align-items-center p-2 rounded-3 text-decoration-none text-dark hover-bg"
                                        style="cursor: pointer"
                                    >
                                        <div class="book-cover me-3">
                                            <img
                                                :src="`/storage/${book.cover_image_url}`"
                                                :alt="book.name"
                                                class="rounded-2 shadow-sm"
                                                width="60"
                                            />
                                        </div>

                                        <div class="flex-grow-1">
                                            <h6 class="mb-1 text-truncate fs-10">
                                                {{ book.name }}
                                            </h6>
                                            <span class="price-tag">
                                                {{ convertToCurrencyFormat(book.original_price) }}
                                            </span>
                                        </div>

                                        <i class="fas fa-chevron-right text-muted ms-2"></i>
                                    </a>
                                </div>

                                <!-- Ver todos os resultados -->
                                <div class="text-center mt-4">
                                    <Link
                                        :href="`/livros/?name=${name}`"
                                        class="btn btn-warning px-4"
                                        @click="modal.click()"
                                    >
                                        Ver todos os resultados
                                    </Link>
                                </div>
                            </div>

                            <!-- Empty State -->
                            <div
                                v-else-if="searchStarted && !processing"
                                class="text-center py-4 text-muted"
                            >
                                <i class="fas fa-book-open fa-2x mb-3"></i>
                                <p class="mb-0">Nenhum livro encontrado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Favorites />
        <Footer />
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onBeforeUnmount } from 'vue';
import { Head, Link, router, useForm } from '@inertiajs/vue3';
import { Tooltip } from 'bootstrap';

import Navbar from './Navbar.vue';
import Footer from './Footer.vue';
import Favorites from './Favorites.vue';

import useAxios from '@/Services/Axios.vue';
import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({ page_title: String, rhemaDay: String });

const { instance: axios } = useAxios();
const { convertToCurrencyFormat } = usePriceFormatter();

const modal = ref(null);
const modalElement = ref(null);

const name = ref(null);
const modalName = ref(null);
const books = ref([]);
const searchStarted = ref(false);
const processing = ref(false);
const tooltips = [];

const compositionUpdate = (event) => {
    name.value = event.target.value;
};

const seacher = () => {
    searchStarted.value = true;
    processing.value = true;
    axios
        .get(`/seache?name=${name.value}`)
        .then((response) => {
            books.value = response.data.books;
        })
        .finally(() => {
            processing.value = false;
        });
};

watch(name, (value) => {
    seacher();
});

const form = useForm({});

const viewBook = (url) => {
    form.get(url, {
        onBefore: (page) => modal.value.click(),
    });
};

const rhemaDay = computed(() => {
    const currentDayOfWeek = new Date().getDay();

    if ([1, 3, 5].includes(currentDayOfWeek)) {
        return true;
    }

    return false;
});

onMounted(() => {
    modalElement.value = document.getElementById('bookSearchModalStore');

    modalElement.value.addEventListener('shown.bs.modal', () => {
        modalName.value.focus();
        modalName.value.click();
    });

    const tooltipElements = document.querySelectorAll("[data-bs-toggle='tooltip']");
    tooltipElements.forEach((element) => {
        tooltips.push(
            new Tooltip(element, {
                trigger: 'hover',
            })
        );
    });
});

onBeforeUnmount(() => {
    tooltips.forEach((tooltip) => {
        tooltip.dispose();
    });
});
</script>
