<template>
    <Layout page_title="VerboShop Paulo Afonso">
        <div class="container-fluid py-4 mt-4 px-4">
            <!-- Header Section -->
            <div class="d-flex flex-wrap justify-content-between align-items-center mb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                            <Link href="/" class="text-decoration-none">Início</Link>
                        </li>
                        <li class="breadcrumb-item active fw-semibold">Livros</li>
                    </ol>
                </nav>

                <div class="d-flex gap-3 align-items-center">
                    <select
                        class="form-select form-select-sm border-0 bg-light shadow-sm d-none d-sm-block"
                        v-model="searchForm.orderBy"
                        @change="seacher"
                    >
                        <option value="asc_name">Ordem alfabética - A/Z</option>
                        <option value="desc_name">Ordem alfabética - Z/A</option>
                        <option value="asc_value">Preço: menor ao maior</option>
                        <option value="desc_value">Preço: maior ao menor</option>
                    </select>

                    <button
                        class="btn btn-warning btn-sm d-sm-none shadow-sm"
                        data-bs-toggle="offcanvas"
                        href="#seacherModal"
                    >
                    <font-awesome-icon class="me-2" :icon="['fas', 'filter']" />
                        Filtros
                    </button>
                </div>
            </div>

            <div class="row g-4">
                <!-- Sidebar Filters -->
                <div class="col-lg-4 d-none d-sm-block">
                    <div class="card border-0 shadow-sm">
                        <div class="card-body">
                            <div class="mb-4">
                                <h5 class="card-title mb-3 fw-bold px-4 mt-3">Autores</h5>
                                <div class="filter-list px-4">
                                    <div
                                        v-for="author in authors"
                                        :key="author.id"
                                        class="form-check mb-2"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            :id="`author-${author.id}`"
                                            :value="author.id"
                                            @click="selectAuthor(author.clean_name)"
                                        />
                                        <label
                                            class="form-check-label d-flex justify-content-between align-items-center"
                                            :for="`author-${author.id}`"
                                        >
                                            <span class="text-truncate me-2 fs-12">{{
                                                author.name
                                            }}</span>
                                            <span class="badge bg-light text-dark">{{
                                                author.book_total
                                            }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div>
                                <h5 class="card-title mb-3 px-4">Temas</h5>
                                <div class="filter-list px-4">
                                    <div
                                        v-for="category in categories"
                                        :key="category.id"
                                        class="form-check mb-2"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            :id="`category-${category.id}`"
                                            :value="category.id"
                                            @click="selectCategory(category.clean_name)"
                                        />
                                        <label
                                            class="form-check-label d-flex justify-content-between align-items-center"
                                            :for="`category-${category.id}`"
                                        >
                                            <span class="text-truncate me-2 fs-12">{{
                                                category.name
                                            }}</span>
                                            <span class="badge bg-light text-dark">{{
                                                category.book_total
                                            }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Books Grid -->
                <div class="col-lg-8">
                    <div class="row row-cols-2 row-cols-md-3 g-4">
                        <div v-for="book in books.data" :key="book.id" class="col">
                            <Card :book="book" :displayFavorite="true" />
                        </div>
                    </div>

                    <div class="mt-4">
                        <Pagination :data="books.meta" reference_date="books" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile Filters Modal -->
        <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="seacherModal"
            aria-labelledby="seacherLabel"
        >
            <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title fw-bold">Filtros</h5>
                <button
                    type="button"
                    class="btn-close"
                    ref="closeSeacherModal"
                    data-bs-dismiss="offcanvas"
                ></button>
            </div>
            <div class="offcanvas-body">
                <div class="mb-4">
                    <h5 class="mb-3">Ordenar por</h5>
                    <select class="form-select mb-4" v-model="searchForm.orderBy" @change="seacher">
                        <option value="asc_name">Ordem alfabética - A/Z</option>
                        <option value="desc_name">Ordem alfabética - Z/A</option>
                        <option value="asc_value">Preço: menor ao maior</option>
                        <option value="desc_value">Preço: maior ao menor</option>
                    </select>

                    <h5 class="mb-3">Autores</h5>
                    <div class="filter-list">
                        <div v-for="author in authors" :key="author.id" class="form-check mb-2">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                :id="`author-mobile-${author.id}`"
                                :value="author.id"
                                @click="selectAuthor(author.clean_name)"
                            />
                            <label
                                class="form-check-label d-flex justify-content-between align-items-center"
                                :for="`author-mobile-${author.id}`"
                            >
                                {{ author.name }}
                                <span class="badge bg-light text-dark">{{
                                    author.book_total
                                }}</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <h5 class="mb-3">Temas</h5>
                    <div class="filter-list">
                        <div
                            v-for="category in categories"
                            :key="category.id"
                            class="form-check mb-2"
                        >
                            <input
                                type="checkbox"
                                class="form-check-input"
                                :id="`category-mobile-${category.id}`"
                                :value="category.id"
                                @click="selectCategory(category.clean_name)"
                            />
                            <label
                                class="form-check-label d-flex justify-content-between align-items-center"
                                :for="`category-mobile-${category.id}`"
                            >
                                {{ category.name }}
                                <span class="badge bg-light text-dark">{{
                                    category.book_total
                                }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { Link, router } from '@inertiajs/vue3';
import Card from '@/Pages/Store/Shared/Card.vue';
import Layout from '@/Pages/Store/Layouts/Layout.vue';
import Pagination from '@/Pages/Dashboard/Components/Shared/Pagination.vue';

const searchForm = reactive({
    categories: [],
    authors: [],
    orderBy: 'asc_name',
});

const props = defineProps({
    categories: Object,
    authors: Object,
    books: Object,
});

const closeSeacherModal = ref(null);

const selectAuthor = (name) => {
    if (searchForm.authors.includes(name)) {
        searchForm.authors = searchForm.authors.filter((a) => a !== name);
    } else {
        searchForm.authors.push(name);
    }
    seacher();
};

const selectCategory = (name) => {
    if (searchForm.categories.includes(name)) {
        searchForm.categories = searchForm.categories.filter((c) => c !== name);
    } else {
        searchForm.categories.push(name);
    }
    seacher();
};

const seacher = () => {
    const authors = searchForm.authors.join(',');
    const categories = searchForm.categories.join(',');

    closeSeacherModal.value?.click();

    router.get(
        '/livros',
        { authors, categories, orderBy: searchForm.orderBy },
        { preserveState: true }
    );
};
</script>

<style scoped>
.filter-list {
    max-height: 400px;
    overflow-y: auto;
}

.filter-list::-webkit-scrollbar {
    width: 4px;
}

.filter-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.filter-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.form-check-label {
    cursor: pointer;
    font-size: 0.9rem;
}

.form-check-input:checked {
    background-color: var(--bs-warning);
    border-color: var(--bs-warning);
}

.badge {
    font-weight: 500;
    font-size: 0.75rem;
}
</style>
