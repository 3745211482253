<script setup>
import { onMounted, ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

import { vMaska } from 'maska';
import { VMoney } from 'v-money';

import usePreviewImage from '@/Composables/usePreviewImage.js';
import useMoney from '@/Composables/useMoney.js';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    allCategories: Object,
    allAuthors: Object,
    book: Object,
    bookCategories: Object,
    bookAuthors: Object,
});

const { previewImage, imageUrl, imageSizeInMB } = usePreviewImage();

const { money: supplierMoney } = useMoney(3);
const { money: originalMoney } = useMoney();

const form = useForm({
    _method: 'put',
    name: props.book.name,
    isbn: props.book.isbn,
    quantity: props.book.quantity,
    total_pages: props.book.total_pages,
    original_price: props.book.original_price,
    description: props.book.description,
    presentation_youtube_url: props.book.presentation_youtube_url,
    spotify_link_url: props.book.spotify_link_url,
    visible: props.book.visible ? 1 : 0,
    release_date: props.book.release_date,
    supplier_price: props.book.supplier_price,
    cover_image_url: '',
    categories: '',
    authors: '',
});

const { convertDecimalFormat } = usePriceFormatter();

const update = () => {
    form.transform((data) => ({
        ...data,
        original_price: convertDecimalFormat(data.original_price),
        supplier_price: convertDecimalFormat(data.supplier_price),
    }));

    form.post(route('books.update', props.book.id));
};

onMounted(() => {
    imageUrl.value = `/storage/${props.book.cover_image_url}`;
    form.categories = props.bookCategories.map((category) => category.id);
    form.authors = props.bookAuthors.map((author) => author.id);
});

const bookEditModalCloseButton = ref(null);

const destroy = () => {
    bookEditModalCloseButton.value.click();
    router.delete(route('books.destroy', props.book.id));
};
</script>

<template>
    <Dashboard :page_title="book.name">
        <Breadcrumb
            :currentPageName="book.name"
            :linkList="[{ previous_page_url: 'books', previous_page_name: 'Livros' }]"
        />

        <div id="edit-book">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link :href="route('books.show', book.id)" class="btn btn-success btn-add me-2">
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#bookEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <hr />

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-8">
                    <label for="name" class="form-label">Nome do livro</label>
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="name"
                        name="name"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-4">
                    <label for="isbn" class="form-label">ISBN</label>
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="isbn"
                        name="isbn"
                        v-model="form.isbn"
                        v-maska
                        data-maska="###-##-####-###-#"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.isbn">{{
                        form.errors.isbn
                    }}</small>
                </div>

                <div class="col-md-4 col-6">
                    <label for="total_pages" class="form-label">Páginas</label>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="total_pages"
                        name="total_pages"
                        v-model="form.total_pages"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.total_pages">{{
                        form.errors.total_pages
                    }}</small>
                </div>

                <div class="col-md-4 col-6">
                    <label for="quantity" class="form-label">Quantidade</label>
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="quantity"
                        name="quantity"
                        v-model="form.quantity"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.quantity">{{
                        form.errors.quantity
                    }}</small>
                </div>

                <div class="col-md-4 col-6">
                    <label for="original_price" class="form-label">Preço do livro</label>
                    <input
                        type="text"
                        class="form-control"
                        id="original_price"
                        name="original_price"
                        v-model.lazy="form.original_price"
                        autocomplete="off"
                        v-money="originalMoney"
                        maxlength="7"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.original_price">{{
                        form.errors.original_price
                    }}</small>
                </div>

                <div class="col-md-3 col-6">
                    <label for="visible" class="form-label">Vísivel</label>
                    <select class="form-select" id="visible" name="visible" v-model="form.visible">
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </select>
                </div>

                <div class="col-md-5 col-6">
                    <label for="release_date" class="form-label">Data de lançamento</label>
                    <input
                        type="date"
                        class="form-control"
                        autocomplete="off"
                        id="release_date"
                        name="release_date"
                        v-model="form.release_date"
                    />
                    <small class="text-danger mt-3" v-if="form.errors.release_date">{{
                        form.errors.release_date
                    }}</small>
                </div>

                <div class="col-md-4 col-6">
                    <label for="supplier_price" class="form-label">Preço de compra</label>
                    <input
                        type="text"
                        class="form-control"
                        id="supplier_price"
                        name="supplier_price"
                        v-model.lazy="form.supplier_price"
                        autocomplete="off"
                        v-money="supplierMoney"
                        maxlength="8"
                    />
                    <small class="text-danger mt-3" v-if="form.errors.supplier_price">{{
                        form.errors.supplier_price
                    }}</small>
                </div>

                <div class="col-md-6">
                    <label for="presentation_youtube_url" class="form-label"
                        >Link apresentação Youtube</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="presentation_youtube_url"
                        name="presentation_youtube_url"
                        v-model="form.presentation_youtube_url"
                        placeholder="https://www.youtube.com/watch?verbodavida"
                    />
                    <small class="text-danger mt-3" v-if="form.errors.presentation_youtube_url">{{
                        form.errors.presentation_youtube_url
                    }}</small>
                </div>

                <div class="col-md-6">
                    <label for="spotify_link_url" class="form-label">Link Sportify</label>
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="spotify_link_url"
                        name="spotify_link_url"
                        v-model="form.spotify_link_url"
                        placeholder="https://open.spotify.com/intl-pt/album"
                    />
                    <small class="text-danger mt-3" v-if="form.errors.spotify_link_url">{{
                        form.errors.spotify_link_url
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label">Descrição</label>
                    <textarea
                        type="text"
                        class="form-control"
                        id="description"
                        name="description"
                        v-model="form.description"
                        rows="10"
                    ></textarea>
                </div>

                <div class="col-lg-12 mt-3">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Categorias vinculadas</h5>
                            <ul class="list-group overflow-auto category-listing mt-3">
                                <li
                                    class="list-group-item p-3"
                                    v-for="(category, index) in allCategories"
                                    :key="index"
                                >
                                    <input
                                        class="form-check-input me-1"
                                        type="checkbox"
                                        :value="category.id"
                                        :id="'checkBoxCategory' + category.id"
                                        :checked="
                                            form.categories.includes(category.id) ? true : false
                                        "
                                        v-model="form.categories"
                                    />
                                    <label
                                        class="form-check-label stretched-link ms-3"
                                        :for="'checkBoxCategory' + category.id"
                                        style="cursor: pointer"
                                        >{{ category.name }}</label
                                    >
                                </li>
                            </ul>
                        </div>

                        <div class="col-md-6 mt-3 mt-sm-0">
                            <h5>Autores</h5>
                            <ul class="list-group overflow-auto mt-3 author-listing">
                                <li
                                    class="list-group-item p-3"
                                    v-for="(author, index) in allAuthors"
                                    :key="index"
                                >
                                    <input
                                        class="form-check-input me-1"
                                        type="checkbox"
                                        :value="author.id"
                                        :id="'checkBoxAuthor' + author.id"
                                        :checked="form.authors.includes(author.id) ? true : false"
                                        v-model="form.authors"
                                    />
                                    <label
                                        class="form-check-label stretched-link ms-3"
                                        :for="'checkBoxAuthor' + author.id"
                                        style="cursor: pointer"
                                        >{{ author.name }}</label
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="col-md-12">
                        <label for="formFile" class="form-label"
                            >Selecione uma nova foto para a capa do livro - Tamanho máximo
                            permitido: 2mb</label
                        >
                        <input
                            class="form-control"
                            @input="form.cover_image_url = $event.target.files[0]"
                            type="file"
                            id="formFile"
                            accept="image/png,image/jpeg"
                            @change="previewImage"
                        />
                    </div>
                    <div class="col-md-11 mx-auto mt-3">
                        <img class="d-block mx-auto book-cover" v-if="imageUrl" :src="imageUrl" />
                    </div>
                </div>

                <ConfirmationModal
                    modal_id="bookEditModal"
                    modal_title="Confirmação de remoção do registro"
                >
                    <template v-slot:modal-text>
                        Você tem certeza que deseja excluir o registro?
                    </template>
                    <template v-slot:modal-footer>
                        <button
                            type="button"
                            ref="bookEditModalCloseButton"
                            class="btn btn-secondary text-white"
                            data-bs-dismiss="modal"
                        >
                            Voltar
                        </button>
                        <button @click="destroy" type="button" class="btn btn-danger text-white">
                            Deletar registro
                        </button>
                    </template>
                </ConfirmationModal>
            </Form>
        </div>
    </Dashboard>
</template>
