<template>
    <header class="navbar-wrapper">
        <nav class="main-nav">
            <div class="nav-container d-flex justify-content-between">
                <button
                    class="mobile-menu-button"
                     data-bs-toggle="modal"
                    data-bs-target="#bookSearchModalStore"
                >
                    <span class="sr-only">Menu</span>
                    <font-awesome-icon :icon="['fas', 'search']" />
                </button>

                <!-- Logo -->
                <Link href="/" class="logo-container" aria-label="Logo Verboshop">
                    <img src="/img/verboshop-logo.png" alt="VerboShop" class="logo" />
                </Link>

                <!-- Search Bar (Desktop) -->
                <div class="search-container desktop-search">
                    <div class="search-wrapper">
                        <input
                            type="search"
                            name="search"
                            placeholder="O que você está buscando hoje?"
                            class="search-input"
                            data-bs-toggle="modal"
                            data-bs-target="#bookSearchModalStore"
                        />
                        <font-awesome-icon :icon="['fas', 'search']" class="search-icon" />
                    </div>
                </div>

                <!-- Actions -->
                <div class="actions-container">
                    <button
                        class="action-button"
                        data-bs-toggle="offcanvas"
                        href="#favorites"
                        role="button"
                    >
                        <font-awesome-icon :icon="['fas', 'cart-shopping']" />
                        <span>Meu carrinho</span>
                    </button>
                </div>

                <!-- Mobile Menu Button -->
                <button
                    class="mobile-menu-button"
                    @click="isMenuOpen = !isMenuOpen"
                    :aria-expanded="isMenuOpen"
                >
                    <span class="sr-only">Menu</span>
                    <font-awesome-icon :icon="['fas', isMenuOpen ? 'times' : 'bars']" />
                </button>
            </div>
        </nav>

        <!-- Secondary Navigation -->
        <nav class="secondary-nav" :class="{ 'mobile-menu-open': isMenuOpen }">
            <div class="nav-container">
                <ul class="nav-links">
                    <li>
                        <Link href="/" class="nav-link" :class="{ active: $page.url === '/' }">
                            Início
                        </Link>
                    </li>
                    <li>
                        <Link
                            href="/autores"
                            class="nav-link"
                            :class="{ active: $page.url === '/autores' }"
                        >
                            Autores
                        </Link>
                    </li>
                    <li>
                        <Link
                            href="/temas"
                            class="nav-link"
                            :class="{ active: $page.url === '/temas' }"
                        >
                            Temas
                        </Link>
                    </li>
                    <li>
                        <Link
                            href="/livros"
                            class="nav-link"
                            :class="{ active: $page.url.startsWith('/livros') }"
                        >
                            Todos os livros
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import { ref } from 'vue';

const isMenuOpen = ref(false);

const openWhatsAppChat = () => {
    const phoneNumber = '557588512376';
    const message = encodeURIComponent(
        'Olá, graça e Paz! Eu gostaria de tirar algumas dúvidas em relação aos livros do Verboshop!'
    );
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
};
</script>
