<script setup>
import { onMounted } from 'vue';
import { router } from '@inertiajs/vue3';
import AOS from 'aos';
import 'aos/dist/aos.css';

const props = defineProps({
    categories: Object,
    full: Boolean,
});

const goToThemePage = (slug) => router.get(`/temas/${slug}`);

onMounted(() => {
    AOS.init({
        once: true,
    });
});
</script>

<template>
    <div class="themes-container mb-5 py-0">
        <h2 v-if="!full" class="section-title font-bold">
            Temas Disponíveis
            <div class="title-underline"></div>
        </h2>

        <div class="themes-grid">
            <div
                v-for="(item, index) in full ? categories : categories.slice(0, 10)"
                :key="index"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
            >
                <div class="theme-card" @click="goToThemePage(item.clean_name)">
                    <div class="image-wrapper">
                        <img :src="item.photo" :alt="item.name" />
                    </div>
                    <span class="theme-name">{{ item.name }}</span>
                </div>
            </div>

            <div
                v-if="!full && categories.length > 10"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
            >
                <div class="theme-card see-all" @click="router.get('/temas')">
                    <div class="image-wrapper">
                        <font-awesome-icon :icon="['fas', 'circle-plus']" class="plus-icon" />
                    </div>
                    <span class="theme-name">Ver Todos</span>
                </div>
            </div>
        </div>
    </div>
</template>
