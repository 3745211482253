<script setup>
import { reactive, computed } from 'vue';
import { router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';

import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    orders: Object,
});

const searchForm = reactive({
    status: '',
    start_date: '',
    end_date: '',
});

const { convertToCurrencyFormat } = usePriceFormatter();
const seacher = () => router.get(route('orders.index'), searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de pedidos" main-data="orders">
        <Breadcrumb currentPageName="Listagem de pedidos" />

        <PageHeader title="Pedidos" :button-link="route('orders.create')" button-text="Novo" />

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-4">
                        <label for="status" class="form-label">Status do pedido</label>
                        <select class="form-select" id="status" v-model="searchForm.status">
                            <option selected value="">Todos os status</option>
                            <option value="PENDENTE">Pendente</option>
                            <option value="APROVADO">Aprovado</option>
                            <option value="FINALIZADO">Finalizado</option>
                            <option value="CANCELADO">Cancelado</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            type="date"
                            id="startDate"
                            class="form-control"
                            v-model="searchForm.start_date"
                        />
                    </div>
                    <div class="col-md-4">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            type="date"
                            id="endDate"
                            class="form-control"
                            v-model="searchForm.end_date"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-orders"
                :headers="[
                    {
                        key: 'id',
                        label: 'ID',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_orders_price',
                        label: 'Valor',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_books',
                        label: 'Livros',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="orders.data"
                :pagination="{
                    data: orders.meta,
                    reference: 'orders',
                }"
                :rowLink="(item) => route('orders.show', item.id)"
            >
                <template #cell-id="{ item }"> #{{ item.id }} </template>

                <template #cell-status="{ item }">
                    <Badge :name="item.status" />
                </template>

                <template #cell-total_orders_price="{ item }">
                    {{ convertToCurrencyFormat(item.total_orders_price) }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
