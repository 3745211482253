<script setup>
import { computed } from 'vue'
import { Link, useForm } from '@inertiajs/vue3'

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue'
import Form from '@/Pages/Dashboard/Components/ui/Form.vue'
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue'

import { vMaska } from 'maska'
import useMoney from '@/Composables/useMoney.js'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'

// Recebendo as props
const props = defineProps({
  books: Array,
  errors: Object,
  order: Object,
  selectedBooks: Array
})

// Apenas se utilizar o composable. Caso não use, pode remover.
const { money } = useMoney(3)
const { convertToCurrencyFormat } = usePriceFormatter()

// Formulário para criar ou atualizar
const form = useForm({
  book_id: '',
  order_id: props.order.id,
  quantity: '',
  unit_price: '',
  total_books_price: ''
})

/**
 * Atualiza os detalhes do livro selecionado no <select>
 */
const updateBookDetails = () => {
  const selectedBook = props.books.find((book) => book.id === form.book_id)
  if (selectedBook) {
    // Converter string com vírgula em ponto (caso venha assim)
    const price = selectedBook.supplier_price
      ?.toString()
      .replace(',', '.')

    const parsedPrice = parseFloat(price) || 0
    form.unit_price = parsedPrice.toFixed(3)

    calculateTotalPrice()
  }
}

/**
 * Calcula o valor total multiplicando unit_price * quantity
 */
const calculateTotalPrice = () => {
  const unitPrice = parseFloat(form.unit_price?.toString().replace(',', '.')) || 0
  const quantity = parseInt(form.quantity) || 0

  // Se ambos forem válidos, faz o cálculo
  if (!isNaN(unitPrice) && !isNaN(quantity)) {
    form.total_books_price = (unitPrice * quantity).toFixed(3)
  } else {
    form.total_books_price = ''
  }
}

// Valor total do pedido (somente para exibir)
const totalOrderAmount = computed(() => 
  convertToCurrencyFormat(props.order.total_orders_price)
)

/**
 * Função chamada pelo formulário para CRIAR um novo item no pedido.
 */
const createBookOrder = () => {
  // Convertemos o unit_price e quantity do form
  const parsedUnitPrice = parseFloat(form.unit_price?.replace(',', '.')) || 0
  const parsedQuantity = parseInt(form.quantity) || 0

  // Atualizamos e calculamos o total
  form.unit_price = parsedUnitPrice.toFixed(3)
  form.total_books_price = (parsedUnitPrice * parsedQuantity).toFixed(3)

  // Envia via POST (supondo que seja criação)
  form.put(`/dashboard/orders/${props.order.id}`, {
    preserveScroll: true,
    onSuccess: () => form.reset()
  })
}

/**
 * Função para ATUALIZAR (usada quando a quantidade muda na tabela).
 * Aqui, temos `book` vindo da linha da tabela.
 */
const updateBookOrder = (book) => {
  // Converter se vier com vírgula
  const parsedUnitPrice = parseFloat(book.unit_price?.toString().replace(',', '.')) || 0
  const parsedQuantity = parseInt(book.quantity) || 0

  // Atribuir ao form para envio
  form.book_id = book.book_id
  form.quantity = parsedQuantity
  form.unit_price = parsedUnitPrice.toFixed(3)
  form.total_books_price = (parsedUnitPrice * parsedQuantity).toFixed(3)

  // Envia via PUT (supondo que seja edição)
  form.put(`/dashboard/orders/${props.order.id}`, {
    preserveScroll: true,
    onSuccess: () => form.reset()
  })
}
</script>

<template>
  <Dashboard page_title="Adicionar pedido">
    <nav class="p-0" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <Link :href="`/dashboard/orders/${order.id}`">
            Pedido nº {{ order.id }}
          </Link>
        </li>
      </ol>
    </nav>

    <h4 class="font-bold font-bold mb-0">Livros</h4>
    <span class="text-secondary font-semibold fs-12 mb-3">
      Confira os livros já adicionados
    </span>

    <div class="alert alert-info mt-4" role="alert">
      <font-awesome-icon class="me-2" :icon="['fas', 'circle-info']" />
      Pedido composto por {{ order.total_books }} livros que custam um total de
      <span ref="total_books">{{ totalOrderAmount }}</span>
    </div>

    <!-- Tabela de livros já selecionados -->
    <div class="p-0">
      <Table
        table-id="table-orders"
        :headers="[
          { key: 'id', label: 'ID', thClass: 'text-center', tdClass: 'text-center fw-bold' },
          { key: 'book_name', label: 'Livro', thClass: 'text-center', tdClass: 'text-center' },
          { key: 'unit_price', label: 'Preço unidade', thClass: 'text-center', tdClass: 'text-center' },
          { key: 'total_books_price', label: 'Total', thClass: 'text-center', tdClass: 'text-center' },
          { key: 'quantity', label: 'Quantidade', thClass: 'text-center', tdClass: 'text-center' }
        ]"
        :data="selectedBooks"
        :pagination="{ reference: 'selectedBooks' }"
      >
        <template #cell-id="{ item }">
          #{{ item.id }}
        </template>

        <template #cell-unit_price="{ item }">
          {{ convertToCurrencyFormat(item.unit_price, 3) }}
        </template>

        <template #cell-total_books_price="{ item }">
          {{ convertToCurrencyFormat(item.total_books_price, 3) }}
        </template>

        <template #cell-quantity="{ item }">
          <input
            type="number"
            v-model="item.quantity"
            min="0"
            class="form-control"
            @change="updateBookOrder(item)"
          />
        </template>
      </Table>
    </div>

    <hr />

    <h4 class="font-bold font-bold mb-0">Novo</h4>
    <span class="text-secondary font-semibold fs-12 mb-4">
      Adicionar novo livro ao pedido
    </span>

    <br /><br />

    <!-- Form para criar um novo livro no pedido -->
    <Form
      button-name="Adicionar livro"
      :method="createBookOrder"
      :processing="form.processing"
      :button-disabled="form.processing"
    >
      <div class="col-md-12">
        <label for="book_id" class="form-label">Livro</label>
        <select
          class="form-select"
          name="book_id"
          v-model="form.book_id"
          @change="updateBookDetails"
          required
        >
          <option value="" selected>--- Selecione algum livro ---</option>
          <option :value="book.id" v-for="(book, index) in books" :key="index">
            {{ book.name }}
          </option>
        </select>
        <small class="text-danger mt-3" v-if="form.errors.book_id">
          {{ form.errors.book_id }}
        </small>
      </div>

      <div class="col-6 col-md-4">
        <label for="unit_price" class="form-label">Preço da unidade</label>
        <div class="input-group">
          <input
            type="text"
            disabled
            class="form-control"
            id="unit_price"
            name="unit_price"
            v-model="form.unit_price"
          />
          <small class="text-danger mt-3" v-if="form.errors.unit_price">
            {{ form.errors.unit_price }}
          </small>
        </div>
      </div>

      <div class="col-md-4">
        <label for="total_books_price" class="form-label">Valor total</label>
        <div class="input-group">
          <input
            type="text"
            disabled
            class="form-control"
            id="total_books_price"
            name="total_books_price"
            v-model="form.total_books_price"
          />
          <small class="text-danger mt-3" v-if="form.errors.total_books_price">
            {{ form.errors.total_books_price }}
          </small>
        </div>
      </div>

      <div class="col-6 col-md-4">
        <label for="quantity" class="form-label">Quantidade</label>
        <input
          type="text"
          class="form-control"
          autocomplete="off"
          v-model="form.quantity"
          @input="calculateTotalPrice"
          id="quantity"
          name="quantity"
          required
          v-maska
          data-maska="##"
        />
        <small class="text-danger mt-3" v-if="form.errors.quantity">
          {{ form.errors.quantity }}
        </small>
      </div>
    </Form>
  </Dashboard>
</template>
