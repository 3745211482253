<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';

import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const form = useForm({ observation: null });

const store = () => form.post(route('orders.store'));
</script>

<template>
    <Dashboard page_title="Criar pedido">
        <Breadcrumb
            current-page-name="Criar pedido"
            :link-list="[
                {
                    previous_page_url: 'orders',
                    previous_page_name: 'Pedidos',
                },
            ]"
        />

        <Form
            button-name="Iniciar construção"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <p
                class="border-start border-5 border-warning font-semibold rounded-1 p-3 bg-info bg-opacity-10 fs-14"
            >
                <font-awesome-icon class="me-3" :icon="['fas', 'screwdriver-wrench']" />
                Faça pedidos para incluir novos livros na loja
            </p>
        </Form>
    </Dashboard>
</template>
