<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Components/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Composables/useImageResizer';

const form = useForm({
    name: null,
    description: null,
    visible_public: 1,
    photo: '/img/avatars/male.png',
    changed_photo: false,
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.photo);

const store = () => {
    form.photo = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('categories.store'));
};
</script>

<template>
    <Dashboard page_title="Criar categoria">
        <Breadcrumb
            current-page-name="Nova categoria"
            :link-list="[
                {
                    previous_page_url: 'categories',
                    previous_page_name: 'Categorias',
                },
            ]"
        />

        <div id="create-category">
            <h4 class="my-3">Nova categoria</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.changed_photo">
                    {{ form.errors.changed_photo }}
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label">Nome da categoria</label>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="name"
                        placeholder="Rhema"
                        v-model="form.name"
                        required
                    />

                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-4">
                    <label for="visible_public" class="form-label">Visível na loja</label>
                    <select
                        class="form-select"
                        name="visible_public"
                        id="visible_public"
                        v-model="form.visible_public"
                    >
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="personal-description" class="form-label">Descrição</label>
                    <small class="ms-2">(opcional)</small>
                    <textarea
                        type="text"
                        name="personal-description"
                        class="form-control"
                        id="personal-description"
                        v-model="form.description"
                        placeholder="Forneça um breve resumo desta categoria"
                        rows="5"
                    ></textarea>

                    <small class="text-danger mt-3" v-if="form.errors.description">{{
                        form.errors.description
                    }}</small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />

                <PhotoEditModal
                    v-if="form.photo"
                    title="Adicionar foto"
                    id="photoUpdateModal"
                    @image-resized="handleImageResized"
                />
            </Form>
        </div>
    </Dashboard>
</template>
