<template>
    <div>
        <div id="chart-bar">
            <VueApexCharts
                type="bar"
                ref="chartRef"
                height="300"
                :options="chartOptions"
                :series="formattedSeries"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script setup>
import VueApexCharts from 'vue3-apexcharts';
import { ref, reactive, defineProps, watch, computed } from 'vue';

const props = defineProps({
    labels: {
        type: Array,
        default: () => [],
    },
    series: {
        type: Array,
        default: () => [],
    },
    barColor: {
        type: String,
        default: '#0d6efd',
    },
    title: {
        type: String,
    },
});

const chartRef = ref(null);

const chartOptions = reactive({
    chart: {
        type: 'bar',
        height: 300,
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
        },
        toolbar: {
            tools: {
                download: false,
            },
        },
    },
    title: {
        text: props.title,
        align: 'center',
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#333',
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: 'rounded',
        },
    },
    xaxis: {
        categories: props.labels,
    },
    grid: {
        show: false,
    },
    pan: {
        enabled: false,
    },
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
    },
    colors: [props.barColor],
});

watch(
    () => props.labels,
    (newLabels) => {
        if (chartRef.value) {
            chartRef.value.updateOptions({
                xaxis: {
                    categories: newLabels,
                },
            });
        }
    }
);

watch(
    () => props.series,
    (newSeries) => {
        if (chartRef.value) {
            const formattedSeries = [
                {
                    name: 'Série',
                    data: newSeries,
                },
            ];

            chartRef.value.updateSeries(formattedSeries);
        }
    }
);

watch(
    () => props.barColor,
    (newColor) => {
        if (chartRef.value) {
            chartRef.value.updateOptions({
                colors: [newColor],
            });
        }
    }
);

const formattedSeries = computed(() => {
    return [
        {
            name: 'Série',
            data: props.series.length ? props.series : [0],
        },
    ];
});
</script>
