<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';
import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    books: Object,
});

const { searchParams, isLoading, compositionSeacher, executeSearch, updateLoadingState } =
    useSearch(
        {
            name: '',
            isbn: '',
            in_promotion: '',
            stock_available: '',
            created_at: '',
        },
        'books.index'
    );

const { convertToCurrencyFormat } = usePriceFormatter();

watch(
    () => props.books,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page_title="Listagem de livros" main-data="books">
        <Breadcrumb currentPageName="Listagem de livros" />

        <PageHeader title="Livros" :button-link="route('books.create')" button-text="Novo" />

        <div class="p-3 search-form-card">
            <div class="d-flex justify-content-between align-items-center mt-2 row px-3">
                <div class="font-bold col-12 col-sm-6">Baixar estoque de livros</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <ButtonWithIcon
                        :href="`/dashboard/books/pdf/export`"
                        background="danger"
                        color="white"
                        icon="file-pdf"
                        :linkInertia="false"
                        message="PDF"
                        additionalStyles="me-2"
                    />

                    <ButtonWithIcon
                        :href="`/dashboard/books/xlxs/export`"
                        background="success"
                        color="white"
                        icon="file-excel"
                        :linkInertia="false"
                        message="Excel"
                    />
                </div>
            </div>

            <hr class="mx-3" />

            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-8">
                        <label class="form-label" for="bookName">Nome do livro</label>
                        <input
                            id="bookName"
                            type="text"
                            class="form-control"
                            placeholder="Ex: LADRÕES DE SONHOS"
                            @input="(event) => compositionSeacher('name', event, executeSearch)"
                        />
                    </div>

                    <div class="col-md-4">
                        <label class="form-label" for="isbn">ISBN</label>
                        <input
                            id="isbn"
                            type="text"
                            class="form-control"
                            placeholder="978-85-6621-722-3"
                            @input="(event) => compositionSeacher('isbn', event, executeSearch)"
                        />
                    </div>

                    <div class="col-md-4">
                        <label class="form-label" for="in_promotion">Em promoção</label>
                        <select
                            class="form-select"
                            id="in_promotion"
                            v-model="searchParams.in_promotion"
                            @change="executeSearch()"
                        >
                            <option value="">Todos</option>
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                        </select>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label" for="stock_available">Estoque</label>
                        <select
                            class="form-select"
                            id="stock_available"
                            v-model="searchParams.stock_available"
                            @change="executeSearch()"
                        >
                            <option value="">Todos</option>
                            <option value="1">Disponível</option>
                            <option value="0">Em falta</option>
                        </select>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label" for="created_at">Data de Criação</label>
                        <input
                            id="created_at"
                            type="date"
                            class="form-control"
                            v-model="searchParams.created_at"
                            @change="executeSearch()"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-books"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', tdClass: 'text-start' },
                    {
                        key: 'price',
                        label: 'Preço',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'isbn', label: 'ISBN', thClass: 'text-center', tdClass: 'text-center' },
                    {
                        key: 'quantity',
                        label: 'Quantidade',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="books?.data"
                :pagination="{
                    data: books?.meta,
                    reference: 'books',
                }"
                :rowClass="
                    (item, index) => [
                        item.current_promotion ? 'table-warning' : '',
                        item.quantity < 1 ? 'table-info' : '',
                    ]
                "
                :rowLink="(item) => route('books.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage
                            :src="`/storage/${item.cover_image_url}`"
                            :alt="`Categoria ${item.name}`"
                            width="30px"
                        />
                        <span class="ms-3">
                            {{ item.name }}
                        </span>
                    </div>
                </template>

                <template #cell-price="{ item }">
                    {{
                        item.current_promotion
                            ? convertToCurrencyFormat(item.current_promotion.discounted_price)
                            : convertToCurrencyFormat(item.original_price)
                    }}
                </template>

                <template #cell-quantity="{ item }">
                    {{ item.quantity >= 1 ? item.quantity : '-' }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
