<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import usePreviewImage from '@/Composables/usePreviewImage.js';

const form = useForm({
    name: null,
    banner: null,
    order: 1,
    redirect_link: null,
    visible: false,
    changed_banner: false,
});

const { previewImage, imageUrl } = usePreviewImage();

const store = () => form.post(route('advertising-banners.store'));
</script>

<template>
    <Dashboard page_title="Criar Banner">
        <Breadcrumb
            current-page-name="Novo Banner"
            :link-list="[
                {
                    previous_page_url: 'advertising-banners',
                    previous_page_name: 'Banners',
                },
            ]"
        />

        <div id="create-banner">
            <h4 class="my-3">Novo Banner</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.changed_banner">
                    {{ form.errors.changed_banner }}
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label">Nome do Banner</label>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="name"
                        placeholder="Nome do Banner"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-4">
                    <label for="visible" class="form-label">Visível</label>
                    <select class="form-select" id="visible" v-model="form.visible">
                        <option :value="true">Sim</option>
                        <option :value="false">Não</option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="redirect_link" class="form-label">Link de Redirecionamento</label>
                    <input
                        type="url"
                        class="form-control"
                        id="redirect_link"
                        v-model="form.redirect_link"
                        placeholder="https://exemplo.com"
                    />
                    <small class="text-danger mt-3" v-if="form.errors.redirect_link">{{
                        form.errors.redirect_link
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="formFile" class="form-label"
                        >Banner da turma - Dimensões recomendadas: 1920 x 600</label
                    >
                    <input
                        id="formFile"
                        class="form-control"
                        type="file"
                        @input="form.banner = $event.target.files[0]"
                        @change="previewImage"
                    />
                    <small class="mt-3 informative-text">
                        O tamanho máximo que a imagem deve ter é de 2mb
                    </small>
                </div>

                <div class="col-md-11 mx-auto">
                    <img
                        v-if="imageUrl"
                        width="576"
                        height="180"
                        class="d-block mx-auto border border-5 border-light shadow"
                        :src="imageUrl"
                    />
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
