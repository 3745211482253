<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    promotions: Object,
});

const { searchParams, isLoading, compositionSeacher, executeSearch, updateLoadingState } =
    useSearch(
        {
            name: '',
        },
        'promotions.index'
    );

watch(
    () => props.promotions,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page_title="Listagem de promoções" main-data="promotions">
        <Breadcrumb currentPageName="Listagem de promoções" />

        <PageHeader
            title="Promoções"
            :button-link="route('promotions.create')"
            button-text="Nova"
        />

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="promotionName">Nome da promoção</label>
                        <input
                            id="promotionName"
                            type="text"
                            class="form-control"
                            :value="searchParams.name"
                            placeholder="Natal"
                            @input="(event) => compositionSeacher('name', event, executeSearch)"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-promotions"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', tdClass: 'text-start' },
                    {
                        key: 'book_total',
                        label: 'Livros',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="promotions.data"
                :pagination="{ data: promotions.meta, reference: 'promotions' }"
                :rowLink="(item) => route('promotions.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    {{ item.name }}
                </template>

                <template #cell-book_total="{ item }">
                    {{ item.book_total }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
