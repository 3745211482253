<script setup>
import { ref } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

const props = defineProps({
    clientOrder: Object,
    orders: Object,
    missingBooks: Object,
});

const formEditClientOrder = useForm({
    book_id: props.clientOrder.book?.id ?? '',
    order_id: props.clientOrder.order?.id ?? '',
    suggestive_book_name: props.clientOrder.suggestive_book_name,
    quantity: props.clientOrder.quantity,
    comments: props.clientOrder.comments,
});

const orderCancellationModalCloseButton = ref(null);

const update = () => formEditClientOrder.put(`/dashboard/client-orders/${props.clientOrder.id}`);

const cancelOrder = () => {
    router.put(`/dashboard/client-orders/${props.clientOrder.id}/cancel`);
    orderCancellationModalCloseButton.value.click();
};
</script>

<template>
    <Dashboard :page_title="`Pedido #${clientOrder.id}`">
        <div class="row mx-0" id="show-client">
            <Breadcrumb
                :currentPageName="clientOrder.id"
                :linkList="[
                    { previous_page_url: 'client-orders', previous_page_name: 'Pedidos Clientes' },
                ]"
            />
            <div class="bg-white p-4 rounded-3 border">
                <div class="col-12 col-md-11 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Editar dados do pedido</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                            <ButtonWithIcon
                                :href="`/dashboard/client-orders/${clientOrder.id}`"
                                background="primary"
                                color="white"
                                icon="eye"
                                message="Visualizar"
                                additionalStyles="me-2"
                            />
                            <ButtonWithIcon
                                href="#"
                                background="danger"
                                color="white"
                                icon="ban"
                                :linkInertia="false"
                                message="Cancelar"
                                dataBsToggle="modal"
                                dataBsTarget="#orderCancellationModal"
                            />
                        </div>
                    </div>
                    <hr />
                    <form class="py-4" @submit.prevent="update" id="client-order-creation-form">
                        <div class="row g-3">
                            <template
                                v-if="
                                    clientOrder.status == 'PENDENTE' ||
                                    clientOrder.status == 'LIVRO_VINCULADO'
                                "
                            >
                                <div class="col-md-8">
                                    <label for="suggestive_book_name" class="form-label"
                                        >Nome sugestivo do livro</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="suggestive_book_name"
                                        name="suggestive_book_name"
                                        v-model="formEditClientOrder.suggestive_book_name"
                                        placeholder="Feminismo: Perversão e subversão"
                                        autocomplete="off"
                                        required
                                    />
                                    <small
                                        class="text-danger mt-3"
                                        v-if="formEditClientOrder.errors.suggestive_book_name"
                                        >{{
                                            formEditClientOrder.errors.suggestive_book_name
                                        }}</small
                                    >
                                </div>
                                <div class="col-md-4">
                                    <label for="quantity" class="form-label">Quantidade</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="quantity"
                                        name="quantity"
                                        v-model="formEditClientOrder.quantity"
                                        autocomplete="off"
                                        required
                                    />
                                    <small
                                        class="text-danger mt-3"
                                        v-if="formEditClientOrder.errors.quantity"
                                        >{{ formEditClientOrder.errors.quantity }}</small
                                    >
                                </div>
                                <div class="col-md-4">
                                    <label for="order_id" class="form-label"
                                        >Pedido vinculado</label
                                    >
                                    <select
                                        class="form-select"
                                        name="order_id"
                                        id="order_id"
                                        v-model="formEditClientOrder.order_id"
                                    >
                                        <option value="">-- Nenhum pedido --</option>
                                        <option
                                            v-for="(order, index) in orders"
                                            :key="index"
                                            :value="order.id"
                                        >
                                            Pedido #{{ order.id }}
                                        </option>
                                    </select>
                                    <small
                                        class="text-danger mt-3"
                                        v-if="formEditClientOrder.errors.order_id"
                                        >{{ formEditClientOrder.errors.order_id }}</small
                                    >
                                </div>
                                <div class="col-md-8">
                                    <label for="book_id" class="form-label">Livro vinculado</label>
                                    <select
                                        class="form-select"
                                        name="book_id"
                                        id="book_id"
                                        v-model="formEditClientOrder.book_id"
                                    >
                                        <option value="" selected>-- Nenhum livro --</option>
                                        <option
                                            v-for="(book, index) in missingBooks"
                                            :key="index"
                                            :value="book.id"
                                        >
                                            {{ book.name }}
                                        </option>
                                    </select>
                                    <small
                                        class="text-danger mt-3"
                                        v-if="formEditClientOrder.errors.book_id"
                                        >{{ formEditClientOrder.errors.book_id }}</small
                                    >
                                </div>
                            </template>
                            <div class="col-md-12">
                                <label for="comments" class="form-label">Observações</label>
                                <textarea
                                    type="text"
                                    class="form-control"
                                    id="comments"
                                    name="comments"
                                    placeholder="Forneça alguma observação dita pelo cliente"
                                    v-model="formEditClientOrder.comments"
                                    rows="5"
                                ></textarea>
                                <small
                                    class="text-danger mt-3"
                                    v-if="formEditClientOrder.errors.comments"
                                    >{{ formEditClientOrder.errors.comments }}</small
                                >
                            </div>
                            <div class="col-12 text-end">
                                <button
                                    class="btn btn-success float-end"
                                    type="submit"
                                    :disabled="formEditClientOrder.processing"
                                >
                                    <span
                                        v-if="formEditClientOrder.processing"
                                        class="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Atualizar pedido
                                </button>
                            </div>
                        </div>
                    </form>

                    <ConfirmationModal
                        modal_id="orderCancellationModal"
                        modal_title="Confirmação de cancelamento de pedido"
                    >
                        <template v-slot:modal-text>
                            <p>Você tem certeza que deseja cancelar o pedido ?</p>
                            <p
                                class="border-start border-5 border-primary p-3 bg-info bg-opacity-10"
                            >
                                Quando cancelado, você não podera mudar o status do pedido
                                novamente.
                            </p>
                        </template>
                        <template v-slot:modal-footer>
                            <button
                                type="button"
                                ref="orderCancellationModalCloseButton"
                                class="btn btn-secondary text-white"
                                data-bs-dismiss="modal"
                            >
                                Voltar
                            </button>
                            <button
                                @click="cancelOrder()"
                                type="button"
                                class="btn btn-success text-white"
                            >
                                Cancelar pedido
                            </button>
                        </template>
                    </ConfirmationModal>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
