<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

const props = defineProps({
    clients: Object,
    orders: Object,
    missingBooks: Object,
});

const form = useForm({
    book_id: '',
    suggestive_book_name: null,
    quantity: 1,
    client_id: '',
    comments: null,
});

const store = () => form.post(`/dashboard/client-orders`);
</script>

<template>
    <Dashboard page_title="Criar pedido para cliente">
        <Breadcrumb currentPageName="Criar pedido para cliente" />
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3">
                <div class="row">
                    <form
                        class="col-10 mx-auto py-4"
                        @submit.prevent="store"
                        id="client-creation-form"
                    >
                        <div class="row g-3">
                            <div class="col-md-8">
                                <label for="suggestive_book_name" class="form-label"
                                    >Nome sugestivo do livro</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="suggestive_book_name"
                                    name="suggestive_book_name"
                                    v-model="form.suggestive_book_name"
                                    placeholder="Feminismo: Perversão e subversão"
                                    autocomplete="off"
                                    required
                                />
                                <small
                                    class="text-danger mt-3"
                                    v-if="form.errors.suggestive_book_name"
                                    >{{ form.errors.suggestive_book_name }}</small
                                >
                            </div>
                            <div class="col-md-4">
                                <label for="quantity" class="form-label">Quantidade</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="quantity"
                                    name="quantity"
                                    v-model="form.quantity"
                                    autocomplete="off"
                                    required
                                />
                                <small class="text-danger mt-3" v-if="form.errors.quantity">{{
                                    form.errors.quantity
                                }}</small>
                            </div>
                            <div class="col-md-7">
                                <label for="book_id" class="form-label">Livro vinculado</label>
                                <select
                                    class="form-select"
                                    name="book_id"
                                    id="book_id"
                                    v-model="form.book_id"
                                >
                                    <option value="" selected>-- Nenhum livro --</option>
                                    <option
                                        v-for="(book, index) in missingBooks"
                                        :key="index"
                                        :value="book.id"
                                    >
                                        {{ book.name }}
                                    </option>
                                </select>
                                <small class="text-danger mt-3" v-if="form.errors.book_id">{{
                                    form.errors.book_id
                                }}</small>
                            </div>
                            <div class="col-md-5">
                                <label for="client_id" class="form-label">Cliente vinculado</label>
                                <select
                                    class="form-select"
                                    name="client_id"
                                    id="client_id"
                                    v-model="form.client_id"
                                    required
                                >
                                    <option value="" selected>-- Selecionar cliente --</option>
                                    <option
                                        v-for="(client, index) in clients"
                                        :key="index"
                                        :value="client.id"
                                    >
                                        {{ client.name }}
                                    </option>
                                </select>
                                <small class="text-danger mt-3" v-if="form.errors.client_id">{{
                                    form.errors.client_id
                                }}</small>
                            </div>
                            <div class="col-md-12">
                                <label for="comments" class="form-label">Observações</label>
                                <textarea
                                    type="text"
                                    class="form-control"
                                    id="comments"
                                    name="comments"
                                    placeholder="Forneça alguma observação dita pelo cliente"
                                    v-model="form.comments"
                                    rows="5"
                                ></textarea>
                                <small class="text-danger mt-3" v-if="form.errors.comments">{{
                                    form.errors.comments
                                }}</small>
                            </div>
                            <div class="col-12 text-end">
                                <button
                                    class="btn btn-success float-end"
                                    type="submit"
                                    :disabled="form.processing"
                                >
                                    <span
                                        v-if="form.processing"
                                        class="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Criar pedido
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
