<script setup>
import { onMounted, ref, watchEffect } from 'vue';
import { router, useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';
import PhotoEditModal from '@/Pages/Dashboard/Components/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Composables/useImageResizer';

const props = defineProps({
    allBooks: Object,
    category: Object,
    categoryBooks: Object,
    errors: Object,
    promotions: Object,
    sessions: Object,
});

const form = useForm({
    _method: 'put',
    name: props.category.name,
    visible_public: props.category.visible_public,
    description: props.category.description,
    books: '',
    photo: props.category.photo,
    promotion_id: null,
    session_id: null,
    changed_photo: false,
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.photo);

const categoryEditModalCloseButton = ref(null);
const selectAll = ref(false);

const update = () => {
    form.photo = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('categories.update', props.category.id));
};

const destroy = () => {
    categoryEditModalCloseButton.value.click();
    router.delete(route('categories.destroy', props.category.id));
};

watchEffect(() => {
    if (selectAll.value) {
        form.books = props.allBooks.map((book) => book.id);
    } else {
        form.books = [];
    }
});

onMounted(() => {
    form.books = props.categoryBooks.map((book) => book.id);
});
</script>

<template>
    <Dashboard :page_title="category.name" main-data="category">
        <Breadcrumb
            :currentPageName="category.name"
            :linkList="[{ previous_page_url: 'categories', previous_page_name: 'Categorias' }]"
        />

        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-sm-6">
                <h4 class="font-bold m-0">Editar dados da categoria</h4>
            </div>
            <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                <ButtonWithIcon
                    :href="`/dashboard/categories/${category.id}`"
                    background="success"
                    color="white"
                    icon="eye"
                    message="Visualizar registro"
                    additionalStyles="me-2"
                />
                <ButtonWithIcon
                    href="#"
                    background="danger"
                    color="white"
                    icon="circle-xmark"
                    :linkInertia="false"
                    message="Deletar"
                    dataBsToggle="modal"
                    dataBsTarget="#categoryEditModal"
                />
            </div>
        </div>

        <hr />

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="d-flex justify-content-center">
                <div class="position-relative">
                    <img
                        :src="photo"
                        alt="Identification Photo"
                        class="upload-box img-fluid shadow-sm"
                    />
                    <div class="position-absolute bottom-0 end-0 m-3">
                        <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#photoUpdateModal"
                            class="btn btn-edit shadow"
                        >
                            <font-awesome-icon
                                class="text-white"
                                :icon="['fas', 'pen-to-square']"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <label for="name" class="form-label">Nome da categoria</label>
                <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="name"
                    name="name"
                    v-model="form.name"
                    required
                />
                <small class="text-danger mt-3" v-if="form.errors.name">{{
                    form.errors.name
                }}</small>
            </div>
            <div class="col-md-4">
                <label for="visible_public" class="form-label">Visível na loja</label>
                <select
                    class="form-select"
                    name="visible_public"
                    id="visible_public"
                    v-model="form.visible_public"
                >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                </select>
            </div>
            <div class="col-md-12">
                <label for="description" class="form-label">Descrição</label>
                <small class="ms-2">(opcional)</small>
                <textarea
                    type="text"
                    class="form-control"
                    id="description"
                    name="description"
                    v-model="form.description"
                    rows="5"
                ></textarea>
                <small class="text-danger mt-3" v-if="form.errors.description">{{
                    form.errors.description
                }}</small>
            </div>

            <ul class="list-group overflow-auto p-2" id="book-listing">
                <h5 class="mb-4 font-bold">Livros vínculados</h5>
                <!-- Checkbox para selecionar todos os livros -->
                <li class="list-group-item p-3">
                    <input
                        class="form-check-input me-1"
                        type="checkbox"
                        id="selectAllBooks"
                        v-model="selectAll"
                    />
                    <label
                        class="form-check-label stretched-link ms-3 fs-13"
                        for="selectAllBooks"
                        style="cursor: pointer"
                        >Selecionar todos livros</label
                    >
                </li>

                <li
                    class="list-group-item p-3 d-flex align-items-center"
                    v-for="(book, index) in allBooks"
                    :key="index"
                >
                    <div>
                        <input
                            class="form-check-input me-1"
                            type="checkbox"
                            :value="book.id"
                            :id="'checkBoxBook' + book.id"
                            :checked="form.books.includes(book.id) ? true : false"
                            v-model="form.books"
                        />
                    </div>
                    <label
                        class="form-check-label stretched-link ms-3 fs-13"
                        :for="'checkBoxBook' + book.id"
                        style="cursor: pointer"
                        >{{ book.name }}</label
                    >
                </li>
            </ul>

            <hr class="mb-0" />

            <legend>Sicronizar com promoção</legend>

            <ul class="list-group mb-3 p-2">
                <li class="list-group-item" v-for="(promotion, index) in promotions" :key="index">
                    <input
                        class="form-check-input me-1"
                        type="radio"
                        name="listGroupRadioPromotion"
                        :value="promotion.id"
                        v-model="form.promotion_id"
                        :id="'radioPromotion' + promotion.id"
                    />
                    <label
                        class="form-check-label fs-13 ms-3 stretched-link"
                        :for="'radioPromotion' + promotion.id"
                        style="cursor: pointer"
                        >{{ promotion.name }}</label
                    >
                </li>
            </ul>

            <template v-if="sessions.length">
                <hr />
                <legend>Sicronizar com seção da loja</legend>

                <ul class="list-group mb-3">
                    <li class="list-group-item" v-for="(session, index) in sessions" :key="index">
                        <input
                            class="form-check-input me-1"
                            type="radio"
                            name="listGroupRadioSession"
                            :value="session.id"
                            v-model="form.session_id"
                            :id="'radioSession' + session.id"
                        />
                        <label
                            class="form-check-label fs-13 ms-3 stretched-link"
                            :for="'radioSession' + session.id"
                            style="cursor: pointer"
                            >{{ session.name }}</label
                        >
                    </li>
                </ul>
            </template>

            <PhotoEditModal
                v-if="form.photo"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </Form>

        <ConfirmationModal
            modal_id="categoryEditModal"
            modal_title="Confirmação de remoção da categoria"
        >
            <template v-slot:modal-text>
                Você tem certeza que deseja excluir a categoria {{ category.name }}?
            </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="categoryEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar categoria
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
