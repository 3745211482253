<template>
    <div class="container-fluid p-3">
        <div class="row mx-auto my-auto">
            <div class="col-lg-11 mx-auto" v-if="data.length <= 4 && totalCarouselCards > 2">
                <div
                    class="row row-cols-1 row-cols-md-3 row-cols-xl-4 g-4 px-4 d-flex justify-content-center"
                >
                    <div class="col" v-for="(book, indice) in data" :key="indice">
                        <Card :book="book" :displayFavorite="true" />
                    </div>
                </div>
            </div>

            <div
                v-else
                :id="id"
                class="carousel carousel-dark slide col-lg-11 mx-auto"
                data-bs-ride="carousel"
            >
                <div class="carousel-inner">
                    <div
                        :class="key == 0 ? 'carousel-item active' : 'carousel-item'"
                        data-bs-interval="10000"
                        v-for="(books, key) in subdividedDataInVectors"
                        :key="key"
                    >
                        <div
                            class="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-4 p-2"
                        >
                            <div class="col" v-for="(book, indice) in books" :key="indice">
                                <Card :book="book" :displayFavorite="true" />
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    class="carousel-control-prev p-2"
                    type="button"
                    :data-bs-target="'#' + id"
                    data-bs-slide="prev"
                >
                    <span class="carousel-control-prev-icon px-2" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button
                    class="carousel-control-next"
                    type="button"
                    :data-bs-target="'#' + id"
                    data-bs-slide="next"
                >
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import Card from '@/Pages/Store/Shared/Card.vue';

const props = defineProps({
    data: Array,
    id: String,
});

const screenWidth = ref(0);

const totalCarouselCards = computed(() => {
    let total = 4;

    if (screenWidth.value < 1200) total = 3;
    if (screenWidth.value < 778) total = 2;

    return total;
});

const subdividedDataInVectors = computed(() => {
    let vectorizedData = [],
        i = 0,
        data = props.data;

    while (i < data.length) {
        vectorizedData.push(data.slice(i, (i += totalCarouselCards.value)));
    }

    return vectorizedData;
});

const currentScreenWidth = () => (screenWidth.value = window.screen.width);

screenWidth.value = window.screen.width;

window.addEventListener('resize', currentScreenWidth);
window.addEventListener('load', currentScreenWidth);
</script>
