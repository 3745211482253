<script setup>
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

const props = defineProps({
    clientTypes: Object,
});

const searchForm = reactive({ name: '' });

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    search();
};

const search = () => router.get(route('client-types.index'), searchForm, { preserveState: true });
</script>

<template>
    <Dashboard page_title="Listagem de Tipos de Cliente" main-data="clientTypes">
        <Breadcrumb currentPageName="Listagem de Tipos de Cliente" />

        <PageHeader
            title="Tipos de Cliente"
            :button-link="route('client-types.create')"
            button-text="Novo"
        />

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="clientTypeName"
                            >Nome do Tipo de Cliente</label
                        >
                        <input
                            id="clientTypeName"
                            type="text"
                            class="form-control"
                            v-model="searchForm.name"
                            placeholder="Ex: Cliente VIP"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-client-types"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', tdClass: 'text-start' },
                    {
                        key: 'discount_available',
                        label: 'Desconto (%)',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="clientTypes?.data"
                :pagination="{ data: clientTypes?.meta, reference: 'client_types' }"
                :rowLink="(item) => route('client-types.show', item.id)"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <span>{{ item.name }}</span>
                </template>

                <template #cell-discount_available="{ item }">
                    {{ item.discount_available }}%
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
