<script setup>
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const { convertToCurrencyFormat } = usePriceFormatter();

const props = defineProps({
    promotion: Object,
    books: Object,
});

const activeBookPromotion = (book_promotion_id) => book_promotion_id == props.promotion.id;
</script>

<template>
    <Dashboard :page_title="promotion.name">
        <div class="row mx-0" id="show-promotion">
            <Breadcrumb
                :currentPageName="promotion.name"
                :linkList="[{ previous_page_url: 'promotions', previous_page_name: 'Promoções' }]"
            />

            <div class="col-lg-12 bg-white p-4 rounded-3 border">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-sm-6 d-flex align-items-center">
                        <img
                            class="me-3"
                            v-if="promotion.rhema"
                            style="width: 35px"
                            src="/img/rhema_logo_colored.png"
                            alt=""
                        />
                        <h4 class="font-bold m-0">{{ promotion.name }}</h4>
                    </div>
                    <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                        <ButtonWithIcon
                            :href="route('promotions.edit', promotion.id)"
                            background="primary"
                            color="white"
                            icon="pen-to-square"
                            message="Editar dados"
                            additionalStyles="me-2"
                        />
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-3 pe-0">
                <button
                    type="button"
                    class="btn btn-success ms-2 font-semibold"
                    :class="promotion.active ? 'btn-success' : 'btn-danger'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :data-bs-title="`${promotion.active ? 'Promoção ativa' : 'Promoção desativada'}`"
                >
                    <font-awesome-icon :icon="['fas', promotion.active ? 'circle-check' : 'ban']" />
                </button>
                <button type="button" class="btn btn-warning ms-2 font-semibold">
                    {{ `${promotion.discount_percentage}% de desconto` }}
                </button>
            </div>

            <div class="col p-0" id="simplifiedBookListing">
                <div v-if="Object.values(books).length > 0">
                    <h4 class="mt-4">
                        {{ Object.values(books).length }} Livros associados a esta promoção
                    </h4>
                    <div class="row row-cols-1 row-cols-md-3 row-cols-xxl-4 g-3 mt-2">
                        <div class="col" v-for="(book, index) in books" :key="index">
                            <Link
                                class="text-decoration-none text-muted"
                                :href="`/dashboard/books/${book.id}`"
                            >
                                <div class="card-simple card">
                                    <div class="col">
                                        <button
                                            type="button"
                                            class="btn btn-white border text-dark m-2 float-end"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            :data-bs-title="`${activeBookPromotion(book.current_promotion?.promotion_id) ? 'Ativa' : 'Desativada'}`"
                                        >
                                            <font-awesome-icon
                                                class="text-success"
                                                :icon="[
                                                    'fas',
                                                    activeBookPromotion(
                                                        book.current_promotion?.promotion_id
                                                    )
                                                        ? 'circle-check'
                                                        : 'ban',
                                                ]"
                                            />
                                        </button>
                                    </div>

                                    <div
                                        class="card-img mt-3 d-flex align-items-center justify-content-center"
                                    >
                                        <img
                                            :src="`/storage/${book.cover_image_url}`"
                                            class=""
                                            alt=""
                                        />
                                    </div>
                                    <div class="card-body">
                                        <div
                                            class="card-title text-center d-flex align-items-center justify-content-center"
                                        >
                                            <h5>{{ book.name }}</h5>
                                        </div>

                                        <p
                                            class="card-price text-center"
                                            v-if="!book.promotional_price"
                                        >
                                            {{ convertToCurrencyFormat(book.original_price) }}
                                        </p>

                                        <p v-else class="card-price text-center">
                                            <span
                                                class="text-secondary text-decoration-line-through"
                                                >{{
                                                    convertToCurrencyFormat(book.original_price)
                                                }}</span
                                            >
                                            -
                                            <span class="text-success">{{
                                                convertToCurrencyFormat(book.promotional_price)
                                            }}</span>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-else>
                    <p
                        class="border-start border-5 border-primary p-3 bg-info bg-opacity-10 font-regular"
                    >
                        Até o momento, não há registro de nenhum livro associado a este item
                    </p>
                </div>
            </div>
            <Lear />
        </div>
    </Dashboard>
</template>
