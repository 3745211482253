<script setup>
import { ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Components/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Composables/useImageResizer';

const props = defineProps({
    author: Object,
    errors: Object,
});

const form = useForm({
    name: props.author.name,
    personal_description: props.author.personal_description,
    profile_picture_url: props.author.profile_picture_url,
    _method: 'put',
    changed_photo: false,
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.profile_picture_url);

const authorEditModalCloseButton = ref(null);

const update = () => {
    form.profile_picture_url = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('authors.update', props.author.id));
};

const destroy = () => {
    authorEditModalCloseButton.value.click();
    router.delete(route('authors.destroy', props.author.id));
};
</script>

<template>
    <Dashboard :page_title="author.name">
        <Breadcrumb
            :currentPageName="author.name"
            :linkList="[{ previous_page_url: 'authors', previous_page_name: 'Autores' }]"
        />

        <div id="edit-author">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="route('authors.show', author.id)"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#authorEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <hr />

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="name" class="form-label">Nome do autor</label>
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        id="name"
                        name="name"
                        v-model="form.name"
                        required
                    />
                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="personal-description" class="form-label">Resumo pessoal</label>
                    <textarea
                        type="text"
                        class="form-control"
                        id="personal-description"
                        name="personal_description"
                        v-model="form.personal_description"
                        rows="10"
                    ></textarea>
                </div>

                <PhotoEditModal
                    v-if="form.photo"
                    title="Adicionar foto"
                    id="photoUpdateModal"
                    @image-resized="handleImageResized"
                />
            </Form>

            <ConfirmationModal
                modal_id="authorEditModal"
                modal_title="Confirmação de remoção do autor"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o autor {{ author.name }}?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="authorEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar autor
                    </button>
                </template>
            </ConfirmationModal>

            <PhotoEditModal
                    v-if="form.profile_picture_url"
                    title="Adicionar foto"
                    id="photoUpdateModal"
                    @image-resized="handleImageResized"
                />
        </div>
    </Dashboard>
</template>
