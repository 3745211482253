<template>
    <div id="authorCarousel" class="carousel slide carousel-dark" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div
                v-for="(authorGroup, groupIndex) in responsiveAuthorGroups"
                :key="groupIndex"
                class="carousel-item"
                :class="{ active: groupIndex === 0 }"
            >
                <div class="container h-100 d-flex align-items-center">
                    <div class="row justify-content-center w-100 g-4 m-0">
                        <div
                            v-for="author in authorGroup"
                            :key="author.id"
                            :class="[
                                'col-12 col-sm-6 col-md-4 col-lg-2',
                                { 'mobile-author': windowWidth < 572 },
                            ]"
                        >
                            <div class="text-center author-card">
                                <Link
                                    class="link-underline-light"
                                    :href="route('store.authors.show', author.clean_name)"
                                >
                                    <img
                                        :src="author.profile_picture_url"
                                        :alt="author.name"
                                        class="rounded-circle mb-2"
                                        style="width: 80px; height: 80px; object-fit: cover"
                                    />
                                    <h6 class="author-name mt-2">{{ author.name }}</h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#authorCarousel"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#authorCarousel"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import { computed, ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
    authors: {
        type: Array,
        required: true,
    },
});

const windowWidth = ref(window.innerWidth);

// Atualiza a largura da janela quando redimensionada
const updateWindowWidth = () => {
    windowWidth.value = window.innerWidth;
};

// Adiciona e remove o event listener
onMounted(() => {
    window.addEventListener('resize', updateWindowWidth);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateWindowWidth);
});

const responsiveAuthorGroups = computed(() => {
    const isMobile = windowWidth.value < 572;
    const groupSize = isMobile ? 1 : 6;

    const groups = [];
    for (let i = 0; i < props.authors.length; i += groupSize) {
        groups.push(props.authors.slice(i, i + groupSize));
    }
    return groups;
});
</script>

<style scoped>
.carousel-item {
    min-height: 180px;
}

#authorCarousel {
    height: 200px;
    max-width: 1200px;
    margin: 0 auto;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

.author-card img {
    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
}

.author-card img:hover {
    transform: scale(1.1);
}

.author-name {
    font-size: 1rem;
    margin: 0;
    color: #333;
}

.carousel-inner {
    height: 100%;
}

@media (max-width: 571px) {
    #authorCarousel {
        height: 160px;
    }

    .carousel-item {
        min-height: 140px;
    }

    .author-card {
        padding: 5px 0; /* Remove padding horizontal */
    }

    .mobile-author {
        padding: 0; /* Remove padding da coluna em mobile */
        max-width: 100%;
        flex: 0 0 100%;
    }

    /* Remove margens do container em mobile */
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>
