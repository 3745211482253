export default function usePriceFormatter() {
    function convertDecimalFormat(value) {
        return Number(value.replace('.', '').replace(',', '.')).toFixed(2);
    }

    function convertToCurrencyFormat(value, decimal_places = 2) {
        return parseFloat(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'symbol',
            minimumFractionDigits: decimal_places,
            maximumFractionDigits: decimal_places,
        });
    }

    return {
        convertDecimalFormat,
        convertToCurrencyFormat,
    };
}
