<script setup>
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    client: Object,
    member: Object,
    sales: Object,
});

const { convertToCurrencyFormat } = usePriceFormatter();
</script>

<template>
    <Dashboard :page_title="client.name">
        <div id="show-client">
            <Breadcrumb
                :currentPageName="client.name"
                :linkList="[{ previous_page_url: 'clients', previous_page_name: 'Clientes' }]"
            />

            <div class="d-flex justify-content-between container-buttonsm align-items-center">
                <Badge :name="client.type" />
                <Link :href="route('clients.edit', client.id)" class="btn btn-add btn-primary">
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>

            <div class="row">
                <div class="d-flex justify-content-center my-4">
                    <LazyImage :src="member?.data?.identification_photo" width="200px" />
                </div>

                <div class="col-12">
                    <div class="row">
                        <h4 class="text-center">
                            {{ client.name }}
                        </h4>
                    </div>
                </div>
            </div>

            <div class="form-card p-2 mt-3" v-if="client.sgi_member_id && member?.data">
                <form action="">
                    <fieldset>
                        <div class="row g-4 p-3">
                            <div class="col-6">
                                <label for="name" class="form-label">Telefone de contato</label>
                                <div class="input-group mb-2">
                                    <input
                                        disabled
                                        type="text"
                                        :value="member?.data?.phone_number"
                                        class="form-control"
                                    />
                                    <a
                                        :href="`https://wa.me/55${member.data.phone_number}`"
                                        target="_blank"
                                        class="btn btn-success btn-add"
                                    >
                                        <font-awesome-icon :icon="['fab', 'whatsapp']" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="email" class="form-label">Email</label>
                                <div class="input-group mb-2">
                                    <input
                                        disabled
                                        type="text"
                                        id="email"
                                        autocomplete="on"
                                        class="form-control"
                                        :value="member.data.email"
                                    />
                                    <a
                                        :href="`mailto:${member.data.email}`"
                                        class="btn btn-primary btn-add"
                                    >
                                        <font-awesome-icon :icon="['fas', 'envelope']" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>

            <hr />

            <h4 class="font-bold font-bold mb-0">Compras</h4>
            <span class="text-secondary font-semibold fs-14 mb-3"
                >Visualize todas as compras deste cliente</span
            >

            <Table
                v-if="sales.length"
                table-id="table-sales"
                :headers="[
                    { key: 'id', label: 'ID' },
                    { key: 'total_sale_price', label: 'Valor' },
                    {
                        key: 'sale_payment_methods',
                        label: 'Método',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'user_avatar',
                        label: 'Vendedor',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'created_at',
                        label: 'Data',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="sales"
                :pagination="{
                    reference: 'sales',
                }"
                :rowLink="(item) => route('sales.show', item.id)"
            >
                <template #cell-id="{ item }"> #{{ item.id }} </template>

                <template #cell-total_sale_price="{ item }">
                    {{ convertToCurrencyFormat(item.total_sale_price) }}
                </template>

                <template #cell-sale_payment_methods="{ item }">
                    <div class="d-flex justify-content-center">
                        <button
                            class="d-flex align-items-center btn btn-outline-success ms-3"
                            :key="item.sale_payment_methods[0].payment_method.name"
                            disabled
                        >
                            <img
                                :src="`${item.sale_payment_methods[0].payment_method.photo_identification}`"
                                alt=""
                                width="20"
                            />
                            <span class="ms-2 fs-12">{{
                                item.sale_payment_methods[0].payment_method.name
                            }}</span>
                        </button>
                    </div>
                </template>

                <template #cell-user_avatar="{ item }">
                    <div class="d-flex justify-content-center">
                        <LazyImage
                            :src="item.user_avatar"
                            :alt="`Categoria ${item.name}`"
                            width="30px"
                        />
                    </div>
                </template>
            </Table>

            <div v-else class="alert alert-primary mt-4">
                Esse cliente ainda não comprou nenhum livro em nossa loja
            </div>

            <div v-if="client.rhema_student_code">
                <hr>
                <h5 class="font-bold font-bold mb-0">Código Rhema:   {{ client.rhema_student_code }}</h5>
            </div>
        </div>
    </Dashboard>
</template>
