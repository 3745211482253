<script setup>
import { onMounted } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import { vMaska } from 'maska';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    types: Object,
    members: Object,
});

const { searchParams, compositionSeacher, toggleSelection } = useSearch({
    member_name: null,
});

const form = useForm({
    name: null,
    type_id: props.types[0].id,
    member_id: null,
    member_name: null,
});

const store = () => form.post(route('clients.store'));

const search = () => {
    router.visit(route('clients.create'), {
        method: 'get',
        data: searchParams.value,
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
        },
    });
};

onMounted(() => {
    const url = new URL(window.location.href);

    if (url.search) {
        router.get(route('clients.create'), {}, { replace: true, preserveState: true });
    }
});
</script>

<template>
    <Dashboard page_title="Criar cliente">
        <Breadcrumb
            current-page-name="Novo cliente"
            :link-list="[
                {
                    previous_page_url: 'clients',
                    previous_page_name: 'Clientes',
                },
            ]"
        />

        <div id="create-client">
            <h4 class="my-3">Novo cliente</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-5">
                    <label for="name" class="form-label">Nome do cliente</label>
                    <input
                        id="member_id"
                        v-model="form.member_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Matheus de Souza"
                        @input="(event) => compositionSeacher('member_name', event, search)"
                    />

                    <small class="text-danger mt-3" v-if="form.errors.name">{{
                        form.errors.name
                    }}</small>
                </div>

                <div class="col-md-4">
                    <label for="type" class="form-label">Tipo de cliente</label>
                    <select
                        class="form-select"
                        id="payment_method_id"
                        name="payment_method_id"
                        v-model="form.type_id"
                        required
                    >
                        <option :value="type.id" v-for="(type, index) in types" :key="index">
                            {{ type.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-3">
                    <label for="name" class="form-label">COD Rhema</label>
                    <input
                        id="member_id"
                        v-model="form.rhema_student_code"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="000000"
                        v-maska
                        data-maska="######"
                    />

                    <small class="text-danger mt-3" v-if="form.errors.rhema_student_code">{{
                        form.errors.rhema_student_code
                    }}</small>
                </div>

                <template v-if="members?.data">
                    <hr class="mb-0" />

                    <div class="alert alert-primary">
                        Verifique se esse cliente já é um membro da nossa igreja
                    </div>

                    <ul class="list-group member-found mt-0">
                        <li
                            v-for="(item, index) in members.data"
                            :key="index"
                            class="list-group-item p-2 d-flex align-items-center mb-2"
                            :class="{ checked: form.member_id === item.id }"
                        >
                            <input
                                :id="'checkBoxSpouse' + item.id"
                                class="form-check-input me-1 mb-0"
                                type="checkbox"
                                name="listGroupRadio"
                                :value="item.id"
                                :checked="form.member_id === item.id"
                                @change="toggleSelection('member_id', item.id, form)"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                                :for="'checkBoxSpouse' + item.id"
                                style="cursor: pointer"
                            >
                                <div class="d-flex align-items-center">
                                    <LazyImage
                                        :src="`${item.identification_photo}`"
                                        :alt="`Client ${item.name}`"
                                        width="30px"
                                    />
                                    <span class="ms-3">{{ item.name }}</span>
                                </div>
                            </label>
                        </li>
                    </ul>
                </template>
            </Form>
        </div>
    </Dashboard>
</template>
