<script>
import { Head, Link } from '@inertiajs/vue3';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import draggable from 'vuedraggable';

export default {
    name: 'Ordering',
    components: {
        Breadcrumb,
        Dashboard,
        Head,
        Link,
        draggable,
    },
    props: {
        sessions: Object,
    },
    methods: {
        handleDragChange(event) {
            this.$inertia.put(route('store-session.ordination'), {
                sessions: this.items,
            });
        },
    },
    data() {
        return {
            items: this.sessions.data,
        };
    },
};
</script>

<template>
    <Head title="Odernação das Seções" />
    <Dashboard>
        <div class="row">
            <div class="col-xxl-8 col-xl-12 mx-auto">
                <Breadcrumb currentPageName="Odernação das Seções" />
                <h4>Ordenar seções da loja</h4>
                <div class="row mt-4 mx-0" id="sessionOrdering">
                    <div class="bg-white rounded-3">
                        <div class="row">
                            <div class="col-12 p-0">
                                <draggable
                                    class="list-group"
                                    v-model="items"
                                    :item-key="(item) => item.id"
                                    @change="handleDragChange"
                                >
                                    <template v-slot:item="{ element }">
                                        <li
                                            class="list-group-item p-4 font-semibold d-flex align-items-center"
                                        >
                                            <font-awesome-icon
                                                class="me-3 text-secondary"
                                                :icon="['fas', 'grip-vertical']"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            />
                                            <span class="badge bg-primary me-3"
                                                >{{ element.display_sequence }} ª
                                            </span>
                                            {{ element.name }}
                                        </li>
                                    </template>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
