<script setup>
import { onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';

import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    session: Object,
    allCategories: Object,
    sessionCategories: Object,
});

const form = useForm({
    name: props.session.name,
    visible: props.session.visible,
    description: props.session.description,
    rhema: props.session.rhema,
    categories: [],
});

const storeEditModalCloseButton = ref(null);

const update = () => form.put(route('store-session.update', props.session.id));

onMounted(() => {
    form.categories = props.sessionCategories.map((category) => category.id);
});

const destroy = () => {
    storeEditModalCloseButton.value.click();
    router.delete(route('clients.destroy'), props.client.id);
};
</script>

<template>
    <Dashboard :page_title="session.name">
        <Breadcrumb
            :currentPageName="session.name"
            :linkList="[{ previous_page_url: 'store-session', previous_page_name: 'Seções' }]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="route('store-session.show', session.id)"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#storeEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <hr />

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-5">
                <label for="name" class="form-label">Nome da seção da loja</label>
                <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="name"
                    name="name"
                    v-model="form.name"
                    required
                />
                <small class="text-danger mt-3" v-if="form.errors.name">{{
                    form.errors.name
                }}</small>
            </div>

            <div class="col-md-4 col-6">
                <label for="rhema" class="form-label">Rhema</label>
                <select class="form-select" name="rhema" id="rhema" v-model="form.rhema">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                </select>
            </div>

            <div class="col-lg-3 col-md-4 d-flex align-items-end justify-content-center">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="visible"
                        v-model="form.visible"
                    />
                    <label class="form-check-label" for="visible"> Seção visível </label>
                </div>
            </div>
            <div class="col-md-12">
                <label for="description" class="form-label">Descrição</label>
                <textarea
                    type="text"
                    class="form-control"
                    id="description"
                    name="description"
                    v-model="form.description"
                    rows="5"
                ></textarea>
            </div>

            <div class="col-md-12">
                <legend>Categorias vinculadas</legend>
                <ul class="list-group overflow-auto category-listing mt-3">
                    <li
                        class="list-group-item p-3"
                        v-for="(category, index) in allCategories"
                        :key="index"
                    >
                        <input
                            class="form-check-input me-1"
                            type="checkbox"
                            :value="category.id"
                            :id="'checkBoxCategory' + category.id"
                            :checked="form.categories.includes(category.id) ? true : false"
                            v-model="form.categories"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13"
                            :for="'checkBoxCategory' + category.id"
                            style="cursor: pointer"
                            >{{ category.name }}</label
                        >
                    </li>
                </ul>
            </div>
            <ConfirmationModal
                modal_id="storeEditModal"
                modal_title="Confirmação de remoção do autor"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o autor {{ session.name }}?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="storeEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar autor
                    </button>
                </template>
            </ConfirmationModal>
        </Form>
    </Dashboard>
</template>
