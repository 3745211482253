<script setup>
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import ActivityLogs from './Components/ActivityLogs.vue';
import RecordMovements from './Components/RecordMovements.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ButtonWithIcon from '@/Pages/Dashboard/Components/ui/ButtonWithIcon.vue';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const { convertToCurrencyFormat } = usePriceFormatter();

const props = defineProps({
    authors: Object,
    book: Object,
    categories: Object,
    activitys: Object,
    movementRecords: Object,
});

const convertYouTubeLink = (link) => {
    if (link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];

        return link;
    }
};

const copyToClipboard = async (value) => {
    try {
        await navigator.clipboard.writeText(value);
        copied.value = true;
        setTimeout(() => (copied.value = false), 2000);
    } catch (err) {
        console.error('Erro ao copiar: ', err);
    }
};
</script>

<template>
    <Dashboard :page_title="book.name" main-data="books">
        <Breadcrumb
            :currentPageName="book.name"
            :linkList="[{ previous_page_url: 'books', previous_page_name: 'Livros' }]"
        />

        <div class="col-lg-12">
            <div id="show-book">
                <div class="row">
                    <div>
                        <template v-if="book.current_promotion">
                            <div class="col-lg-12 bg-light">
                                <div class="row d-flex justify-content-between align-items-center">
                                    <span class="font-bold col-12 col-lg-8"
                                        >Custando
                                        {{
                                            convertToCurrencyFormat(
                                                book.current_promotion.discounted_price
                                            )
                                        }}
                                        na promoção de {{ book.current_promotion.name }}</span
                                    >
                                    <div class="col col-lg-4 text-end mt-2 mt-sm-0">
                                        <ButtonWithIcon
                                            :href="`/dashboard/promotions/${book.current_promotion.promotion_id}`"
                                            background="warning"
                                            color="dark"
                                            icon="percent"
                                            message="Ver promoção"
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </template>

                        <div class="row">
                            <div class="text-end p-2">
                                <button
                                    type="button"
                                    class="btn btn-success me-2"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    :data-bs-title="`${book.visible ? 'Vísivel na loja' : 'Não vísivel na loja'}`"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', book.visible ? 'eye' : 'eye-slash']"
                                    />
                                </button>

                                <ButtonWithIcon
                                    :href="`/dashboard/books/edit/${book.id}`"
                                    background="primary"
                                    color="white"
                                    icon="pen-to-square"
                                    message="Editar livro"
                                />
                            </div>
                        </div>

                        <img
                            :src="`/storage/${book.cover_image_url}`"
                            class="mb-4 book-cover mx-auto d-block bg-transparent"
                            alt=""
                        />

                        <ul
                            class="nav nav-tabs d-flex justify-content-center"
                            id="myTab"
                            role="tablist"
                        >
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link active"
                                    id="data-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#data-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="data-tab-pane"
                                    aria-selected="true"
                                >
                                    <font-awesome-icon class="me-2" :icon="['fas', 'book']" />
                                    Dados
                                </button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="timeline-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#timeline-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="timeline-tab-pane"
                                    aria-selected="false"
                                >
                                    <font-awesome-icon class="me-2" :icon="['fas', 'list-check']" />
                                    Linha do tempo
                                </button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="contact-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#movements-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="movements-tab-pane"
                                    aria-selected="false"
                                    :disabled="movementRecords.length <= 0"
                                >
                                    <font-awesome-icon
                                        class="me-2"
                                        :icon="['fas', 'truck-moving']"
                                    />
                                    Movimentações
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content mt-3" id="myTabContent">
                        <div
                            class="tab-pane fade show active card-simple p-4"
                            id="data-tab-pane"
                            role="tabpanel"
                            aria-labelledby="data-tab"
                            tabindex="0"
                        >
                            <form action="">
                                <fieldset disabled>
                                    <div class="row g-4 p-3">
                                        <div class="col-8">
                                            <label for="name" class="form-label"
                                                >Nome do livro</label
                                            >
                                            <input
                                                type="text"
                                                id="name"
                                                autocomplete="on"
                                                class="form-control"
                                                :value="book.name"
                                            />
                                        </div>
                                        <div class="col-sm-4 col-8">
                                            <label for="isbn" class="form-label">ISBN</label>
                                            <div class="input-group mb-3">
                                                <input
                                                    type="text"
                                                    id="isbn"
                                                    class="form-control"
                                                    :value="book.isbn"
                                                />
                                                <span
                                                    style="cursor: pointer"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    data-bs-title="Copiar isbn"
                                                    @click="copyToClipboard(book.isbn)"
                                                    class="input-group-text border-0 bg-ligth"
                                                    id="basic-addon1"
                                                    ><font-awesome-icon
                                                        class="me-2"
                                                        :icon="['fas', 'copy']"
                                                /></span>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-4">
                                            <label for="total_pages" class="form-label"
                                                >Páginas</label
                                            >
                                            <input
                                                type="text"
                                                id="total_pages"
                                                class="form-control"
                                                :value="book.total_pages"
                                            />
                                        </div>

                                        <div class="col-4 col-sm-3">
                                            <label for="original_price" class="form-label"
                                                >Preço padrão</label
                                            >
                                            <input
                                                type="text"
                                                id="original_price"
                                                class="form-control"
                                                :value="
                                                    convertToCurrencyFormat(book.original_price)
                                                "
                                            />
                                        </div>
                                        <div class="col-4 col-sm-3">
                                            <label for="supplier_price" class="form-label"
                                                >Preço de custo</label
                                            >
                                            <input
                                                type="text"
                                                id="supplier_price"
                                                class="form-control"
                                                :value="
                                                    convertToCurrencyFormat(book.supplier_price, 3)
                                                "
                                            />
                                        </div>
                                        <div class="col-6 col-sm-3">
                                            <label for="quantity" class="form-label"
                                                >Em estoque</label
                                            >
                                            <input
                                                type="text"
                                                id="quantity"
                                                class="form-control"
                                                :value="`${book.quantity > 0 ? book.quantity : 'Em falta'}`"
                                            />
                                        </div>
                                        <div class="col-6 col-sm-12">
                                            <label for="release_date" class="form-label"
                                                >Data de lançamento</label
                                            >
                                            <input
                                                type="date"
                                                id="release_date"
                                                class="form-control"
                                                v-model="book.release_date"
                                            />
                                        </div>

                                        <div class="col-12" v-if="book.description">
                                            <label for="description" class="form-label"
                                                >Sinopse</label
                                            >
                                            <textarea
                                                class="form-control"
                                                id="description"
                                                :value="book.description"
                                                rows="12"
                                            ></textarea>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>

                            <template v-if="categories.length > 0">
                                <hr />
                                <h5 class="font-bold">Categorias vinculadas ao livro</h5>

                                <div class="mt-3">
                                    <Link
                                        :href="`/dashboard/categories/${category.id}`"
                                        class="btn btn-warning rounded-pill mb-3 fs-12 font-bold text-dark d-inline-block me-2 p-2"
                                        v-for="(category, index) in categories"
                                        :key="index"
                                    >
                                        {{ category.name }}
                                    </Link>
                                </div>
                            </template>

                            <template v-if="authors.length > 0">
                                <hr />
                                <h5 class="font-bold">Autores vinculados ao livro</h5>

                                <div class="row mb-3" id="author-book">
                                    <div
                                        class="col"
                                        v-for="(author, index) in authors"
                                        :key="index"
                                    >
                                        <Link :href="`/autores/${author.clean_name}`">
                                            <img
                                                class="d-block mx-auto"
                                                :src="`${author.profile_picture_url}`"
                                                alt=""
                                            />
                                            <span
                                                class="text-center d-block mx-auto font-bold mt-2 fs-13"
                                                >{{ author.name }}</span
                                            >
                                        </Link>
                                    </div>
                                </div>
                            </template>

                            <div class="row mb-3" v-if="book.presentation_youtube_url">
                                <hr />
                                <h5 class="font-bold mb-3">Vídeo de apresentação do livro</h5>
                                <div class="col-11 mx-auto">
                                    <div class="mt-3 d-flex justify-content-center">
                                        <iframe
                                            class="rounded-4 youtube-video"
                                            :src="convertYouTubeLink(book.presentation_youtube_url)"
                                            title="YouTube video"
                                            allowfullscreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3" v-if="book.spotify_link_url">
                                <hr />
                                <h5 class="font-bold mb-3">Audiobooks</h5>
                                <a
                                    :href="book.spotify_link_url"
                                    target="_blank"
                                    class="btn btn-success font-bold fs-14"
                                >
                                    <font-awesome-icon class="me-2" :icon="['fab', 'spotify']" />
                                    Ouvir no Sportify
                                </a>
                            </div>
                        </div>

                        <div
                            class="tab-pane fade"
                            id="timeline-tab-pane"
                            role="tabpanel"
                            aria-labelledby="timeline-tab"
                            tabindex="0"
                        >
                            <div class="col-lg-12 overflow-auto activity-record">
                                <ActivityLogs
                                    title="Linha do tempo do livro"
                                    :activitys="activitys"
                                />
                            </div>
                        </div>

                        <div
                            class="tab-pane fade"
                            id="movements-tab-pane"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabindex="0"
                        >
                            <div class="col-lg-12 overflow-auto activity-record">
                                <RecordMovements :activitys="movementRecords" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
