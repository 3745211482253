<script setup>
import { onMounted, ref } from 'vue';
import { Link, useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    role: Object,
    allPermissions: Object,
    rolePermissions: Object,
    errors: Object,
});

const form = useForm({
    display_name: props.role.display_name,
    description: props.role.description,
    permissions: [],
});

const roleEditModalCloseButton = ref(null);

const update = () => router.put(route('roles.update', props.role.id), form);
const destroy = () => {
    roleEditModalCloseButton.value.click();
    router.delete(route('roles.destroy', props.role.id));
};

onMounted(() => {
    form.permissions = props.rolePermissions.map((role) => role.id);
});
</script>

<template>
    <Dashboard :page_title="role.display_name">
        <Breadcrumb
            :currentPageName="role.display_name"
            :linkList="[{ previous_page_url: 'roles', previous_page_name: 'Funções' }]"
        />

        <div id="edit-role" class="p-0">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link :href="route('roles.show', role.id)" class="btn btn-success btn-add me-2">
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#roleEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <hr />

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-4">
                    <label for="display_name" class="form-label">Nome legível da função</label>
                    <input
                        type="text"
                        id="display_name"
                        class="form-control"
                        v-model="form.display_name"
                        autocomplete="off"
                        required
                    />
                    <small class="text-danger" v-if="errors.display_name">{{
                        errors.display_name
                    }}</small>
                </div>
                <div class="col-8">
                    <label for="description" class="form-label">Nome legível da função</label>
                    <input
                        type="text"
                        id="description"
                        class="form-control"
                        v-model="form.description"
                        autocomplete="off"
                        required
                    />
                    <small class="text-danger" v-if="errors.description">{{
                        errors.description
                    }}</small>
                </div>

                <ul class="list-group overflow-auto p-2 normal-listing">
                    <h5 class="mb-4 font-bold">Permissões atribuídas a esta função</h5>
                    <li
                        class="list-group-item p-3"
                        v-for="(permission, index) in allPermissions"
                        :key="index"
                    >
                        <input
                            class="form-check-input me-1"
                            type="checkbox"
                            :value="permission.id"
                            :id="'checkBoxPermission' + permission.id"
                            :checked="form.permissions.includes(permission.id) ? true : false"
                            v-model="form.permissions"
                        />
                        <label
                            class="form-check-label stretched-link ms-3"
                            :for="'checkBoxPermission' + permission.id"
                            style="cursor: pointer"
                            >{{ permission.display_name }} - {{ permission.description }}</label
                        >
                    </li>
                </ul>

                <ConfirmationModal
                    modal_id="roleDestroyModal"
                    modal_title="Confirmação de remoção do cliente"
                >
                    <template v-slot:modal-text>
                        Você tem certeza que deseja excluir a função de {{ role.display_name }}?
                    </template>
                    <template v-slot:modal-footer>
                        <button
                            type="button"
                            ref="roleDestroyModalCloseButton"
                            class="btn btn-secondary text-white"
                            data-bs-dismiss="modal"
                        >
                            Voltar
                        </button>
                        <button @click="destroy" type="button" class="btn btn-danger text-white">
                            Deletar função
                        </button>
                    </template>
                </ConfirmationModal>
            </Form>
        </div>
    </Dashboard>
</template>
