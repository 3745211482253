<script setup>
import { ref, reactive, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import BookImage from '@/Pages/Dashboard/Pages/Books/Components/BookImage.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import { vMaska } from 'maska';
import usePriceFormatter from '@/Composables/usePriceFormatter.js';

const props = defineProps({
    book: Object,
});

const hasPromotional = reactive({
    status: false,
    promotionalValue: null,
    originalValue: null,
});

const { convertToCurrencyFormat } = usePriceFormatter();

const bookIdInputIsbn = ref(null);

const seacher = reactive({
    book_isbn: '',
});

const didInitialSearch = ref(false);

const search = () => {
    if (!seacher.book_isbn.trim()) return;

    router.visit(route('books.quick-consultation'), {
        method: 'get',
        data: { book_isbn: seacher.book_isbn },
        only: ['book'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            if (props.book.current_promotion) {
                hasPromotional.status = true;
                hasPromotional.promotionalValue = props.book.current_promotion.discounted_price;
                hasPromotional.originalPrice = props.book?.original_price;
            } else {
                hasPromotional.status = false;
            }
        },
        onFinish: () => (didInitialSearch.value = true),
    });
};

let timeoutId = null;

const compositionSeacher = (event) => {
    seacher.book_isbn = event.target.value.replace(/\s/g, '');

    if (seacher.book_isbn.length >= 34) {
        seacher.book_isbn = seacher.book_isbn.slice(-17);
        event.target.value = seacher.book_isbn;
    }

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

onMounted(() => {
    bookIdInputIsbn.value?.focus();
});
</script>

<template>
    <Dashboard page_title="Vender livro">
        <Breadcrumb
            currentPageName="Preço livro"
            :linkList="[{ previous_page_url: 'books', previous_page_name: 'Livros' }]"
        />

        <div class="row mt-2 mx-0">
            <div class="col-lg-12 card-simple p-4">
                <h4 class="text-center mt-3">Obter preço do livro</h4>
                <div class="col-md-12 mt-4">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text bg-dark" id="inputGroup-sizing-lg">
                            <font-awesome-icon
                                class="link-icon text-white"
                                :icon="['fas', 'barcode']"
                            />
                        </span>
                        <input
                            type="text"
                            class="form-control"
                            ref="bookIdInputIsbn"
                            v-maska
                            data-maska="###-##-####-###-#"
                            @input="compositionSeacher($event)"
                            placeholder="Informe o ISBN do livro"
                            autocomplete="off"
                            required
                            @keydown.enter.prevent
                        />
                    </div>
                </div>

                <div v-if="book">
                    <hr />
                    <div class="col-md-12">
                        <div class="row mt-3">
                            <BookImage :url="`/storage/${book.cover_image_url}`" />
                            <h5 class="text-center mt-3 font-bold">{{ book.name }}</h5>
                            <h4 class="mt-3 text-center font-bold text-secondary">
                                {{ convertToCurrencyFormat(book.original_price) }} -
                                <span v-if="book.quantity">{{ book.quantity }} em estoque</span>
                                <span v-else>Em falta</span>
                            </h4>

                            <div class="row mt-3 p-3" v-if="hasPromotional.status">
                                <hr />
                                <p
                                    class="border-start border-5 border-warning font-semibold rounded-1 p-3 bg-info bg-opacity-10 fs-14"
                                >
                                    Livro em promoção - De
                                    <del>{{
                                        convertToCurrencyFormat(hasPromotional.originalPrice)
                                    }}</del>
                                    por apenas
                                    {{ convertToCurrencyFormat(hasPromotional.promotionalValue) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="!book && didInitialSearch">
                    <p
                        class="border-start border-5 mt-4 border-danger font-semibold p-3 bg-info bg-opacity-10 fs-14"
                    >
                        Nenhum livro encontrado
                    </p>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
