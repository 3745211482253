<script setup>
import { ref, reactive, nextTick, onMounted } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import BookImage from '@/Pages/Dashboard/Pages/Books/Components/BookImage.vue';

import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import { vMaska } from 'maska';

import usePriceFormatter from '@/Composables/usePriceFormatter.js';
import useAxios from '@/Services/Axios.vue';

const bookIdInputIsbn = ref();
const book = reactive({
    book_id: null,
    name: null,
    stock: null,
    original_price: null,
    cover_image_url: null,
    found: true,
});

const hasPromotional = reactive({
    status: false,
    promotionalValue: null,
    originalValue: null,
});

const { convertToCurrencyFormat } = usePriceFormatter();
// const { instance: axios } = useAxios();

// const inputValue = ref('');

// const seacher = () => {
//     axios.get(`/searchByIsbn?isbn=${bookIdInputIsbn.value}`).then((response) => {
//         let book_response = response.data.books[0];

//         book.name = book_response?.name;
//         book.id = book_response?.id;
//         book.stock = book_response?.quantity;
//         book.cover_image_url = book_response?.cover_image_url;
//         book.found = book_response ? true : false;

//         if (book_response?.current_promotion) {
//             book.original_price = book_response.current_promotion.discounted_price.toString();
//             hasPromotional.status = true;
//             hasPromotional.promotionalValue = book_response.current_promotion.discounted_price;
//             hasPromotional.originalPrice = book_response?.original_price;
//         } else {
//             book.original_price = book_response?.original_price.toString();
//             hasPromotional.status = false;
//         }
//     });
// };

// let typingTimer = null;

// const compositionSeacher = (event) => {
//     inputValue.value = event.target.value;

//     if (inputValue.value.length >= 34) {
//         event.target.value = inputValue.value.slice(-17);
//     } else {
//         bookIdInputIsbn.value = inputValue.value;
//     }

//     clearTimeout(typingTimer);

//     typingTimer = setTimeout(async () => {
//         await nextTick();
//         seacher();
//     }, 1000);
// };

onMounted(() => {
    bookIdInputIsbn.value.focus();
});
</script>

<template>
    <Dashboard page_title="Vender livro">
        <Breadcrumb currentPageName="Vender livro" />

        <div class="row mt-2 mx-0">
            <div class="col-lg-12 card-simple p-4">
                <h4 class="text-center">Obter preço do livro</h4>
                <div class="col-md-12 mt-4">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text bg-dark" id="inputGroup-sizing-lg">
                            <font-awesome-icon
                                class="link-icon text-white"
                                :icon="['fas', 'barcode']"
                            />
                        </span>
                        <input
                            type="text"
                            class="form-control"
                            ref="bookIdInputIsbn"
                            v-maska
                            data-maska="###-##-####-###-# ###-##-####-###-#"
                            @input="compositionSeacher($event)"
                            placeholder="Informe o ISBN do livro"
                            autocomplete="off"
                            required
                            @keydown.enter.prevent
                        />
                    </div>
                </div>

                <div v-if="book.name && book.found">
                    <hr />
                    <div class="col-md-12">
                        <div class="row mt-3">
                            <BookImage :url="`/storage/${book.cover_image_url}`" />
                            <h5 class="text-center mt-3 font-semibold">{{ book.name }}</h5>
                            <h4 class="mt-3 text-center fw-bold">
                                {{ convertToCurrencyFormat(book.original_price) }} -
                                <span v-if="book.stock">{{ book.stock }} em estoque</span>
                                <span v-else>Em falta</span>
                            </h4>

                            <div class="row mt-3 p-3" v-if="hasPromotional.status">
                                <hr />
                                <p
                                    class="border-start border-5 border-warning font-semibold rounded-1 p-3 bg-info bg-opacity-10 fs-14"
                                >
                                    Livro em promoção - De
                                    <del>{{
                                        convertToCurrencyFormat(hasPromotional.originalPrice)
                                    }}</del>
                                    por apenas
                                    {{ convertToCurrencyFormat(hasPromotional.promotionalValue) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="!book.found">
                    <p
                        class="border-start border-5 mt-4 border-danger font-semibold p-3 bg-info bg-opacity-10 fs-14"
                    >
                        Nenhum livro encontrado
                    </p>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
