export default function useDateFormat() {
    function simplifyDate(dataString) {
        let dateParts = dataString.split('/');
        let day = dateParts[0];
        let month = dateParts[1];
        let year = dateParts[2].substring(2);

        let months = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
        ];

        let monthText = months[parseInt(month) - 1];

        return day + ' ' + monthText + ' de ' + year;
    }

    return {
        simplifyDate,
    };
}
