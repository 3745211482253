<script setup>
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Components/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Composables/useImageResizer';

const props = defineProps({
    user: Object,
    userRole: Object,
    roles: Object,
});

const form = useForm({
    name: props.user.name,
    status: props.user.status,
    role_id: props.userRole.id || 1,
    profile_image_url: props.user.avatar,
    email: props.user.email,
    changed_photo: false,
    _method: 'put',
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.profile_image_url);

const update = () => {
    form.photo = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('users.update', props.user.id));
};
</script>

<template>
    <Dashboard :page_title="user.name">
        <Breadcrumb
            :currentPageName="user.name"
            :linkList="[{ previous_page_url: 'users', previous_page_name: 'Equipe' }]"
        />

        <div id="edit-user">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link :href="route('users.show', user.id)" class="btn btn-success btn-add me-2">
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <hr />

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-sm-6 col">
                        <label for="name" class="form-label">Nome</label>
                        <input
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            id="name"
                            name="name"
                            v-model="form.name"
                            placeholder="Matheus"
                            required
                        />
                        <small class="text-danger mt-3" v-if="form.errors.name">{{
                            form.errors.name
                        }}</small>
                    </div>
                    <div class="col-sm-6 col">
                        <label for="email" class="form-label">Email</label>
                        <input
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            id="email"
                            name="email"
                            v-model="form.email"
                            placeholder="matheus@gmail.com"
                            required
                        />
                        <small class="text-danger mt-3" v-if="form.errors.email">{{
                            form.errors.email
                        }}</small>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <label for="function" class="form-label">Função</label>
                        <select
                            class="form-select"
                            name="function"
                            id="function"
                            v-model="form.role_id"
                        >
                            <option v-for="(role, index) in roles" :key="index" :value="role.id">
                                {{ role.display_name }}
                            </option>
                        </select>
                        <small class="text-danger mt-3" v-if="form.errors.role">{{
                            form.errors.role
                        }}</small>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <label for="status" class="form-label">Status da conta</label>
                        <select class="form-select" name="status" id="status" v-model="form.status">
                            <option value="ATIVO">Ativa</option>
                            <option value="DESATIVADO">Desativada</option>
                        </select>
                        <small class="text-danger mt-3" v-if="form.errors.status">{{
                            form.errors.status
                        }}</small>
                    </div>
                </div>
                <PhotoEditModal
                    v-if="form.profile_image_url"
                    title="Adicionar foto"
                    id="photoUpdateModal"
                    @image-resized="handleImageResized"
                />
            </Form>
        </div>
    </Dashboard>
</template>
