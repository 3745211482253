<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Composables/useSearch.js';

const props = defineProps({
    categories: Object,
});

const { isLoading, compositionSeacher, executeSearch, updateLoadingState } = useSearch(
    {
        name: '',
    },
    'categories.index'
);

watch(
    () => props.categories,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page_title="Listagem de categorias" main-data="categories">
        <Breadcrumb currentPageName="Listagem de categorias" />

        <PageHeader
            title="Categorias"
            :button-link="route('categories.create')"
            button-text="Nova"
        />

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da categoria</label>
                        <input
                            id="floatingInput"
                            type="text"
                            class="form-control"
                            placeholder="Rhema"
                            @input="(event) => compositionSeacher('name', event, executeSearch)"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-categories"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', tdClass: 'text-start' },
                    {
                        key: 'total_books',
                        label: 'Livros vinculados',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'visible_public',
                        label: 'V.L',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="categories?.data"
                :rowLink="(item) => route('categories.show', item.id)"
                :is-loading="isLoading"
                :pagination="{
                    data: categories?.meta,
                    reference: 'categories',
                }"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage :src="item.photo" :alt="`Categoria ${item.name}`" width="30px" />
                        <span class="ms-3">
                            {{ item.name }}
                        </span>
                    </div>
                </template>

                <template #cell-visible_public="{ item }">
                    <Badge :name="item.visible_public ? 'YES' : 'NOT'" />
                </template>

                <template #cell-total_books="{ item }"> {{ item.total_books }} livros </template>
            </Table>
        </div>
    </Dashboard>
</template>
